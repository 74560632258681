import Component from '../../../assets/scripts/modules/component'

class TabbedContent extends Component {
  init () {
    const switchTab = (event) => {
      labels.forEach((label) => {
        label.classList.remove('is-active')
      })
      const selectedtab = event.target.dataset.labelid
      event.target.classList.add('is-active')
      tabs.forEach(function (tab) {
        if (tab.dataset.tabid === selectedtab) {
          tab.classList.add('is-active')
        } else {
          tab.classList.remove('is-active')
        }
      })
    }

    const labels = document.querySelectorAll('[data-labelid]')
    const tabs = document.querySelectorAll('[data-tabid]')

    labels.forEach((label) => {
      label.addEventListener('click', switchTab)
    })
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.tabbed-content').forEach(element => {
  element.instance = element.instance || new TabbedContent(element)
}))
