import { ItemList } from '../../../assets/scripts/components/item-list'
import { apiCall, DELETE, GET, POST, RequestStatusError } from '../../../assets/scripts/interfaces/api-call'
import eventBus from '../../../assets/scripts/interfaces/event-bus'
import { GroupMemberManager, ROLE_LABELS } from '../../../assets/scripts/interfaces/group-member-manager'
import Component from '../../../assets/scripts/modules/component'
import { ActionLink } from './components/action-link'

/**
 * Construct a new Member object
 *
 * @param library
 * @param data
 * @returns {{element: Element}}
 * @constructor
 */
function BaseMember (library, data) {
  const fragment = library.member.content.cloneNode(true)
  const element = fragment.firstElementChild

  const linkElement = fragment.querySelector('[data-role=link]')
  const titleElement = fragment.querySelector('[data-role=title]')
  const roleElement = fragment.querySelector('[data-role=role]')
  const avatarElement = fragment.querySelector('[data-role=avatar]')

  const { name, slug, role, avatar } = data

  if (linkElement && slug) {
    linkElement.href = window.CNV_APP.profileUrl.replace(/\bslug\b/, slug)
  }

  if (titleElement) {
    titleElement.innerText = name
  }

  if (roleElement) {
    roleElement.innerText = role
  }

  if (avatarElement) {
    avatarElement.src = avatar
  }

  return {
    element
  }
}

/**
 * Create a Regular Member object
 * @param library
 * @param data
 * @returns {{element: Element}}
 * @constructor
 */
function RegularMember (library, data) {
  const { element } = BaseMember(library, data)
  const actions = element.querySelector('[data-role=actions]')

  if (actions) {
    element.removeChild(actions)
  }

  return {
    element
  }
}

/**
 * Create ModeratableMember object
 *
 * @param library
 * @param api
 * @param data
 * @returns {{subscribe: (function(*=, *=): *), element: Element}}
 * @constructor
 */
function ModeratableMember (library, api, data) {
  /**
   * Subscribe to events sent by this member.
   *
   * @param name
   * @param callback
   * @returns {*}
   */
  function subscribe (name, callback) {
    return bus.addListener(name, callback)
  }

  const { element } = BaseMember(library, data)
  const actions = element.querySelector('[data-role=actions]')
  const { uuid } = data
  const bus = eventBus()

  if (actions) {
    const action = ActionLink(library.action, { label: 'Verwijderen' })

    action.subscribe(() => {
      if (api.removeMember(uuid)) {
        setTimeout(() => {
          bus.send('removed', { element })
        }, 100)
      }
    })
    actions.appendChild(action.element)
  }

  return {
    subscribe,
    element
  }
}

/**
 * Create a PendingMember object
 *
 * @param library
 * @param api
 * @param data
 * @returns {{subscribe: (function(*=, *=): *), element: Element}}
 * @constructor
 */
function PendingMember (library, api, data) {
  /**
   * Subscribe to events sent by this member.
   *
   * @param name
   * @param callback
   * @returns {*}
   */
  function subscribe (name, callback) {
    return bus.addListener(name, callback)
  }

  const { element } = BaseMember(library, data)
  const actions = element.querySelector('[data-role=actions]')
  const { uuid } = data
  const bus = eventBus()

  if (actions) {
    const action = ActionLink(library.action, { label: 'Accepteren' })
    action.subscribe(() => {
      if (api.acceptRequest(uuid)) {
        setTimeout(() => {
          bus.send('removed', { element })
        }, 100)
      }
    })
    actions.appendChild(action.element)
  }

  return {
    subscribe,
    element
  }
}

/**
 * Construct a new Member List object.
 *
 * @param library
 * @param fetchSource
 * @param data
 * @returns {{subscribe, element: *}}
 * @constructor
 */
function MemberList (library, fetchSource, data, perPage = 100) {
  const { element, reload, subscribe } = ItemList(library.list, library.loadMoreButton, fetchSource, null, perPage)
  const titleElement = element.querySelector('[data-role=title]')
  const { title } = data

  if (titleElement) {
    titleElement.innerText = title
  }

  return {
    element,
    reload,
    subscribe
  }
}

/**
 * Fetch a group of users by role.
 *
 * @param library
 * @param api
 * @param pageUuid
 * @param canModerate
 * @param role
 * @param offset
 * @param limit
 * @returns {Promise<{total, items}>}
 */
async function fetchUsers (library, api, pageUuid, canModerate, role, offset, limit) {
  const serialize = (data) => serializeTwistMemberData(role, data)
  const asMember = (data) => canModerate ? ModeratableMember(library, api, data) : RegularMember(library, data)

  if (!pageUuid) { // testing
    if (role === 'owner') {
      return {
        total: 1,
        items: [
          { name: 'Peter Schuurs', avatar: '/static/media/csm_Justine-Feitsma_01_1fabb4eb9a.jpg', role: ROLE_LABELS.owner }
        ].map(asMember)
      }
    } else if (role === 'moderator') {
      return {
        total: 1,
        items: [
          { name: 'CNV', avatar: '/static/media/csm_Justine-Feitsma_01_1fabb4eb9a.jpg', role: ROLE_LABELS.moderator }
        ].map(asMember)
      }
    }

    return {
      total: 23,
      items: [
        { name: 'Mister_Stepp', avatar: '/static/media/csm_Justine-Feitsma_01_1fabb4eb9a.jpg', role: ROLE_LABELS.regular },
        { name: 'Vivian93', avatar: '/static/media/csm_Justine-Feitsma_01_1fabb4eb9a.jpg', role: ROLE_LABELS.regular },
        { name: 'Its_David', avatar: '/static/media/csm_Justine-Feitsma_01_1fabb4eb9a.jpg', role: ROLE_LABELS.regular },
        { name: 'Rosetta_J', avatar: '/static/media/csm_Justine-Feitsma_01_1fabb4eb9a.jpg', role: ROLE_LABELS.regular },
        { name: 'Sandra_vd_L', avatar: '/static/media/csm_Justine-Feitsma_01_1fabb4eb9a.jpg', role: ROLE_LABELS.regular },
        { name: 'Mister_Stepp', avatar: '/static/media/csm_Justine-Feitsma_01_1fabb4eb9a.jpg', role: ROLE_LABELS.regular },
        { name: 'Vivian93', avatar: '/static/media/csm_Justine-Feitsma_01_1fabb4eb9a.jpg', role: ROLE_LABELS.regular },
        { name: 'Its_David', avatar: '/static/media/csm_Justine-Feitsma_01_1fabb4eb9a.jpg', role: ROLE_LABELS.regular },
        { name: 'Rosetta_J', avatar: '/static/media/csm_Justine-Feitsma_01_1fabb4eb9a.jpg', role: ROLE_LABELS.regular },
        { name: 'Sandra_vd_L', avatar: '/static/media/csm_Justine-Feitsma_01_1fabb4eb9a.jpg', role: ROLE_LABELS.regular },
        { name: 'Mister_Stepp', avatar: '/static/media/csm_Justine-Feitsma_01_1fabb4eb9a.jpg', role: ROLE_LABELS.regular },
        { name: 'Vivian93', avatar: '/static/media/csm_Justine-Feitsma_01_1fabb4eb9a.jpg', role: ROLE_LABELS.regular },
        { name: 'Its_David', avatar: '/static/media/csm_Justine-Feitsma_01_1fabb4eb9a.jpg', role: ROLE_LABELS.regular },
        { name: 'Rosetta_J', avatar: '/static/media/csm_Justine-Feitsma_01_1fabb4eb9a.jpg', role: ROLE_LABELS.regular },
        { name: 'Sandra_vd_L', avatar: '/static/media/csm_Justine-Feitsma_01_1fabb4eb9a.jpg', role: ROLE_LABELS.regular },
        { name: 'Mister_Stepp', avatar: '/static/media/csm_Justine-Feitsma_01_1fabb4eb9a.jpg', role: ROLE_LABELS.regular },
        { name: 'Vivian93', avatar: '/static/media/csm_Justine-Feitsma_01_1fabb4eb9a.jpg', role: ROLE_LABELS.regular },
        { name: 'Its_David', avatar: '/static/media/csm_Justine-Feitsma_01_1fabb4eb9a.jpg', role: ROLE_LABELS.regular },
        { name: 'Rosetta_J', avatar: '/static/media/csm_Justine-Feitsma_01_1fabb4eb9a.jpg', role: ROLE_LABELS.regular },
        { name: 'Sandra_vd_L', avatar: '/static/media/csm_Justine-Feitsma_01_1fabb4eb9a.jpg', role: ROLE_LABELS.regular },
        { name: 'Mister_Stepp', avatar: '/static/media/csm_Justine-Feitsma_01_1fabb4eb9a.jpg', role: ROLE_LABELS.regular },
        { name: 'Vivian93', avatar: '/static/media/csm_Justine-Feitsma_01_1fabb4eb9a.jpg', role: ROLE_LABELS.regular },
        { name: 'Its_David', avatar: '/static/media/csm_Justine-Feitsma_01_1fabb4eb9a.jpg', role: ROLE_LABELS.regular },
        { name: 'Rosetta_J', avatar: '/static/media/csm_Justine-Feitsma_01_1fabb4eb9a.jpg', role: ROLE_LABELS.regular },
        { name: 'Sandra_vd_L', avatar: '/static/media/csm_Justine-Feitsma_01_1fabb4eb9a.jpg', role: ROLE_LABELS.regular }
      ].map(asMember).slice(offset, offset + limit)
    }
  }

  const endpoint = `/rest-api/v1/community/pages/${pageUuid}/followers/`
  const params = new URLSearchParams({ offset, limit, role })
  const paramsString = params.toString()
  const { results, count } = await apiCall(GET, `${endpoint}?${paramsString}`)

  return {
    total: count,
    items: results.map(serialize).map(asMember)
  }
}

async function fetchPendingMembers (library, api, pageUuid, offset, limit) {
  const serialize = (data) => serializeTwistMemberData('pending', data)
  const asMember = (item) => PendingMember(library, api, item)

  const endpoint = `/rest-api/v1/community/pages/${pageUuid}/requests/`
  const params = new URLSearchParams({ offset, limit })
  const paramsString = params.toString()
  const { results, count } = await apiCall(GET, `${endpoint}?${paramsString}`)

  return {
    total: count,
    items: results.map(serialize).map(asMember)
  }
}

/**
 * Fetch all admin members of the group
 *
 * @param library
 * @param groupMemberManager
 * @param offset
 * @param limit
 * @returns {Promise<{total, items}>}
 */
async function fetchAdmins (library, groupMemberManager, offset, limit) {
  const serialize = (data) => serializeTwistMemberData(data.role, data)
  const asMember = (item) => RegularMember(library, item)

  const { total, items } = await groupMemberManager.fetchAdmins()
  return { total, items: items.slice(offset, offset + limit).map(serialize).map(asMember) }
}

/**
 * Fetch all regular members of the group
 *
 * @param library
 * @param api
 * @param pageUuid
 * @param canModerate
 * @param offset
 * @param limit
 * @returns {Promise<{total: *, items: *}>}
 */
async function fetchRegularMembers (library, api, pageUuid, canModerate, offset, limit) {
  return fetchUsers(library, api, pageUuid, canModerate, 'regular', offset, limit)
}

async function acceptRequest (pageUuid, userUuid) {
  const endpoint = `/rest-api/v1/community/pages/${pageUuid}/accept/${userUuid}/`

  try {
    await apiCall(POST, endpoint, {}, window.CNV_APP.csrfToken, false)
  } catch (err) {
    if (err instanceof RequestStatusError) {
      return false
    }

    throw err
  }

  return true
}

async function removeMember (pageUuid, userUuid) {
  const endpoint = `/rest-api/v1/community/pages/${pageUuid}/accept/${userUuid}/`

  try {
    await apiCall(DELETE, endpoint, {}, window.CNV_APP.csrfToken, false)
  } catch (err) {
    if (err instanceof RequestStatusError) {
      return false
    }

    throw err
  }

  return true
}

/**
 * Transform data from twist-it to member dat
 *
 * @param role
 * @param memberData
 * @returns {{role, title}}
 */
function serializeTwistMemberData (role, memberData) {
  const { name, profile_photo: { url = null } = {}, slug, uuid } = memberData

  return {
    avatar: url,
    name,
    role: ROLE_LABELS[role],
    slug,
    uuid
  }
}

class MemberListComponent extends Component {
  init () {
    const templateList = this.element.querySelector('[data-role=template-member-list]')
    const templateMember = this.element.querySelector('[data-role=template-member]')
    const templateAction = this.element.querySelector('[data-role=template-action]')
    const templateLoadMoreButton = this.element.querySelector('[data-role=template-load-more-button]')

    const pageUuid = this.element.getAttribute('data-page-uuid')
    const userIsAdmin = this.element.getAttribute('data-user-is-admin') === 'true'

    const groupMemberManager = GroupMemberManager(pageUuid)

    this.library = {
      action: templateAction,
      list: templateList,
      member: templateMember,
      loadMoreButton: templateLoadMoreButton
    }

    this.api = {
      acceptRequest: (userUuid) => acceptRequest(pageUuid, userUuid),
      removeMember: (userUuid) => removeMember(pageUuid, userUuid),
      fetchAdmins: (offset, limit) => fetchAdmins(this.library, groupMemberManager, offset, limit),
      fetchRegularMembers: (offset, limit) => fetchRegularMembers(this.library, this.api, pageUuid, userIsAdmin, offset, limit),
      fetchPendingMembers: (offset, limit) => fetchPendingMembers(this.library, this.api, pageUuid, offset, limit)
    }

    this.adminMemberListContainer = this.element.querySelector('[data-role=admin-member-list]')
    this.regularMemberListContainer = this.element.querySelector('[data-role=regular-member-list]')
    this.pendingMemberListContainer = this.element.querySelector('[data-role=pending-member-list]')

    const adminList = MemberList(this.library, this.api.fetchAdmins, { title: 'Admins' })
    adminList.subscribe(({ itemCount }) => {
      if (itemCount > 0) {
        this.adminMemberListContainer.appendChild(adminList.element)
      }
    })

    const regularList = MemberList(this.library, this.api.fetchRegularMembers, { title: 'Leden' })
    let regularListAdded = false
    regularList.subscribe(({ itemCount }) => {
      if (regularListAdded) {
        return
      }

      if (itemCount > 0) {
        this.regularMemberListContainer.appendChild(regularList.element)
        regularListAdded = true
      }
    })

    if (userIsAdmin) {
      const pendingMemberList = MemberList(this.library, this.api.fetchPendingMembers, { title: 'Aanvragen' }, 5)
      let pendingListAdded = false
      pendingMemberList.subscribe(({ itemCount }) => {
        if (pendingListAdded) {
          if (itemCount === 0) {
            this.pendingMemberListContainer.removeChild(pendingMemberList.element)
          } else {
            regularList.reload()
          }

          return
        }

        if (itemCount > 0) {
          this.pendingMemberListContainer.appendChild(pendingMemberList.element)
          pendingListAdded = true
        }
      })
    }
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.member-list').forEach(element => {
  element.instance = element.instance || new MemberListComponent(element)
}))
