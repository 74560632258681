import Component from '../../../assets/scripts/modules/component'
import SwiperWrapper from '../../../assets/scripts/modules/swiper-wrapper'

const SWIPER_OPTIONS = {
  spaceBetween: 10,
  breakpointsInverse: true,
  breakpoints: {
    640: { slidesPerView: 2 },
    960: { slidesPerView: 3 }
  }
}

let timeout

export default class CardCarouselComponent extends Component {
  init () {
    this.observer = null
    this.container = this.element.querySelector('.swiper-container')

    const swiperOptions = {
      ...SWIPER_OPTIONS,
      navigation: {
        nextEl: this.element.querySelector('.swiper-button-next'),
        prevEl: this.element.querySelector('.swiper-button-prev')
      }
    }

    this.swiperWrapper = new SwiperWrapper(this.container, swiperOptions, [], {
      slideChange: () => this.onSwiperSlideChange(),
      resize: () => this.showNavigation()
    })

    window.clearTimeout(timeout)
    timeout = window.setTimeout(() => {
      this.onSwiperSlideChange()
      this.showNavigation()

      // Warkaround for swiper bug; see https://github.com/nolimits4web/Swiper/issues/2099
      if (SWIPER_OPTIONS.loop) {
        [...this.element.querySelectorAll('.swiper-button-disabled')].forEach(button => {
          button.classList.remove('swiper-button-disabled')
        })
      }
    }, 500)
  }

  canNavigate () {
    const prevIsDisabled = this.element.querySelector('.swiper-button-prev').classList.contains('swiper-button-disabled')
    const nextIsDisabled = this.element.querySelector('.swiper-button-next').classList.contains('swiper-button-disabled')
    return (prevIsDisabled && nextIsDisabled)
  }

  showNavigation () {
    if (this.canNavigate()) {
      this.element.querySelector('.swiper-navigation').setAttribute('hidden', '')
    } else {
      this.element.querySelector('.swiper-navigation').removeAttribute('hidden', '')
    }
  }

  onSwiperSlideChange () {
    if (!this.swiperWrapper || !this.swiperWrapper.swiper || !this.swiperWrapper.swiper.slides) {
      return
    }

    const current = this.swiperWrapper.swiper.slides[this.swiperWrapper.swiper.activeIndex]
    const all = Object.values(this.swiperWrapper.swiper.slides).filter(slide => !!slide.tagName)
    const currentWithDuplicates = all.filter(slide => slide.getAttribute('data-swiper-slide-index') === current.getAttribute('data-swiper-slide-index'))

    currentWithDuplicates.forEach(slide => {
      slide.classList.add('swiper-slide--activated')
    })
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.card-carousel').forEach(element => {
  element.instance = element.instance || new CardCarouselComponent(element)
}))

document.addEventListener('DOMContentLoaded', function (event) {
  document.querySelectorAll('.card-carousel .filter-button').forEach(button => {
    button.addEventListener('click', (event) => {
      window.location.href = `?news_type=${button.dataset.tagid}`
    })
  })

  if (window.location.search.includes('news_type')) {
    if (document.getElementById('news')) {
      updateNews()
      window.scrollTo(0, document.getElementById('news').getBoundingClientRect().top - 100)
    }
  }

  function updateNews () {
    const params = (new URL(document.location)).searchParams
    const currentTagId = params.get('news_type')

    if (currentTagId !== 'alles') {
      document.querySelector('[data-tagid="alles"]').classList.remove('selected')
      document.querySelector(`[data-tagid="${currentTagId}"]`).classList.add('selected')
    }
  }
}, false)
