import Component from '../../../assets/scripts/modules/component'
import SwiperWrapper from '../../../assets/scripts/modules/swiper-wrapper'

let timeout

export default class DossierTestimonialComponent extends Component {
  init () {
    this.observer = null
    this.container = this.element.querySelector('.swiper-container')

    if (this.container) {
      this.initSwiper()
    }
  }

  initSwiper () {
    const swiperOptions = {
      navigation: {
        nextEl: this.element.querySelector('.swiper-button-next'),
        prevEl: this.element.querySelector('.swiper-button-prev')
      },
      breakpoints: {
        800: {
          speed: 1000
        }
      }
    }

    this.swiperWrapper = new SwiperWrapper(this.container, swiperOptions, [], {
      slideChange: () => this.onSwiperSlideChange(),
      resize: () => this.showNavigation()
    })

    window.clearTimeout(timeout)
    timeout = window.setTimeout(() => {
      this.onSwiperSlideChange()
      this.showNavigation()
    }, 500)
  }

  canNavigate () {
    const prevIsDisabled = this.element.querySelector('.swiper-button-prev').classList.contains('swiper-button-disabled')
    const nextIsDisabled = this.element.querySelector('.swiper-button-next').classList.contains('swiper-button-disabled')
    return (prevIsDisabled && nextIsDisabled)
  }

  showNavigation () {
    if (this.canNavigate()) {
      this.element.querySelector('.swiper-navigation').setAttribute('hidden', '')
    } else {
      this.element.querySelector('.swiper-navigation').removeAttribute('hidden', '')
    }
  }

  onSwiperSlideChange () {
    if (!this.swiperWrapper || !this.swiperWrapper.swiper || !this.swiperWrapper.swiper.slides) {
      return
    }

    const current = this.swiperWrapper.swiper.slides[this.swiperWrapper.swiper.activeIndex]
    const all = Object.values(this.swiperWrapper.swiper.slides).filter(slide => !!slide.tagName)
    const currentWithDuplicates = all.filter(slide => slide.getAttribute('data-swiper-slide-index') === current.getAttribute('data-swiper-slide-index'))

    currentWithDuplicates.forEach(slide => {
      slide.classList.add('swiper-slide--activated')
    })
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.dossier-testimonials').forEach(element => {
  element.instance = element.instance || new DossierTestimonialComponent(element)
}))
