/**
 * Custom modal closer function.
 *
 * @param modal
 */
function closeModal (modal) {
  // Filthy hack - MicroModal.close has a bug where modals are swapped when multiple modals are present
  // Therefor we want to trigger the close "naturally" by Micromodal's own internal processes
  const event = new Event('click')
  modal.setAttribute('data-modal-close', 'data-modal-close')
  modal.dispatchEvent(event)
  modal.removeAttribute('data-modal-close')
}

export { closeModal }
