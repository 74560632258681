import { ItemList } from '../../../assets/scripts/components/item-list'
import { apiCall, GET } from '../../../assets/scripts/interfaces/api-call'
import Component from '../../../assets/scripts/modules/component'
import { humanizeNumber } from '../../../assets/scripts/utilities/humanize'

/**
 * Construct a Member object
 *
 * @param templateLibrary
 * @param memberData
 * @returns {{element: Element}}
 * @constructor
 */
function Member (templateLibrary, memberData) {
  const fragment = templateLibrary.member.content.cloneNode(true)
  const element = fragment.firstElementChild
  const image = fragment.querySelector('[data-role=image]')

  const { avatar } = memberData

  if (image && avatar) {
    image.src = avatar
  }

  return {
    element
  }
}

/**
 * Construct a MemberInfo object
 *
 * @param templateLibrary
 * @param defaultData
 * @param membersData
 * @returns {{element: Element}}
 * @constructor
 */
function MembersInfo (templateLibrary, defaultData, membersData) {
  /**
   * Format the label for member count.
   *
   * @param count
   * @returns {string}
   */
  function formatMemberCount (count) {
    if (count === 1) {
      return '1 lid'
    }

    return `${humanizeNumber(count)} leden`
  }

  const fragment = templateLibrary.membersInfo.content.cloneNode(true)
  const element = fragment.firstElementChild
  const sample = fragment.querySelector('[data-role=sample]')
  const text = fragment.querySelector('[data-role=text]')

  const { member1, member2, member3, memberCount } = membersData
  const { defaultAvatar } = defaultData

  if (sample) {
    let members = [member1, member2, member3].filter(m => !!m)

    if (!members.length) {
      members = [{
        avatar: defaultAvatar
      }]
    }

    for (const member of members) {
      const memberObject = Member(templateLibrary, member)
      sample.appendChild(memberObject.element)
    }
  }

  if (text) {
    text.innerText = formatMemberCount(memberCount)
  }

  return {
    element
  }
}

/**
 * Construct a Group object
 *
 * @param templateLibrary
 * @param api
 * @param groupData
 * @returns {{element: Element}}
 * @constructor
 */
function Group (templateLibrary, api, groupData) {
  const fragment = templateLibrary.group.content.cloneNode(true)
  const element = fragment.firstElementChild
  const titleElement = fragment.querySelector('[data-role=title]')
  const link = fragment.querySelector('[data-role=link]')
  const status = fragment.querySelector('[data-role=status]')
  const attributes = fragment.querySelector('[data-role=attributes]')
  const description = fragment.querySelector('[data-role=description]')
  const footer = fragment.querySelector('[data-role=footer]')

  const { accessPending, href, isOpen, title } = groupData

  if (titleElement) {
    titleElement.innerText = title
  }

  if (link) {
    link.href = href
  }

  if (status && accessPending) {
    status.innerText = 'Toegang is aangevraagd'
  }

  if (attributes) {
    let iconFragment, iconElement

    if (accessPending) {
      iconFragment = templateLibrary.iconPending.content.cloneNode(true)
      iconElement = iconFragment.firstElementChild
      attributes.appendChild(iconElement)
    } else if (!isOpen) {
      iconFragment = templateLibrary.iconPrivate.content.cloneNode(true)
      iconElement = iconFragment.firstElementChild
      attributes.appendChild(iconElement)
    }
  }

  if (description) {
    description.innerText = groupData.description
  }

  if (footer) {
    const membersInfo = MembersInfo(
      templateLibrary,
      api.getDefaultData(),
      {
        member1: groupData.member1,
        member2: groupData.member2,
        member3: groupData.member3,
        memberCount: groupData.memberCount
      }
    )
    footer.appendChild(membersInfo.element)
  }

  return {
    element
  }
}

/**
 * Construct a Memory object
 *
 * @param api
 * @returns {{isPending: (function(*=): *)}}
 * @constructor
 */
function GroupListMemory (api) {
  /**
   * Test whether a group uuid has pending access for the current user.
   *
   * @param groupUuid
   * @returns {Promise<*>}
   */
  async function isPending (groupUuid) {
    if (!window.CNV_APP.isLoggedIn) {
      return false
    }

    if (pendingAccessRequests === null) {
      if (requestPromise === null) {
        requestPromise = api.fetchPendingRequests()
      }

      pendingAccessRequests = await requestPromise
    }

    return pendingAccessRequests.includes(groupUuid)
  }

  let pendingAccessRequests = null
  let requestPromise = null

  return {
    isPending
  }
}

/**
 * Fetch new groups from the back-end.
 *
 * @param templateLibrary
 * @param api
 * @param endpoint
 * @param offset
 * @param limit
 * @returns {Promise<{total: *, items: {element: Element}[]}|{total: *, isTest: boolean, items: *[]}>}
 */
async function fetchGroups (templateLibrary, api, endpoint, offset, limit) {
  if (!endpoint) {
    return {
      total: 0,
      items: []
    }
  }

  // For testing purposes
  if (endpoint === 'test') {
    return {
      total: limit + 1, // this should show the load more button once
      items: [],
      isTest: true
    }
  }

  // regular behaviour
  const params = new URLSearchParams({ offset, limit }).toString()
  const data = await apiCall(GET, `${endpoint}?${params}`)

  const results = await Promise.all(data.results.map(api.serializeGroupData))

  return {
    total: data.count,
    items: results.map(item => Group(templateLibrary, api, item))
  }
}

/**
 * Fetch pending requests from the back-end.
 *
 * @returns {Promise<*>}
 */
async function fetchPendingRequests () {
  const endpoint = '/rest-api/v1/community/pages/requests/'
  const response = await apiCall(GET, endpoint)

  return response.results.map(item => item.uuid)
}

/**
 * Serialize group data from the back-end.
 *
 * @param memory
 * @param groupData
 * @returns {Promise<{accessPending: *, isOpen, member2: *, member3: *, memberCount, description, member1: *, href, title}>}
 */
async function serializeGroupData (memory, groupData) {
  const { uuid, name, slug, description, is_visible: isVisible, followers_count: followersCount, extra_data: extraData } = groupData

  const members = extraData?.members?.map(({ profile_photo: profilePhoto }) => ({
    avatar: profilePhoto
  })) || []

  const accessPending = await memory.isPending(uuid)

  return {
    title: name,
    href: window.CNV_APP.groupUrl?.replace(/\/slug\//, `/${slug}/`) || '#',
    description: description,
    accessPending: accessPending,
    isOpen: isVisible,
    member1: members[0],
    member2: members[1],
    member3: members[2],
    memberCount: followersCount
  }
}

class GroupListComponent extends Component {
  init () {
    const templateList = this.element.querySelector('[data-role=template-list]')
    const templateGroup = this.element.querySelector('[data-role=template-group]')
    const templateMembersInfo = this.element.querySelector('[data-role=template-members-info]')
    const templateMember = this.element.querySelector('[data-role=template-member]')
    const templateLoadMoreButton = this.element.querySelector('[data-role=template-load-more-button]')
    const templateIconPrivate = this.element.querySelector('[data-role=template-icon-private]')
    const templateIconPending = this.element.querySelector('[data-role=template-icon-pending]')

    this.library = {
      group: templateGroup,
      iconPending: templateIconPending,
      iconPrivate: templateIconPrivate,
      list: templateList,
      loadMoreButton: templateLoadMoreButton,
      membersInfo: templateMembersInfo,
      member: templateMember
    }

    this.api = {
      fetchGroups: (offset, limit) => fetchGroups(this.library, this.api, this.endpoint, offset, limit),
      fetchPendingRequests,
      getDefaultData: () => ({
        defaultAvatar: this.defaultAvatar
      }),
      serializeGroupData: async (groupData) => serializeGroupData(this.memory, groupData)
    }

    this.memory = GroupListMemory(this.api)

    this.endpoint = this.element.getAttribute('data-endpoint')
    this.defaultAvatar = this.element.getAttribute('data-default-avatar')
    this.body = this.element.querySelector('[data-role=body]')

    // Build the main group list
    this.groupList = ItemList(this.library.list, this.library.loadMoreButton, this.api.fetchGroups, null, 6)
    this.body.appendChild(this.groupList.element)
  }

  /**
   * Add a new group from group data.
   *
   * @param groupData
   * @returns {Promise<void>}
   */
  async addGroup (groupData) {
    if (groupData.slug) {
      groupData.href = window.CNV_APP.groupUrl.replace(/\/slug\//, `/${groupData.slug}/`)
    }

    if (groupData.uuid) {
      groupData.accessPending = await this.memory.isPending(groupData.uuid)
    }

    const newGroup = Group(this.library, this.api, groupData)
    this.groupList.addItem(newGroup, true)
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.group-list').forEach(element => {
  element.instance = element.instance || new GroupListComponent(element)
}))
