import { apiCall, GET } from '../../../assets/scripts/interfaces/api-call'
import Component from '../../../assets/scripts/modules/component'
import { hydratorRegisterComponent } from '../../../assets/scripts/utilities/hydrator'

class SearchAutocompleteComponent extends Component {
  init () {
    const templateItem = this.element.querySelector('.search-autocomplete__item-template')
    const baseUrl = this.element.getAttribute('data-base-url')
    this.container = this.element.querySelector('[data-role=container]')

    this.library = {
      item: templateItem
    }

    this.input = this.element.querySelector('[data-role="input"]')
    this.form = this.element.closest('form')
    this.options = []

    this.input.addEventListener('input', async () => {
      const query = this.input.value
      const { items } = await apiCall(GET, `${baseUrl}?q=${query}`)
      this.options = items
      this.renderItemContainer(query)
    })

    this.input.addEventListener('focus', () => {
      this.element.classList.add('search-autocomplete--active')
    })

    this.input.addEventListener('blur', () => {
      this.element.classList.remove('search-autocomplete--active')
    })
  }

  renderItemContainer (currentQuery) {
    this.container.innerHTML = ''
    this.options.forEach((option) => {
      const item = this.createItem(this.library.item, option.name, currentQuery)
      item.setAttribute('href', `?q=${option.name}`)
      // item.addEventListener('click', () => {
      //   this.input.value = option.name
      //   this.form.submit()
      // })
      this.container.appendChild(item)
    })
  }

  createItem (template, text, currentQuery) {
    const fragment = template.content.cloneNode(true)
    const element = fragment.firstElementChild
    const textContainer = fragment.querySelector('[data-role=text]')
    textContainer.innerHTML = text.replace(new RegExp(currentQuery, 'gi'), '<strong>$&</strong>')
    return element
  }

  createOptionHTML (template, text) {
    const fragment = template.content.cloneNode(true)
    const element = fragment.firstElementChild
    const textContainer = fragment.querySelector('[data-role=text]')
    textContainer.innerText = text

    return element.outerHTML
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.search-autocomplete').forEach((element) => {
    element.instance = element.instance || new SearchAutocompleteComponent(element)
  })
)
hydratorRegisterComponent('.search-autocomplete', SearchAutocompleteComponent)
