/**
 * Create a new idea
 */
import { DropdownMenu } from '../../../../assets/scripts/components/dropdown-menu'
import { DropdownMenuItem } from '../../../../assets/scripts/components/dropdown-menu-item'
import { UserLabel } from '../../../../assets/scripts/components/user-label'
import eventBus from '../../../../assets/scripts/interfaces/event-bus'
import { hydrate } from '../../../../assets/scripts/utilities/hydrator'

function buildIdea (templateLibrary, api, ideaData) {
  /**
   * Subscribe to events sent by this comment
   *
   * @param name
   * @param callback
   * @returns {*}
   */
  function subscribe (name, callback) {
    return bus.addListener(name, callback)
  }

  function updateStatus (isLikedByUser, likeCount) {
    if (status) {
      element.classList.toggle('idea-section-idea--is-liked', isLikedByUser)
      status.innerText = likeCount

      // Retrieve animated state from localstorage
      const lastLikedUuid = localStorage.getItem('last-liked-item')
      const hasLikeAnimated = localStorage.getItem('has-like-animated')
      const shouldHandleLastLiked = isLikedByUser && ideaData.uuid === lastLikedUuid && hasLikeAnimated !== 'true'
      if (shouldHandleLastLiked) {
        localStorage.setItem('has-like-animated', isLikedByUser)
      }
      element.classList.toggle('idea-section-idea--is-last-liked', shouldHandleLastLiked)
    }
  }

  function updateLikeButtonStatus (isLiked) {
    if (like) {
      like.classList[isLiked ? 'remove' : 'add']('button-toggle--is-collapsed')
    }
  }

  const fragment = templateLibrary.idea.content.cloneNode(true)
  const element = fragment.firstElementChild
  const menu = element.querySelector('[data-role=menu]')
  const status = element.querySelector('[data-role=status]')
  const title = element.querySelector('[data-role=title]')
  const text = element.querySelector('[data-role=text]')
  const username = element.querySelector('[data-role=username]')
  const avatar = element.querySelector('[data-role=avatar]')
  const like = element.querySelector('[data-role=like]')
  const labels = element.querySelector('[data-role=labels]')
  const userProfileLink = element.querySelector('[data-role=user-profile-link]')

  const bus = eventBus()

  updateStatus(ideaData.likedByUser, ideaData.likeCount)

  if (title) {
    title.innerText = ideaData.title
  }

  if (text) {
    text.innerText = ideaData.text
  }

  if (username) {
    username.innerText = ideaData.username
  }

  if (userProfileLink) {
    userProfileLink.href = window.CNV_APP.profileUrl?.replace(/\bslug\b/, ideaData.userSlug)
  }

  if (avatar && ideaData.avatar) {
    avatar.src = ideaData.avatar
  }

  if (like) {
    let isOn = ideaData.likedByUser

    updateLikeButtonStatus(isOn)

    if (!ideaData.isOwned) {
      like.addEventListener('click', async () => {
        const { likeCount } = await api[isOn ? 'unlikeIdea' : 'likeIdea'](ideaData.uuid)
        isOn = !isOn
        updateStatus(isOn, likeCount)
        updateLikeButtonStatus(isOn)

        // Save animated state
        if (isOn) {
          localStorage.setItem('last-liked-item', ideaData.uuid)
          localStorage.setItem('has-like-animated', false)
        }

        bus.send('order-change', {})
      })
    }
  }

  if (menu) {
    const dropdownMenu = DropdownMenu(templateLibrary.dropdownMenu)
    const menuItems = []

    if (ideaData.isOwned) {
      const removeItem = DropdownMenuItem(templateLibrary.dropdownMenuItem, 'Idee verwijderen', templateLibrary.iconRemove)
      removeItem.subscribe(async (event) => {
        const success = await api.removeIdea(ideaData.uuid)

        if (success) {
          bus.send('removed', { element })
        }
      })
      menuItems.push(removeItem)
    }

    if (!ideaData.isOwned) {
      const reportItem = DropdownMenuItem(templateLibrary.dropdownMenuItem, 'Rapporteren', templateLibrary.iconReport)
      reportItem.subscribe(async (event) => {
        const success = await api.reportIdea(ideaData.uuid)

        if (success) {
          alert('Bedankt voor het rapporteren!')
        }
      })
      menuItems.push(reportItem)
    }

    for (const item of menuItems) {
      dropdownMenu.addItem(item)
    }

    if (menuItems.length) {
      menu.appendChild(dropdownMenu.element)
    }
  }

  if (labels && ideaData.labels) {
    const userLabels = ideaData.labels.map(ul => UserLabel(templateLibrary.userLabel, ul))

    for (const userLabel of userLabels) {
      labels.appendChild(userLabel.element)
    }
  }

  hydrate(element)

  return {
    subscribe,
    element
  }
}

export { buildIdea }
