import MicroModal from 'micromodal'

import { redirectToLoginAndReturn } from '../../../assets/scripts/interfaces/api-call'
import asyncFormSubmitter from '../../../assets/scripts/interfaces/async-form-submitter'
import Component from '../../../assets/scripts/modules/component'
import { closeModal } from '../../../assets/scripts/utilities/close-modal-js-modal'
import fireCustomEvent from '../../../assets/scripts/utilities/fire-custom-event'
import { hydrate } from '../../../assets/scripts/utilities/hydrator'

/**
 * Communicate with the back-end to handle a form.
 * Send the results around via a global event.
 */
async function handleFormModal (modal, action) {
  async function handleResponse (data) {
    function handleSubmit (event) {
      async function ajaxSubmit () {
        formSubmitted = true

        if (isTest) {
          /** We assume the existence of a .response.json file with the same name that contains the data to add */
          const response = await fetch(action.replace('.html', '.response.json'))
          const extra = await response.json()
          handleResponse({
            completed: true,
            extra,
            errors: [],
            html: ''
          })
        } else {
          const response = await submit(new FormData(form))
          if (response.status === 403) {
            return redirectToLoginAndReturn()
          }
          handleResponse(response)
        }
      }
      event.preventDefault()
      event.stopPropagation()

      // Prevent multiple submits
      if (formSubmitted) {
        return false
      }

      ajaxSubmit().catch(console.log)

      return false
    }

    const { submit } = asyncFormSubmitter(action, window.CNV_APP.csrfToken, 'modal')
    let formSubmitted = false
    let form

    // For testing purposes
    if (isTest && typeof data === 'string') {
      data = {
        html: data,
        errors: [],
        completed: false,
        extra: {}
      }
    }

    if (data.errors.length) {
      // Show errors to user
      alert(data.errors.join('\n'))
      closeModal(modal)
      // MicroModal.close(modal.id)
    } else if (data.completed) {
      // Finalize and close the modal
      fireCustomEvent('modal-form-completed', {
        modal: modal,
        data: data.extra
      })
      closeModal(modal)
      // MicroModal.close(modal.id)
    } else {
      // Update the modal state
      // TODO: Remove event listener?
      container.innerHTML = data.html
      hydrate(container)
      form = container.querySelector('form')
      // custom-submit fixes weird FF behaviour that submits the form without being able to prevent it
      form.addEventListener('custom-submit', handleSubmit)
      form.addEventListener('submit', handleSubmit)
    }
  }

  const container = modal.querySelector('.modal__dynamic-content')
  container.innerHTML = ''
  const isTest = action.endsWith('.html')
  const response = await fetch(action)
  if (response.status === 403) {
    return redirectToLoginAndReturn()
  }
  handleResponse(await response[isTest ? 'text' : 'json']())
}

function handleFormModalClose (modal, action) {
  const container = modal.querySelector('.modal__dynamic-content')
  const form = container.querySelector('form')

  if (form) {
    // TODO: remove event listener?
    container.innerHTML = ''
  }
}

function onModalShow (modal, caller, event) {
  const action = modal.dataset.action

  if (action) {
    handleFormModal(modal, action)
  }
}

function onModalClose (modal, caller, event) {
  event.preventDefault()
  const action = modal.dataset.action

  if (action) {
    handleFormModalClose(modal, action)
  }
}

class Modal extends Component {
  init () {
    microModalInit()
  }
}

function microModalInit () {
  if (!micromodalInitialized) {
    micromodalInitialized = true

    MicroModal.init({
      openTrigger: 'data-modal-open',
      closeTrigger: 'data-modal-close',
      openClass: 'is-open',
      disableScroll: true,
      disableFocus: false,
      awaitOpenAnimation: false,
      awaitCloseAnimation: false,
      debugMode: true,
      onShow: onModalShow,
      onClose: onModalClose
    })
  }
}

let micromodalInitialized = false

window.addEventListener('init-load', () =>
  document.querySelectorAll('.modal').forEach((element) => {
    element.instance = element.instance || new Modal(element)
  })
)
