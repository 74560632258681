import Component from '../../../assets/scripts/modules/component'

export default class SettingsActionComponent extends Component {
  init () {
    window.addEventListener('modal-form-completed', ({ detail: { modal: { id } } }) => {
      if (id === 'modal-cao') {
        window.location.reload()
      }
    })
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.settings-action').forEach(element => {
  element.instance = element.instance || new SettingsActionComponent(element)
}))
