import eventBus from '../../../assets/scripts/interfaces/event-bus'

/**
 * Controller for a toggle button
 *
 * @param templateOrElement
 * @returns {{getState: (function(): boolean), subscribe: subscribe, setState: setState, element: (Element|*)}}
 * @constructor
 */
function ToggleButton (templateOrElement) {
  /**
   * Get current state of toggle button
   *
   * @returns {boolean}
   */
  function getState () {
    return !!input.checked
  }

  /**
   * Set new state of toggle button
   *
   * @param newState
   */
  function setState (newState) {
    input.checked = newState
    unsetProcessingUserAction()
  }

  function setProcessingUserAction () {
    awaitingResponse = true
    element.classList.add('toggle--processing')
  }

  function unsetProcessingUserAction () {
    awaitingResponse = false
    element.classList.remove('toggle--processing')
  }

  /**
   * Subscribe to click event of toggle button
   * @param callback
   */
  function subscribe (callback) {
    bus.addListener('click', callback)
  }

  // Supports both a direct element and a template
  const template = templateOrElement.tagName === 'TEMPLATE' ? templateOrElement : null
  const fragment = template ? template.content.cloneNode(true) : templateOrElement
  const element = template ? fragment.firstElementChild : templateOrElement
  const clickableArea = fragment.querySelector('[data-role=toggle-clickable-area]')
  const input = fragment.querySelector('input')
  console.log('ToggleButton', input.value)

  const api = {
    getState,
    setState,
    subscribe,
    element
  }

  let awaitingResponse = false

  if (!clickableArea) {
    // This button will not work!
    element.innerHTML = ''
    console.log('Toggle button hidden because unable to initialize')
    return api
  }

  clickableArea.addEventListener('click', (event) => {
    event.preventDefault()

    if (!awaitingResponse) {
      setProcessingUserAction()
      bus.send('click', {})
    }
  })

  const bus = eventBus()

  return api
}

window.addEventListener('init-load', () => document.querySelectorAll('.toggle').forEach(element => {
  element.instance = element.instance || ToggleButton(element)
}))
