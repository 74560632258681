import { apiCall, GET } from './api-call'
import { LazyEvaluator } from './lazy-evaluator'

const cache = {}

/**
 * Convert role identifiers to labels
 *
 * @type {{owner: string, moderator: string, regular: string}}
 */
const ROLE_LABELS = {
  owner: 'Beheerder',
  moderator: 'Moderator',
  pending: '',
  regular: ''
}

/**
 * Fetch a group of users by role.
 *
 * @param pageUuid
 * @param role
 * @param offset
 * @param limit
 * @returns {Promise<{total, items}>}
 */
async function fetchUsers (pageUuid, role, offset, limit) {
  if (!pageUuid) { // testing
    return {
      total: 1,
      items: [
        { name: 'Mister_Stepp', avatar: '/static/media/csm_Justine-Feitsma_01_1fabb4eb9a.jpg', role: 'owner' },
        { name: 'Mister_Stepp', avatar: '/static/media/csm_Justine-Feitsma_01_1fabb4eb9a.jpg', role: 'moderator' },
        { name: 'Mister_Stepp', avatar: '/static/media/csm_Justine-Feitsma_01_1fabb4eb9a.jpg', role: 'regular' }
      ].slice(offset, offset + limit)
    }
  }

  const endpoint = `/rest-api/v1/community/pages/${pageUuid}/followers/`
  const params = new URLSearchParams({ offset, limit, role })
  const paramsString = params.toString()
  const { results, count } = await apiCall(GET, `${endpoint}?${paramsString}`)

  const annotatedResults = results.map(result => ({ ...result, role }))

  return {
    total: count,
    items: annotatedResults
  }
}

/**
 * Helper for group member data
 *
 * @param pageUuid
 * @returns {{getRoleLabels: ((function(*): Promise<*[]>)), fetchAdmins: ((function(): Promise<null|*>)|*)}}
 * @constructor
 */
function GroupMemberManager (pageUuid) {
  /**
   * Fetch all owners.
   *
   * @returns {Promise<{total: number, items: *[]}>}
   */
  async function fetchOwners () {
    return fetchUsers(pageUuid, 'owner', 0, 100)
  }

  /**
   * Fetch all moderators
   *
   * @returns {Promise<{total: number, items: *[]}>}
   */
  async function fetchModerators () {
    return fetchUsers(pageUuid, 'moderator', 0, 100)
  }

  /**
   * Fetch all admin user groups and combine them in one result structure.
   *
   * @returns {Promise<{total: number, items: *[]}>}
   */
  async function doFetchAdmins () {
    const [owners, moderators] = await Promise.all([fetchOwners(), fetchModerators()])

    const { total: ownersTotal, items: ownersItems } = owners
    const { total: moderatorsTotal, items: moderatorsItems } = moderators

    return {
      total: ownersTotal + moderatorsTotal,
      items: ownersItems.concat(moderatorsItems)
    }
  }

  /**
   * Get the role labels for the user with given uuid.
   *
   * @param uuid
   * @returns {Promise<*[]>}
   */
  async function getRoleLabels (uuid) {
    const admins = await fetchAdmins()
    const user = admins.items.find(user => user.uuid === uuid)

    if (user && ROLE_LABELS[user.role]) {
      return [ROLE_LABELS[user.role]]
    }

    return []
  }

  let fetchAdmins

  if (pageUuid in cache) {
    fetchAdmins = cache[pageUuid]
  } else {
    fetchAdmins = cache[pageUuid] = LazyEvaluator(doFetchAdmins).evaluate
  }

  return {
    fetchAdmins,
    getRoleLabels
  }
}

export { ROLE_LABELS, GroupMemberManager }
