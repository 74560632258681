import Component from '../../../assets/scripts/modules/component'
import { hydratorRegisterComponent } from '../../../assets/scripts/utilities/hydrator'
import { clickedOutsideSingle } from '../../../assets/scripts/utilities/outside-click'
class DropdownMenuComponent extends Component {
  init () {
    this.btn = this.element.querySelector('.dropdown-menu__btn')
    this.btn.addEventListener('click', () => {
      const toggleClass = 'is-collapsed'
      const isCollapsed = this.element.classList.contains(toggleClass)
      this.btn.setAttribute('aria-expanded', isCollapsed)
      this.element.classList.toggle(toggleClass, !isCollapsed)
    })

    document.addEventListener('click', (e) => {
      clickedOutsideSingle(e, this.btn) && this.element.classList.add('is-collapsed')
    })
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.dropdown-menu').forEach(element => {
  element.instance = element.instance || new DropdownMenuComponent(element)
}))

class DropdownMenuSimpel extends Component {
  init () {
    this.btnSimpel = this.element.querySelector('.dropdown-menu-simple__btn')

    this.btnSimpel.addEventListener('click', () => {
      this.toggle()
    })

    const clickables = [...this.element.querySelectorAll('[data-role=item-clickable]')]
    clickables.forEach(item => {
      item.addEventListener('click', (event) => {
        this.toggle(true)
      })
    })
  }

  toggle (makeCollapsed = null) {
    const toggleClass = 'is-collapsed'
    const isCollapsed = makeCollapsed === null ? !this.element.classList.contains(toggleClass) : makeCollapsed
    this.btnSimpel.setAttribute('aria-expanded', isCollapsed)
    this.element.classList[isCollapsed ? 'add' : 'remove'](toggleClass)
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.dropdown-menu-simple').forEach(element => {
  element.instance = element.instance || new DropdownMenuSimpel(element)
}))

hydratorRegisterComponent('.dropdown-menu-simple', DropdownMenuSimpel)
