
import { ItemList } from '../../../assets/scripts/components/item-list'
import { apiCall, apiFormCall, DELETE, GET, POST, redirectToLoginAndReturn, RequestStatusError } from '../../../assets/scripts/interfaces/api-call'
import { GroupMemberManager } from '../../../assets/scripts/interfaces/group-member-manager'
import Component from '../../../assets/scripts/modules/component'
import { humanizeNumber } from '../../../assets/scripts/utilities/humanize'
import { buildComment } from './components/comment-section-comment'

/**
 * Format the subtitle for the comment section
 *
 * @param totalItemCount
 * @returns string
 */
function formatSubtitle (totalItemCount) {
  if (totalItemCount < 1) {
    return ''
  }

  if (totalItemCount === 1) {
    return '1 bericht'
  }

  return `${humanizeNumber(totalItemCount)} berichten`
}

/**
 * Helper function to redirect a user to the login page if something is forbidden
 *
 * @param promise
 * @returns {Promise<*>}
 */
async function resolveOrRedirectToLogin (promise) {
  try {
    return await promise
  } catch (err) {
    if (err instanceof RequestStatusError) {
      if (err.status === 403) {
        redirectToLoginAndReturn()
        return
      }
      throw err
    }
  }
}

/**
 * Fetch comments from backend
 *
 * @param endpoints
 * @param templateLibrary
 * @param api
 * @param postUuid
 * @param offset
 * @param limit
 * @returns {Promise<{total, comments}|{total: *, comments: *[]}>}
 */
async function fetchPosts (endpoints, templateLibrary, api, postUuid, offset, limit) {
  // For testing purposes
  if (!postUuid) {
    return {
      total: limit + 1, // this should show the load more button once
      items: [],
      isTest: true
    }
  }

  // regular behaviour
  const params = new URLSearchParams({ offset, limit }).toString()
  const endpoint = endpoints.fetchPosts(postUuid)
  const data = await apiCall(GET, `${endpoint}?${params}`)
  const serialize = async (data) => api.serializeTwistCommentData(true, data)

  const results = await Promise.all(data.results.map(serialize))

  return {
    total: data.count,
    items: results.map(item => buildComment(templateLibrary, api, item, true))
  }
}

/**
 * Fetch replies from backend
 *
 * @param endpoints
 * @param templateLibrary
 * @param api
 * @param commentUuid
 * @param offset
 * @param limit
 * @returns {Promise<{total, comments}|{total: *, comments: *[]}>}
 */
async function fetchReplies (endpoints, templateLibrary, api, commentUuid, offset, limit) {
  // For testing purposes
  if (!commentUuid) {
    return {
      total: limit + 1, // this should show the load more button once
      items: [],
      isTest: true
    }
  }

  // regular behaviour
  const params = new URLSearchParams({ offset, limit }).toString()
  const endpoint = endpoints.fetchReplies(commentUuid)// `/rest-api/v1/community/comments/${commentUuid}/replies/`
  const data = await apiCall(GET, `${endpoint}?${params}`)
  const serialize = async (data) => api.serializeTwistCommentData(false, data)

  const results = await Promise.all(data.results.map(serialize))

  return {
    total: data.count,
    items: results.map(item => buildComment(templateLibrary, api, item, false))
  }
}

/**
 * Post a comment
 *
 * @param endpoints
 * @param templateLibrary
 * @param api
 * @param postUuid
 * @param formData
 * @returns {Promise<{addReply: addReply, element: *}>}
 */
async function postPost (endpoints, templateLibrary, api, postUuid, formData) {
  // For testing purposes
  if (!postUuid) {
    const comment = {
      text: formData.get('text'),
      isOwned: true,
      replyAllowed: true,
      username: 'testUser',
      timestamp: new Date().toISOString()
    }
    return buildComment(templateLibrary, api, comment, true)
  }

  const promise = endpoints.createPost(postUuid, formData)
  const data = await resolveOrRedirectToLogin(promise)

  const commentData = await api.serializeTwistCommentData(true, data)
  return buildComment(templateLibrary, api, commentData, true)
}

/**
 * Post a reply to a comment
 *
 * @param endpoints
 * @param templateLibrary
 * @param api
 * @param commentUuid
 * @param formData
 * @returns {Promise<{addReply: addReply, subscribe: function(*=, *=): *, element: *}>}
 */
async function postReply (endpoints, templateLibrary, api, commentUuid, formData) {
  // For testing purposes
  if (!commentUuid) {
    const reply = {
      text: formData.get('text'),
      isOwned: true,
      replyAllowed: false,
      username: 'testUser',
      timestamp: new Date().toISOString()
    }
    return buildComment(templateLibrary, api, reply)
  }

  const endpoint = endpoints.createReply(commentUuid)
  const promise = apiFormCall(POST, endpoint, formData, window.CNV_APP.csrfToken)
  const data = await resolveOrRedirectToLogin(promise)

  const commentData = await api.serializeTwistCommentData(false, data)
  return buildComment(templateLibrary, api, commentData, false)
}

/**
 * Toggle a comment
 *
 * @param endpoints
 * @param isPostNotReply
 * @param uuid
 * @param on
 * @returns {Promise<{likeCount: (number)}|{likeCount: *}>}
 */
async function toggleComment (endpoints, isPostNotReply, uuid, on) {
  // For testing purposes
  if (!uuid) {
    return {
      likeCount: on ? 1 : 0
    }
  }

  // Regular behaviour
  const endpoint = (isPostNotReply ? endpoints.postToggleLike : endpoints.replyToggleLike)(uuid)
  const promise = apiCall(on ? POST : DELETE, endpoint, {}, window.CNV_APP.csrfToken)
  const data = await resolveOrRedirectToLogin(promise)

  return {
    likeCount: data.likes
  }
}

/**
 * Like a comment
 *
 * @param endpoints
 * @param isPostOrReply
 * @param uuid
 * @returns {Promise<{likeCount: number}|{likeCount: *}>}
 */
async function likeComment (endpoints, isPostOrReply, uuid) {
  return toggleComment(endpoints, isPostOrReply, uuid, true)
}

/**
 * Unlike a comment
 *
 * @param endpoints
 * @param isPostOrReply
 * @param uuid
 * @returns {Promise<{likeCount: number}|{likeCount: *}>}
 */
async function unlikeComment (endpoints, isPostOrReply, uuid) {
  return toggleComment(endpoints, isPostOrReply, uuid, false)
}

/**
 * Report a post
 *
 * @param endpoints
 * @param postUuid
 * @returns {Promise<boolean>}
 */
async function reportPost (endpoints, postUuid) {
  // For testing purposes
  if (!postUuid) {
    return true
  }

  const [endpoint, data] = endpoints.reportPost(postUuid)
  const response = await apiCall(POST, endpoint, data, window.CNV_APP.csrfToken, false)

  return response.status === 200
}

/**
 * Report a reply
 *
 * @param endpoints
 * @param commentUuid
 * @param comment
 * @returns {Promise<boolean>}
 */
async function reportReply (endpoints, commentUuid, comment = '') {
  // For testing purposes
  if (!commentUuid) {
    return true
  }

  const endpoint = endpoints.reportReply()
  const data = {
    comment_uuid: commentUuid,
    reason: 'report',
    comment: comment
  }
  const response = await apiCall(POST, endpoint, data, window.CNV_APP.csrfToken, false)

  return response.status === 200
}

/**
 * Remove a post
 *
 * @param endpoints
 * @param postUuid
 * @returns {Promise<boolean>}
 */
async function removePost (endpoints, postUuid) {
  // For testing purposes
  if (!postUuid) {
    return true
  }

  const endpoint = endpoints.removePost(postUuid)
  const response = await apiCall(DELETE, endpoint, {}, window.CNV_APP.csrfToken, false)

  return response.status === 200
}

/**
 * Remove a reply
 *
 * @param endpoints
 * @param replyUuid
 * @returns {Promise<boolean>}
 */
async function removeReply (endpoints, replyUuid) {
  // For testing purposes
  if (!replyUuid) {
    return true
  }

  const endpoint = endpoints.removeReply(replyUuid)
  const response = await apiCall(DELETE, endpoint, {}, window.CNV_APP.csrfToken, false)

  return response.status === 200
}

/**
 * * Convert twist it comment data to regular comment data
 *
 * @param memberManager
 * @param userCanAddComments
 * @param commentData
 * @returns {{likedByUser: *, likeCount: *, text, avatar: *, uuid, username: *, labels: *[]}}
 */
async function serializeTwistCommentData (memberManager, userCanAddComments, commentData) {
  const labels = []
  const serializeInner = async (data) => serializeTwistCommentData(memberManager, userCanAddComments, data)

  if (commentData.is_owned) {
    labels.push('Jij')
  }

  if (commentData.created_by?.is_cnv_expert) {
    labels.push('CNV Expert')
  }

  if (commentData.created_by?.is_moderator) {
    labels.push('Moderator')
  }

  if (memberManager) {
    for (const label of await memberManager.getRoleLabels(commentData.created_by.uuid)) {
      if (!labels.includes(label)) {
        labels.push(label)
      }
    }
  }

  const replies = await Promise.all((commentData.comments || []).map(serializeInner))

  return {
    uuid: commentData.uuid,
    text: commentData.text ? commentData.text : commentData.content.text,
    poll_uuid: commentData.content?.poll_uuid,
    avatar: commentData.created_by?.profile_photo?.url,
    username: commentData.created_by?.name,
    userSlug: commentData.created_by?.slug,
    function: commentData.created_by?.function,
    timestamp: commentData.created_at,
    isOwned: commentData.is_owned,
    labels,
    likeCount: commentData.likes,
    likedByUser: commentData.is_liked,
    replyAllowed: userCanAddComments,
    replies: replies,
    totalReplies: commentData.comments_count
  }
}

export default class CommentSection extends Component {
  init () {
    const templateComment = this.element.querySelector('.comment-section__template-comment')
    const templateCommentHeader = this.element.querySelector('.comment-section__template-comment-header')
    const templateIconReport = this.element.querySelector('.comment-section__template-icon-report')
    const templateIconRemove = this.element.querySelector('.comment-section__template-icon-remove')
    const templateDropdownMenu = this.element.querySelector('.comment-section__template-dropdown-menu')
    const templateDropdownMenuItem = this.element.querySelector('.comment-section__template-dropdown-menu-item')
    const templateLikeButton = this.element.querySelector('.comment-section__template-like-button')
    const templateList = this.element.querySelector('.comment-section__template-list')
    const templateLoadMoreButton = this.element.querySelector('.comment-section__template-load-more-button')
    const templatePoll = this.element.querySelector('.comment-section__poll')
    const templateReplyButton = this.element.querySelector('.comment-section__template-reply-button')
    const templateUserLabel = this.element.querySelector('.comment-section__template-user-label')

    this.body = this.element.querySelector('[data-role=body]')
    this.postUuid = this.element.dataset.uuid
    this.subtitleElement = this.element.querySelector('[data-role=subtitle]')

    const endpointVariant = this.element.getAttribute('data-endpoint-variant')
    const canViewComments = this.element.getAttribute('data-can-view-comments') === 'true'
    const canAddComments = this.element.getAttribute('data-can-add-comments') === 'true'

    if (endpointVariant === 'comment-reply') {
      // This is variant where main posts are comments and replies are comment replies
      this.endpoints = {
        createPost: (pageUuid, formData) => {
          const endpoint = `/rest-api/v1/community/posts/${pageUuid}/comments/`
          return apiFormCall(POST, endpoint, formData, window.CNV_APP.csrfToken)
        },
        createReply: (postUuid) => `/rest-api/v1/community/comments/${postUuid}/replies/`,
        fetchPosts: (pageUuid) => `/rest-api/v1/community/posts/${pageUuid}/comments/`,
        fetchReplies: (postUuid) => `/rest-api/v1/community/comments/${postUuid}/replies/`,
        postToggleLike: (postUuid) => `/rest-api/v1/community/comments/${postUuid}/like/`,
        reportPost: (postUuid) => [`/rest-api/v1/report_entity/comment/`, { comment_uuid: postUuid }],
        reportReply: () => `/rest-api/v1/report_entity/comment/`,
        removePost: (postUuid) => `/rest-api/v1/community/comments/${postUuid}/`,
        removeReply: (replyUuid) => `/rest-api/v1/community/comments/${replyUuid}/`,
        replyToggleLike: (postUuid) => `/rest-api/v1/community/comments/${postUuid}/like/`
      }
    } else if (endpointVariant === 'post-comment') {
      // This is variant where main posts are posts and replies are post comments
      this.endpoints = {
        createPost: (pageUuid, formData) => {
          const endpoint = `/rest-api/v1/community/posts/`
          return apiCall(
            POST,
            endpoint,
            {
              type: 'private',
              content: {
                text: formData.get('text')
              },
              destination_uuid: pageUuid,
              visible_in: []
            },
            window.CNV_APP.csrfToken
          )
        },
        createReply: (postUuid) => `/rest-api/v1/community/posts/${postUuid}/comments/`,
        fetchPosts: (pageUuid) => `/rest-api/v1/community/pages/${pageUuid}/feed/`,
        fetchReplies: (postUuid) => `/rest-api/v1/community/posts/${postUuid}/comments/`,
        postToggleLike: (postUuid) => `/rest-api/v1/community/posts/${postUuid}/like/`,
        reportPost: (postUuid) => [`/rest-api/v1/report_entity/post/`, { post_uuid: postUuid }],
        reportReply: () => `/rest-api/v1/report_entity/comment/`,
        removePost: (postUuid) => `/rest-api/v1/community/posts/${postUuid}/`,
        removeReply: (replyUuid) => `/rest-api/v1/community/comments/${replyUuid}/`,
        replyToggleLike: (postUuid) => `/rest-api/v1/community/comments/${postUuid}/like/`
      }
    } else {
      console.log('Unknown comment-section variant: ', endpointVariant)
      return
    }

    this.library = {
      comment: templateComment,
      commentHeader: templateCommentHeader,
      dropdownMenu: templateDropdownMenu,
      dropdownMenuItem: templateDropdownMenuItem,
      iconReport: templateIconReport,
      iconRemove: templateIconRemove,
      likeButton: templateLikeButton,
      list: templateList,
      loadMoreButton: templateLoadMoreButton,
      poll: templatePoll,
      replyButton: templateReplyButton,
      userLabel: templateUserLabel
    }

    const groupMemberManager = endpointVariant === 'post-comment' ? GroupMemberManager(this.postUuid) : null

    this.api = {
      canAddComments: () => !!canAddComments,
      fetchPosts: (offset, limit) => fetchPosts(this.endpoints, this.library, this.api, this.postUuid, offset, limit),
      fetchReplies: (commentUuid, offset, limit) => fetchReplies(this.endpoints, this.library, this.api, commentUuid, offset, limit),
      likeComment: (isPostNotReply, uuid) => likeComment(this.endpoints, isPostNotReply, uuid),
      unlikeComment: (isPostNotReply, uuid) => unlikeComment(this.endpoints, isPostNotReply, uuid),
      postPost: (formData) => postPost(this.endpoints, this.library, this.api, this.postUuid, formData),
      postReply: (commentUuid, formData) => postReply(this.endpoints, this.library, this.api, commentUuid, formData),
      reportPost: (postUuid) => reportPost(this.endpoints, postUuid),
      reportReply: (commentUuid, comments) => reportReply(this.endpoints, commentUuid, comments),
      removePost: (postUuid) => removePost(this.endpoints, postUuid),
      removeReply: (commentUuid) => removeReply(this.endpoints, commentUuid),
      serializeTwistCommentData: (userCanAddComments, commentData) => serializeTwistCommentData(groupMemberManager, userCanAddComments, commentData)
    }

    // Build the main comments list
    if (canViewComments) {
      this.mainCommentsList = ItemList(this.library.list, this.library.loadMoreButton, this.api.fetchPosts)
      this.body.appendChild(this.mainCommentsList.element)

      this.mainCommentsList.subscribe((detail) => {
        this.element.classList.toggle('comment-section--is-empty', detail.itemCount === 0)

        if (this.subtitleElement) {
          this.subtitleElement.innerText = formatSubtitle(detail.totalItemCount)
        }
      })
    }

    // Build the reaction form
    if (canViewComments && canAddComments) {
      const form = this.element.querySelector('form')
      if (form) {
        form.addEventListener('submit', async (event) => {
          event.preventDefault()
          const comment = await this.api.postPost(new FormData(form))
          this.mainCommentsList.addItem(comment)
          form.querySelector('[name=text]').value = ''
        })
      }
    }
  }

  /**
   * Add a new comment to the comment section
   *
   * @param commentData
   * @returns {{addReply: addReply, subscribe: function(*=, *=): *, element: *}}
   */
  addComment (commentData) {
    const fullCommentData = { replyAllowed: true, ...commentData }
    const newComment = buildComment(this.library, this.api, fullCommentData, true)
    this.mainCommentsList.addItem(newComment)
    return newComment
  }

  /**
   * Add a new reply to a certain comment in the comment section
   *
   * @param comment
   * @param replyData
   * @returns {{addReply: addReply, subscribe: function(*=, *=): *, element: *}}
   */
  addReply (comment, replyData) {
    const fullCommentData = { replyAllowed: false, ...replyData }
    const newComment = buildComment(this.library, this.api, fullCommentData, false)
    comment.addReply(newComment)
    return newComment
  }

  /**
   * Fetch a new page of comments.
   *
   * Can be used for testing purposes to hide the load button.
   */
  fetchPage () {
    this.mainCommentsList.fetchPage()
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.comment-section').forEach(element => {
  element.instance = element.instance || new CommentSection(element)
}))
