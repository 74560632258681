
import Component from '../../../assets/scripts/modules/component'

export default class TickerTapeComponent extends Component {
  init () {
    this.btn = this.element.querySelector('.ticker-tape__icon')
    if (this.btn) {
      this.btn.addEventListener('click', () => {
        const toggleClass = 'ticker-tape--closed'
        const isCollapsed = this.element.classList.contains(toggleClass)
        this.btn.setAttribute('aria-expanded', isCollapsed)
        this.element.classList.toggle(toggleClass)

        if (!isCollapsed && this.element.getBoundingClientRect().top < 0) {
          this.element.scrollIntoView(true)
        }
      })
    }
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.ticker-tape').forEach(element => {
  element.instance = element.instance || new TickerTapeComponent(element)
}))
