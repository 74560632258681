import asyncFormSubmitter from '../../../assets/scripts/interfaces/async-form-submitter'
import Component from '../../../assets/scripts/modules/component'
import { hydratorRegisterComponent } from '../../../assets/scripts/utilities/hydrator'

export default class ButtonToggleComponent extends Component {
  init () {
    const toggleClass = 'button-toggle--is-collapsed'
    const endpoint = this.element.getAttribute('data-endpoint')
    const isManual = this.element.getAttribute('data-manual') !== null

    if (endpoint) {
      //  Deprecated
      const { submit } = asyncFormSubmitter(endpoint, window.CNV_APP.csrfToken)
      this.element.addEventListener('click', async () => {
        const newState = this.element.classList.contains(toggleClass)
        const formData = new FormData()
        formData.append('is_private', newState)
        const response = await submit(formData)
        if (response.success) {
          this.element.classList[newState ? 'remove' : 'add'](toggleClass)
          this.element.setAttribute('aria-expanded', newState)
        }
      })
    } else if (!isManual) {
      this.element.addEventListener('click', () => {
        const isCollapsed = this.element.classList.contains(toggleClass)
        this.element.setAttribute('aria-expanded', isCollapsed)
        this.element.classList.toggle(toggleClass)
      })
    }
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.button-toggle').forEach(element => {
  element.instance = element.instance || new ButtonToggleComponent(element)
}))
hydratorRegisterComponent('.button-toggle', ButtonToggleComponent)
