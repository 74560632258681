import { DropdownMenu } from '../../../assets/scripts/components/dropdown-menu'
import Component from '../../../assets/scripts/modules/component'
import { clickedOutsideMultiple } from '../../../assets/scripts/utilities/outside-click'

export class NavigationBarComponent extends Component {
  init () {
    this.body = document.querySelector('body')
    this.menuPanels = this.element.querySelectorAll('.menu-panel')
    this.menuLinks = this.element.querySelectorAll('.menu-link__link')

    this.logoutButton = this.element.querySelector('.navigation-bar__logout-button')
    this.loginButton = this.element.querySelector('.navigation-bar__login-button')
    this.backButton = this.element.querySelector('.navigation-bar__back-button')

    this.loggedInContent = this.element.querySelectorAll('.navigation-bar__logged-in-content')

    this.userAvatar = this.element.querySelectorAll('[data-role="user-avatar"]')
    this.userName = this.element.querySelectorAll('[data-role="user-fullname"]')
    this.loginUrl = this.element.querySelectorAll('[data-role="login-url"]')
    this.logoutUrl = this.element.querySelectorAll('[data-role="logout-url"]')
    this.profileUrl = this.element.querySelectorAll('[data-role="user-profile-url"]')
    this.swapUrl = this.element.querySelectorAll('[data-role="user-swap-url"]')

    this.overlay = this.element.querySelector('.navigation-bar__overlay')
    this.overlayOpenButton = this.element.querySelector('.navigation-bar__hamburger-button')
    this.overlayCloseButton = this.element.querySelector('.navigation-bar__close-button')
    this.linksOverlay = this.element.querySelector('.navigation-bar__links-overlay')
    this.activeCardOverlay = document.getElementsByClassName('menu-panel-login-dropdown__close')

    this.profileButton = this.element.querySelector('[data-role="profile-button"]')
    this.profilePanel = this.element.querySelector('[data-role="profile-panel"]')
    this.profileOverlayButton = this.element.querySelector('.navigation-bar__profile-link')
    this.profileOverlay = this.element.querySelector('.navigation-bar__profile-overlay')
    this.mobileProfileSection = this.element.querySelector('.navigation-bar__mobile .navigation-bar__profile')
    this.desktopProfileSection = this.element.querySelector('.navigation-bar__desktop .navigation-bar__profile')
    this.desktopProfilePopup = this.desktopProfileSection.querySelector('.navigation-bar__profile-popup')
    this.navigationProfileUrl = this.element.querySelector('.navigation-bar__profile-url')

    this.notifications = this.element.querySelector('.navigation-bar__notifications')

    this.setMenuState()
    this.assignVariables()
    this.initListeners()
    this.initDatalayer()
  }

  initDatalayer () {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'login',
      loginstatus: window.CNV_APP?.isLoggedIn ? 'ingelogd' : 'uitgelogd',
      lidstatus: window.CNV_APP?.userIsMember ? 'lid' : 'geen lid'
    })
  }

  setMenuState () {
    // If you have #menu-popup in the URL, on reload the menu will be open
    var hash = window.location.hash
    if (hash) {
      const link = document.querySelector(`a[href="${hash}"]`)
      const menuLink = link?.parentElement
      const menuPanel = link?.nextElementSibling
      menuLink?.classList.add('menu-link--active')
      menuPanel?.classList.remove('menu-panel--inactive')

      // Check if we have nested menuLink + menuPanel
      if (menuLink.parentElement.classList.contains('menu-panel__link-group')) {
        const parentMenuPanel = menuLink.parentElement.parentElement.parentElement
        const parentMenuLink = parentMenuPanel.parentElement
        parentMenuPanel?.classList.remove('menu-panel--inactive')
        parentMenuLink?.classList.add('menu-link--active')
      }
    }
  }

  initListeners () {
    if (this.profileButton && this.profilePanel) {
      const profilePanel = DropdownMenu(this.profilePanel)

      this.profileButton.addEventListener('click', (event) => {
        event.preventDefault()

        this.profileButton.classList.toggle('menu-panel-profile--filled')
        profilePanel.isOpen() ? profilePanel.close() : profilePanel.open()
      })

      const box = document.querySelector('.menu-bar__profile-button')

      document.addEventListener('click', (e) => {
        if (clickedOutsideMultiple(e, [this.profileButton, this.profilePanel])) {
          box.classList.remove('menu-panel-profile--filled')
          profilePanel.close()
        }
      })

      window.addEventListener('close-menu-panels', () => {
        profilePanel.close()
        this.closeMenu()
      })
    }

    this.profileOverlayButton?.addEventListener('click', () => {
      // Only do this if the user is logged in
      if (window.CNV_APP?.isLoggedIn) {
        this.openMobileProfileOverlay()
      }
    })

    this.desktopProfileSection.addEventListener('click', () => {
      if (this.desktopProfilePopup.classList.contains('active')) {
        this.closeDesktopProfilePopup()
      } else {
        this.openDesktopProfilePopup()
      }
    })

    for (const link of this.menuLinks) {
      link.addEventListener('click', () => {
        // Only open one
        this.closeDesktopProfilePopup()
      })
    }

    this.backButton.addEventListener('click', () => this.closeMobileProfileOverlay())

    this.overlayOpenButton.addEventListener('click', () => this.openMenu())
    this.overlayCloseButton.addEventListener('click', () => this.closeMenu())

    if (this.activeCardOverlay.length > 0) {
      const cardOverlays = document.querySelectorAll('.menu-panel-login-dropdown')

      for (const cardOverlay of cardOverlays) {
        cardOverlay.addEventListener('click', function handleClick () {
          cardOverlay.classList.add('is-collapsed')
        })
      }
    }
  }

  assignVariables () {
    const menuClass = window.CNV_APP?.isLoggedIn ? 'logged-in' : 'logged-out'
    this.element?.classList.add(menuClass)

    const profileTitle = this.profileButton?.querySelector('.menu-panel-profile__title')
    if (profileTitle) {
      profileTitle.innerText = window.CNV_APP?.userFullName
    }

    for (const el of this.userAvatar) {
      el.src = window.CNV_APP?.userAvatar
    }

    for (const el of this.userName) {
      el.innerText = window.CNV_APP?.userFullName
    }

    for (const el of this.loginUrl) {
      el.href = window.CNV_APP?.loginUrl
    }

    for (const el of this.logoutUrl) {
      el.href = window.CNV_APP?.logoutUrl
    }

    for (const el of this.profileUrl) {
      el.href = window.CNV_APP?.myProfileUrl
    }

    for (const el of this.swapUrl) {
      el.href = window.CNV_APP?.swapUrl
    }

    if (window.CNV_APP?.isLoggedIn) {
      this.loginButton?.classList.add('hide')
    } else {
      this.notifications.style.display = 'none'
      this.mobileProfileSection?.classList.remove('active')
      this.logoutButton?.classList.add('hide')
      this.loginButton?.classList.remove('hide')
      for (const content of this.loggedInContent) {
        content.style.display = 'none'
      }
      this.navigationProfileUrl.firstElementChild.href = window.CNV_APP?.loginUrl
    }
  }

  closeMenu () {
    // This is the mobile menu
    this.overlay.classList.remove('active')
    this.element.classList.add('active')
    this.body.classList.remove('no-scroll')
    this.overlayOpenButton.classList.remove('hide')
    this.overlayCloseButton.classList.add('hide')
    this.setMenuState()
  }

  openMenu () {
    // This is the mobile menu
    for (const panel of this.menuPanels) {
      panel.classList.add('menu-panel--inactive')
    }
    this.overlay.classList.add('active')
    this.element.classList.remove('active')
    this.body.classList.add('no-scroll')
    this.overlayOpenButton.classList.add('hide')
    this.overlayCloseButton.classList.remove('hide')
  }

  openDesktopProfilePopup () {
    this.desktopProfilePopup.classList.add('active')

    const openPanels = document.querySelectorAll('.menu-panel:not(.menu-panel--inactive)')
    for (const panel of openPanels) {
      panel.classList.add('menu-panel--inactive')
    }
  }

  closeDesktopProfilePopup () {
    this.desktopProfilePopup.classList.remove('active')
  }

  openMobileProfileOverlay () {
    this.mobileProfileSection.classList.remove('active')
    this.linksOverlay.classList.remove('active')
    this.profileOverlay.classList.add('active')
  }

  closeMobileProfileOverlay () {
    this.mobileProfileSection.classList.add('active')
    this.linksOverlay.classList.add('active')
    this.profileOverlay.classList.remove('active')
  }
}

export default class MenuBarComponent extends Component {
  init () {
    this.profileButton = this.element.querySelector('[data-role="profile-button"]')
    this.profilePanel = this.element.querySelector('[data-role="profile-panel"]')

    this.userAvatar = this.element.querySelectorAll('[data-role="user-avatar"]')
    this.userName = this.element.querySelectorAll('[data-role="user-fullname"]')
    this.loginUrl = this.element.querySelectorAll('[data-role="login-url"]')
    this.logoutUrl = this.element.querySelectorAll('[data-role="logout-url"]')
    this.profileUrl = this.element.querySelectorAll('[data-role="user-profile-url"]')
    this.swapUrl = this.element.querySelectorAll('[data-role="user-swap-url"]')

    this.mainMenu = this.element.querySelector('.menu-bar__main-menu')

    this.activeCardOverlay = document.getElementsByClassName('menu-panel-login-dropdown__close')

    this.assignVariables()
    this.initListeners()
    this.initDatalayer()
  }

  initDatalayer () {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'login',
      loginstatus: window.CNV_APP?.isLoggedIn ? 'ingelogd' : 'uitgelogd',
      lidstatus: window.CNV_APP?.userIsMember ? 'lid' : 'geen lid'
    })
  }

  initListeners () {
    if (this.profileButton && this.profilePanel) {
      const profilePanel = DropdownMenu(this.profilePanel)

      this.profileButton.addEventListener('click', (event) => {
        event.preventDefault()

        this.profileButton.classList.toggle('menu-panel-profile--filled')
        profilePanel.isOpen() ? profilePanel.close() : profilePanel.open()
      })

      const box = document.querySelector('.menu-bar__profile-button')

      document.addEventListener('click', (e) => {
        if (clickedOutsideMultiple(e, [this.profileButton, this.profilePanel])) {
          box.classList.remove('menu-panel-profile--filled')
          profilePanel.close()
        }
      })

      window.addEventListener('close-menu-panels', () => {
        profilePanel.close()
        this.toggleMenu(false)
      })
    }

    Array.from(this.element.querySelectorAll('.menu-bar__menu-opener, .menu-bar__menu-closer')).forEach((el) => {
      el.addEventListener('click', () => this.toggleMenu())
    })

    if (this.activeCardOverlay.length > 0) {
      const cardOverlays = document.querySelectorAll('.menu-panel-login-dropdown')

      for (const cardOverlay of cardOverlays) {
        cardOverlay.addEventListener('click', function handleClick () {
          cardOverlay.classList.add('is-collapsed')
        })
      }
    }
  }

  assignVariables () {
    const menuClass = window.CNV_APP?.isLoggedIn ? 'menu-bar--logged-in' : 'menu-bar--logged-out'
    this.element.classList.add(menuClass)

    const profileTitle = this.profileButton.querySelector('.menu-panel-profile__title')
    profileTitle.innerText = window.CNV_APP?.userFullName

    for (const el of this.userAvatar) {
      el.src = window.CNV_APP?.userAvatar
    }

    for (const el of this.userName) {
      el.innerText = window.CNV_APP?.userFullName
    }

    for (const el of this.loginUrl) {
      el.href = window.CNV_APP?.loginUrl
    }

    for (const el of this.logoutUrl) {
      el.href = window.CNV_APP?.logoutUrl
    }

    for (const el of this.profileUrl) {
      el.href = window.CNV_APP?.myProfileUrl
    }

    for (const el of this.swapUrl) {
      el.href = window.CNV_APP?.swapUrl
    }
  }

  // Mobile menu toggle
  toggleMenu (makeOpen = null) {
    const { element, mainMenu } = this
    const isOpen = makeOpen !== null ? !makeOpen : !mainMenu.classList.contains('menu-panel--inactive')

    if (isOpen) {
      Array.from(this.element.querySelectorAll('.menu-panel')).forEach((el) => {
        el.classList.add('menu-panel--inactive')
      })
      element.classList.remove('menu-bar--active')
      document.body.classList.remove('no-scroll')
    } else {
      mainMenu.classList.remove('menu-panel--inactive')
      element.classList.add('menu-bar--active')
      document.body.classList.add('no-scroll')
    }
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.menu-bar').forEach((element) => {
    element.instance = element.instance || new MenuBarComponent(element)
  })
)

window.addEventListener('init-load', () =>
  document.querySelectorAll('.navigation-bar').forEach((element) => {
    element.instance = element.instance || new NavigationBarComponent(element)
  })
)
