
const submissionResponse = (success, errors, extra) => ({
  success, // boolean
  errors, // list of strings
  extra // object
})

const modalSubmissionResponse = (completed, html, errors, extra) => ({
  completed, // boolean
  html, // string
  errors, // list of strings
  extra // object
})

const asyncFormSubmitter = (endpoint, csrftoken, responseType = 'default') => {
  const request = new Request(
    endpoint,
    { headers: { 'X-CSRFToken': csrftoken } }
  )
  const submit = async (formData) => {
    const response = await fetch(request, { body: formData, method: 'post', mode: 'same-origin' })
    const data = await response.json()
    if (responseType === 'modal') {
      return modalSubmissionResponse(data.completed, data.html, data.errors, data.extra)
    }
    return submissionResponse(data.success, data.errors, data.extra)
  }

  return {
    submit
  }
}

export default asyncFormSubmitter
