import Cleave from 'cleave.js'

import Component from '../../../assets/scripts/modules/component'
import escapeHTML from '../../../assets/scripts/utilities/escape-html'
import findParentBySelector from '../../../assets/scripts/utilities/find-parent'
import { hydratorRegisterComponent } from '../../../assets/scripts/utilities/hydrator'

window.onload = function () {
  if (document.querySelector('.text-field--error input') != null) {
    document.querySelector('.text-field--error input').focus()
  }
}

const CLASSNAME_HAS_VALUE = 'text-field--has-value'
const CLASSNAME_HAS_CHANGED = 'text-field--has-changed'

function showCharacterCount (element, input, format = '%c / %m') {
  function update () {
    current = input.value.length
    display.innerText = format.replace('%c', current).replace('%m', max)
  }

  const display = element.querySelector('[data-role=max-length-display]')

  if (!input || !display) {
    console.log('failed to initialise character counter for', element)
    return
  }

  display.parentNode.classList.add('text-field__wrapper--max-count')

  const max = parseInt(input.getAttribute('maxlength'), 10) || 0
  let current = 0
  element.addEventListener('input', () => update())
  element.addEventListener('change', () => update())
  update()
}

class TextFieldComponent extends Component {
  init () {
    this.input = this.element.querySelector('input, textarea')
    this.initialValue = this.input.value
    this.input.addEventListener('input', (event) => {
      this.element.classList[event.target.value ? 'add' : 'remove'](CLASSNAME_HAS_VALUE)
      this.element.classList[event.target.value !== this.initialValue ? 'add' : 'remove'](CLASSNAME_HAS_CHANGED)
      this.element.classList.remove('text-field--error')
    })

    // For auto-growing textarea, taken from https://css-tricks.com/auto-growing-inputs-textareas/
    this.element.querySelector('textarea')?.addEventListener('input', () => {
      this.input.parentNode.dataset.replicatedValue = this.input.value
    })

    if (this.element.classList.contains('text-field--date')) {
      // eslint-disable-next-line no-new
      new Cleave(this.input, {
        date: true,
        delimiter: '-',
        datePattern: ['d', 'm', 'Y']
      })
    }

    const formElement = findParentBySelector(this.element, 'form')
    window.addEventListener('form-error', (event) => {
      const fieldName = this.input.name
      if (event.detail.formElement === formElement && fieldName === event.detail.fieldName) {
        this.element.querySelector('[data-role="error"]').innerHTML = event.detail.errors.map(escapeHTML).join('<br>')
        this.element.classList.remove('text-field--has-changed')
        this.element.classList.add('text-field--error')
      }
    })

    if (this.input.hasAttribute('maxlength')) {
      showCharacterCount(this.element, this.input)
    }
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.text-field').forEach(element => {
  element.instance = element.instance || new TextFieldComponent(element)
}))
hydratorRegisterComponent('.text-field', TextFieldComponent)
