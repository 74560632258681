import debounce from 'lodash/debounce'

import { apiCall, POST } from '../../../assets/scripts/interfaces/api-call'
import Component from '../../../assets/scripts/modules/component'

class AddressFieldComponent extends Component {
  init () {
    this.checkboxAbroad = this.element.querySelector('.address-field__input-abroad')
    this.checkboxHasAdjustAddressManually = this.element.querySelector('.address-field__input-adjusted-address-manually')

    this.sectionDutch = this.element.querySelector('.address-field__section-dutch')
    this.sectionAbroad = this.element.querySelector('.address-field__section-abroad')
    this.element.querySelector('.address-field__btn-abroad').addEventListener('click', () => {
      this.sectionDutch.setAttribute('hidden', '')
      this.sectionAbroad.removeAttribute('hidden', '')
      this.checkboxAbroad.checked = true
    })
    this.element.querySelector('.address-field__btn-dutch').addEventListener('click', () => {
      this.sectionAbroad.setAttribute('hidden', '')
      this.sectionDutch.removeAttribute('hidden', '')
      this.checkboxAbroad.checked = false
    })

    this.inputPostcode = this.element.querySelector('.address-field__postcode input')
    this.inputHouseNumber = this.element.querySelector('.address-field__house-number input')
    this.inputHouseNumberAddition = this.element.querySelector('.address-field__house-number-addition input')
    this.inputCity = this.element.querySelector('.address-field__city input')
    this.inputStreet = this.element.querySelector('.address-field__street input')

    this.inputsForApi = [this.inputPostcode, this.inputHouseNumber, this.inputHouseNumberAddition]
    this.inputsForApi.forEach(input => input.addEventListener('input', debounce(this.fillAddress.bind(this), 500)))

    this.elResults = this.element.querySelector('.address-field__result-wrapper')
    this.elResultsAddress = this.element.querySelector('.address-field__result-address')
    this.elNoResults = this.element.querySelector('.address-field__no-results')
    this.elCityStreetFielsContainer = this.element.querySelector('.address-field__city-street-container')
    this.elChangeAddressBtn = this.element.querySelector('.address-field_btn-change')

    this.elChangeAddressBtn.addEventListener('click', () => {
      this.elCityStreetFielsContainer.removeAttribute('hidden', '')
      this.elResults.setAttribute('hidden', '')
      this.checkboxHasAdjustAddressManually.checked = true
    })

    this.apiUrl = this.element.getAttribute('data-api-url')
  }

  async fillAddress () {
    if (this.inputPostcode.value && this.inputHouseNumber.value) {
      const response = await this.fetchAddress(this.inputPostcode.value, this.inputHouseNumber.value, this.inputHouseNumberAddition.value)

      this.checkboxHasAdjustAddressManually.checked = false
      this.elResults.setAttribute('hidden', '')
      this.elNoResults.setAttribute('hidden', '')
      this.elCityStreetFielsContainer.setAttribute('hidden', '')

      if (response.ok) {
        const { data } = response

        this.inputCity.value = data.city
        this.inputCity.dispatchEvent(new Event('input'))
        this.inputStreet.value = data.street
        this.inputStreet.dispatchEvent(new Event('input'))

        const { street, houseNumber, houseNumberAddition } = data

        this.elResultsAddress.innerText = `${street || ''} ${houseNumber || ''}${houseNumberAddition || ''} \n ${this.inputPostcode.value} ${data.city}`
        this.elResults.removeAttribute('hidden', '')
      } else {
        this.elNoResults.removeAttribute('hidden', '')
        this.elCityStreetFielsContainer.removeAttribute('hidden', '')
        this.checkboxHasAdjustAddressManually.checked = true
      }
    }
  }

  async fetchAddress (postcode, houseNumber, houseNumberAddition) {
    if (!this.apiUrl) {
      return { ok: false }
    }

    const params = new FormData()
    params.set('postcode', postcode)
    params.set('house_number', houseNumber)
    params.set('house_number_addition', houseNumberAddition)

    return apiCall(POST, this.apiUrl, params, window.CNV_APP.csrfToken, true, false)
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.address-field').forEach(element => {
  element.instance = element.instance || new AddressFieldComponent(element)
}))
