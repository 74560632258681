import asyncFormSubmitter from '../../../assets/scripts/interfaces/async-form-submitter'
import Component from '../../../assets/scripts/modules/component'

// We can't directly modify a FileList (such as input.files).
// So, removing selected uploads one-by-one is impossible. Or is it?
// We can replace the FileList with another FileList, like that of a different DataTransfer object.
const removeItemFromFileList = function (fileList, name) {
  let files = [...fileList]
  files = files.filter((file) => file.name !== name)

  if (files.length === fileList.length) {
    return fileList // No change
  }

  // NOTE: Does Safari on iOS support DataTransfer.{files|items}? Can't test right now -EKL
  const transfer = new DataTransfer()
  files.forEach((file) => transfer.items.add(file))

  return transfer.files
}

const prettyPrintFileSize = (bytes) => (bytes > 1024000 ? `${(bytes / 1024 / 1000).toFixed(2).toString()} MB` : `${(bytes / 1024).toFixed(0).toString()} kB`)

// TODO: Add drag n drop

export default class FileFieldComponent extends Component {
  init () {
    this.input = this.element.querySelector('.file-field__input')
    this.previews = this.element.querySelector('.file-field__previews')
    this.template = this.element.querySelector('.file-field__preview-template')
    this.buttonAdd = this.element.querySelector('.button--add')
    this.buttonClear = this.element.querySelector('.button--clear')
    this.populatePreviews = this.populatePreviews.bind(this)

    if (!this.input || !this.previews || !this.template || !this.buttonAdd || !this.buttonClear) {
      console.log('Failed to initialise file field.')
      return
    }

    if (this.element.dataset.action) {
      const { submit } = asyncFormSubmitter(this.element.dataset.action, window.CNV_APP.csrfToken)
      this.input.addEventListener('change', async (event) => {
        const populatePreviews = this.populatePreviews
        const dataURItoBlob = this.dataURItoBlob

        const formData = new FormData()
        const img = document.createElement('img')
        const reader = new FileReader()
        let dataurl = null

        reader.onload = function (e) {
          img.src = e.target.result

          img.onload = async function () {
            var canvas = document.createElement('canvas')

            const MAX_WIDTH = 999
            const MAX_HEIGHT = 999
            const width = img.width
            const height = img.height

            canvas.width = width > MAX_WIDTH ? MAX_WIDTH : width
            canvas.height = height > MAX_HEIGHT ? MAX_HEIGHT : height
            const ctx = canvas.getContext('2d')

            const placementX = width > MAX_WIDTH ? (MAX_WIDTH - width) / 2 : 0
            const placementY = height > MAX_HEIGHT ? (MAX_HEIGHT - height) / 2 : 0
            ctx.drawImage(img, placementX, placementY, width, height)

            dataurl = canvas.toDataURL('image/jpeg')
            formData.append('image', dataURItoBlob(dataurl), 'image.jpeg')

            const response = await submit(formData)

            if (response.success) {
              populatePreviews(event, [response.extra.image])
            } else {
              alert(response.errors.map((a) => a[1]).join('\n'))
            }
          } // img.onload
        }
        // Load files into file reader
        reader.readAsDataURL(this.input.files[0])
      })
      this.buttonClear.addEventListener('click', async (event) => {
        const formData = new FormData()
        const response = await submit(formData)
        if (response.success) {
          this.resetFileField(event, [])
        } else {
          alert(response.errors.map((a) => a[1]).join('\n'))
        }
      })
    }
  }

  populatePreviews (event, objects = null) {
    this.previews.innerHTML = ''

    const files = objects || [...this.input.files]

    if (!files.length) {
      this.resetFileField(event)
      return
    }

    files.forEach((file) => this.addPreview(file))
    this.element.classList.add('file-field--has-files')
  }

  addPreview (file) {
    console.log(file)
    const clone = this.template.content.cloneNode(true)

    const preview = clone.querySelector('.file-field__preview')
    const image = clone.querySelector('.file-field__preview-image')
    const filename = clone.querySelector('.file-field__preview-filename')
    const filesize = clone.querySelector('.file-field__preview-filesize')
    const resolution = clone.querySelector('.file-field__preview-resolution')

    preview.dataset.name = file.name

    // if (!['.jpg', '.jpeg', '.png'].some(ext => file.name.endsWith(ext))) {
    //   return
    // }

    if (file.url) {
      image.src = file.url
    } else {
      image.src = URL.createObjectURL(file)
    }
    image.onload = () => {
      URL.revokeObjectURL(image.src) // Prevent memory leak

      if (resolution) {
        resolution.innerText = `${image.naturalWidth}×${image.naturalHeight}px`
      }
    }

    if (filename && file.name) {
      filename.innerText = file.name.split('\\')
    }

    if (filesize) {
      filesize.innerText = `(${prettyPrintFileSize(file.size)})`
    }

    this.previews.appendChild(clone)

    preview.addEventListener('click', (event) => this.removeFile(preview))
  }

  removeFile (preview) {
    if (!preview) {
      return
    }

    this.input.files = removeItemFromFileList(this.input.files, preview.dataset.name)
    preview.parentNode.removeChild(preview)

    if (!this.input.files.length) {
      this.resetFileField()
    }
  }

  resetFileField (event) {
    if (event) {
      event.preventDefault()
      event.target.blur()
    }

    this.input.value = ''
    this.previews.innerHTML = ''
    this.element.classList.remove('file-field--has-files')
  }

  dataURItoBlob (dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1])

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length)
    var ia = new Uint8Array(ab)
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }

    return new Blob([ab], { type: mimeString })
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.file-field')) {
    element.instance = element.instance || new FileFieldComponent(element)
  }
})
