/**
 * Create a dropdown menu
 */

function DropdownMenu (templateOrElement) {
  /**
   * Add a dropdown menu item
   *
   * @param item
   * @returns {*}
   */
  function addItem (item) {
    item.subscribe(close)
    items.appendChild(item.element)
    return item
  }

  /**
   * Remove a dropdown menu item
   *
   * @param item
   */
  function removeItem (item) {
    items.removeChild(item.element)
  }

  /**
   * Open the dropdown
   */
  function open () {
    element.classList.remove('is-collapsed')
    element.setAttribute('aria-expanded', 'true')
  }

  /**
   * Close the dropdown
   */
  function close () {
    element.classList.add('is-collapsed')
    element.setAttribute('aria-expanded', 'false')
  }

  /**
   * Return whether the dropdown is opened
   */
  function isOpen () {
    return !element.classList.contains('is-collapsed')
  }

  // Supports both a direct element and a template
  const template = templateOrElement.tagName === 'TEMPLATE' ? templateOrElement : null
  const fragment = template ? template.content.cloneNode(true) : templateOrElement
  const element = template ? fragment.firstElementChild : templateOrElement
  const items = fragment.querySelector('[data-role=menu-items]')

  element.setAttribute('aria-expanded', isOpen() ? 'false' : 'true')

  return {
    open,
    close,
    isOpen,
    addItem,
    removeItem,
    element
  }
}

export { DropdownMenu }
