import Component from '../../../assets/scripts/modules/component'

class DropdownComponent extends Component {
  init () {
    this.element.querySelector('select').addEventListener('change', (event) => {
      if (event.currentTarget.value) {
        this.element.classList.add('dropdown-field--has-value')
      } else {
        this.element.classList.add('dropdown-field--has-value')
      }
    })
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.dropdown-field').forEach(element => {
  element.instance = element.instance || new DropdownComponent(element)
}))
