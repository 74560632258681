import Component from '../../../assets/scripts/modules/component'

export default class CardGridExpandableComponent extends Component {
  init () {
    this.btn = this.element.querySelector('.card-grid-expandable__btn')
    if (this.btn) {
      this.btn.addEventListener('click', () => {
        const toggleClass = 'is-collapsed'
        const isCollapsed = this.element.classList.contains(toggleClass)
        this.btn.setAttribute('aria-expanded', isCollapsed)
        this.element.classList.toggle(toggleClass)

        if (!isCollapsed && this.element.getBoundingClientRect().top < 0) {
          this.element.scrollIntoView(true)
        }
      })
    }
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.card-grid-expandable').forEach(element => {
  element.instance = element.instance || new CardGridExpandableComponent(element)
}))
