import Component from '../../../assets/scripts/modules/component'
import findParentBySelector from '../../../assets/scripts/utilities/find-parent'
import fireCustomEvent from '../../../assets/scripts/utilities/fire-custom-event'
import { hydratorRegisterComponent } from '../../../assets/scripts/utilities/hydrator'

export default class ChooseSectorComponent extends Component {
  init () {
    const select = this.element.querySelector('select')
    select.addEventListener('change', () => {
      const form = findParentBySelector(select, 'form')
      if (form) {
        fireCustomEvent('custom-submit', {}, false, false, form)
      }
    })

    const dropdown = this.element.querySelector('.dropdown-menu')
    const btn = this.element.querySelector('.dropdown-menu__btn')

    const toggleClass = 'is-collapsed'

    btn.addEventListener('click', () => {
      const isCollapsed = dropdown.classList.contains(toggleClass)
      btn.setAttribute('aria-expanded', isCollapsed)
      dropdown.classList.toggle(toggleClass)

      if (isCollapsed) {
        const maxItems = 4
        const itemsContainer = this.element.querySelector('.dropdown-menu__items')
        const itemHeight = this.element.querySelector('.dropdown-menu__item').clientHeight
        itemsContainer.style.maxHeight = `${maxItems + itemHeight * maxItems}px`
      }
    })

    this.element.querySelectorAll('ul li a').forEach(link => {
      link.addEventListener('click', (e) => {
        e.preventDefault()
        const cluster = e.target.innerText
        btn.innerHTML = `<strong>${e.target.innerText}</strong>`
        dropdown.classList.toggle(toggleClass)

        this.element.querySelectorAll('.sub-options').forEach(options => {
          options.style.display = 'none'
        })

        this.element.querySelector(`[data-cluster-name="${cluster}"]`).style.display = 'flex'
      })
    })

    this.element.querySelectorAll('.sector-link').forEach(link => {
      link.addEventListener('click', (e) => {
        e.preventDefault()
        select.value = link.getAttribute('data-sector-value')
        const form = select.closest('form')// findParentBySelector(select, 'form')
        if (form) {
          fireCustomEvent('custom-submit', {}, false, false, form)
        }
        e.stopPropagation()
      })
    })
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.choose-sector').forEach(element => {
  element.instance = element.instance || new ChooseSectorComponent(element)
}))
hydratorRegisterComponent('.choose-sector', ChooseSectorComponent)
