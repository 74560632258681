
import Component from '../../../assets/scripts/modules/component'

const STATUS_TIMEOUT = 2000

export default class InviteMemberComponent extends Component {
  init () {
    this.buttonCopy = this.element.querySelector('.button--copy-link')
    this.linkUrl = this.element.getAttribute('data-link-url') || ''
    this.copyTitle = this.element.getAttribute('data-copy-title') || ''
    this.copySuccessTitle = this.element.getAttribute('data-copy-success-title') || ''
    this.copyFailedTitle = this.element.getAttribute('data-copy-failed-title') || ''
    this.titleTimeout = null

    this.buttonCopy?.addEventListener('click', event => this.onCopyButtonClick(event))
  }

  onCopyButtonClick (event) {
    event?.preventDefault()

    if (!navigator || !navigator.clipboard || !navigator.clipboard.writeText) {
      return
    }

    navigator.clipboard.writeText(this.linkUrl).then(() => this.onCopySuccess(), () => this.onCopyFailed())
  }

  onCopySuccess () {
    if (!this.copySuccessTitle) {
      return
    }

    // TODO: check innertext op chronme en firefox
    this.buttonCopy.innerText = this.copySuccessTitle
    this.buttonCopy.disabled = true

    window.clearTimeout(this.titleTimeout)
    this.titleTimeout = window.setTimeout(() => this.onCopyReset(), STATUS_TIMEOUT)
  }

  onCopyFailed () {
    if (!this.copyFailedTitle) {
      return
    }

    // TODO: check innertext op chronme en firefox
    this.buttonCopy.innerText = this.copyFailedTitle
    this.buttonCopy.disabled = true

    window.clearTimeout(this.titleTimeout)
    this.titleTimeout = window.setTimeout(() => this.onCopyReset(), STATUS_TIMEOUT)
  }

  onCopyReset () {
    if (!this.copyTitle) {
      return
    }

    this.buttonCopy.innerText = this.copyTitle
    this.buttonCopy.disabled = false
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.invite-member').forEach(element => {
  element.instance = element.instance || new InviteMemberComponent(element)
}))
