/**
 * Create a new user label
 */
function UserLabel (template, content) {
  const fragment = template.content.cloneNode(true)
  const element = fragment.firstElementChild
  const name = fragment.querySelector('[data-role=name]')

  if (name) {
    name.innerText = content
  }

  return {
    element
  }
}

export { UserLabel }
