import Component from '../../../assets/scripts/modules/component'

export default class ConversionBannerComponent extends Component {
  init () {
    const approval = this.element.querySelector('[name="formcheck"]')
    const formSubmit = this.element.querySelector('.form')
    const formSubmitButton = this.element.querySelector('.form__submit')
    const modalAproval = this.element.querySelector('.modal')

    modalAproval && modalAproval.addEventListener('click', (event) => {
      if (modalAproval.classList.contains('is-open')) {
        modalAproval.classList.remove('is-open')
        modalAproval.ariaHidden = true
      }
    })

    formSubmit && formSubmit.addEventListener('submit', (event) => {
      if (!approval.checked) {
        modalAproval.ariaHidden = false
        modalAproval.classList.add('is-open')
        event.preventDefault()
      }
      formSubmit.disabled = false
    })

    approval && approval.addEventListener('click', (event) => {
      formSubmitButton.disabled = false
    })

    const button = this.element.querySelector('.conversion-banner__button .button')
    if (button) {
      button.addEventListener('click', () => {
        const productDetail = window.dataLayer.filter((data) => data.event === 'productDetail')[0]
        window.dataLayer.push({
          event: 'addToCart',
          ecommerce: {
            currencyCode: 'EUR',
            add: {
              products: [
                {
                  ...productDetail.ecommerce.detail.products[0],
                  quantity: 1
                }
              ]
            }
          }
        })
      })
    }
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.conversion-banner ').forEach((element) => {
    element.instance = element.instance || new ConversionBannerComponent(element)
  })
)
