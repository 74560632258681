import { apiFormCall, POST } from '../../../assets/scripts/interfaces/api-call'
import Component from '../../../assets/scripts/modules/component'
import { closeModal } from '../../../assets/scripts/utilities/close-modal-js-modal'

export default class ArticleDownloadsComponent extends Component {
  init () {
    const formElement = this.element.querySelector('form')

    if (formElement) {
      formElement.addEventListener('submit', (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        if (!formData.get('email')) return

        const modalElement = document.getElementById('modal-cao-download')
        closeModal(modalElement)
        const endpoint = '/api/cao/downloads/'
        apiFormCall(POST, endpoint, formData, window.CNV_APP.csrfToken, false)
        window.open(e.target[3].value, 'CNV-Download')
        e.target.reset()
      })
    }
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.article-downloads').forEach((element) => {
    element.instance = element.instance || new ArticleDownloadsComponent(element)
  })
)
