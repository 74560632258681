import { apiFormCall, POST } from '../../../assets/scripts/interfaces/api-call'
import Component from '../../../assets/scripts/modules/component'
import { closeModal } from '../../../assets/scripts/utilities/close-modal-js-modal'

function sendDatalayer (caoCategory, email) {
  const data = {
    event: 'cao_download',
    cao_category: caoCategory,
    email: !!email
  }

  if (email) {
    data.customer_info = {
      email
    }
  }

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(data)
}

export default class CaoHeaderComponent extends Component {
  init () {
    const formElement = this.element.querySelector('form')
    const directDownloadElement = document.getElementById('cao-header-download-direct')
    const downloadWithEmailElement = document.getElementById('cao-header-download-email')
    const caoCategory = this.element.getAttribute('data-cao-category') || 'unknown'

    if (directDownloadElement) {
      directDownloadElement.addEventListener('click', () => {
        sendDatalayer(caoCategory, false)
      })
    }

    if (downloadWithEmailElement) {
      downloadWithEmailElement.addEventListener('click', () => {
        const modal = document.getElementById('modal-cao-download')
        if (modal) {
          const form = modal.querySelector('form')
          const emailField = modal.querySelector('input[name="email"]')
          const email = emailField.value
          const isValid = form.checkValidity()

          sendDatalayer(caoCategory, isValid ? email : false)
        }
      })
    }

    if (formElement) {
      formElement.addEventListener('submit', (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        if (!formData.get('email')) return

        const modalElement = document.getElementById('modal-cao-download')
        closeModal(modalElement)
        const endpoint = '/api/cao/downloads/'
        apiFormCall(POST, endpoint, formData, window.CNV_APP.csrfToken, false)
        window.open(e.target[3].value, 'CNV-Download')
        e.target.reset()
      })
    }
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.cao-header').forEach((element) => {
    element.instance = element.instance || new CaoHeaderComponent(element)
  })
)
