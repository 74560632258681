import Component from '../../../assets/scripts/modules/component'

export default class GroupDetailComponent extends Component {
  init () {
    const titleElements = Array.from(this.element.querySelectorAll('[data-role=group-detail-title]'))
    const descriptionElements = Array.from(this.element.querySelectorAll('[data-role=group-detail-description]'))

    window.addEventListener('modal-form-completed', (event) => {
      const { modal, data } = event.detail

      if (modal.id === 'modal-group-settings') {
        titleElements.forEach(element => {
          element.innerText = data.name
        })

        descriptionElements.forEach(element => {
          element.innerText = data.description
        })
      }
    })
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.group-detail').forEach(element => {
  element.instance = element.instance || new GroupDetailComponent(element)
}))
