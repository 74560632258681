/**
 * Helper construction to execute a function only once and only when it is needed
 *
 * @param f
 * @returns {{reset: reset, evaluate: ((function(): Promise<null|*>)|*)}}
 * @constructor
 */
function LazyEvaluator (f) {
  /**
   * Function that will return a promise to the evaluated function call.
   * @returns {Promise<*>}
   */
  async function evaluate () {
    if (isEvaluated) {
      return Promise.resolve(value)
    }

    if (evaluationPromise) {
      return evaluationPromise
    }

    evaluationPromise = f()
    value = await evaluationPromise
    isEvaluated = true
    return value
  }

  /**
   * Mark this evaluator as not evaluated so its value is recalculated.
   */
  function reset () {
    isEvaluated = false
  }

  let evaluationPromise = null
  let isEvaluated = false
  let value = null

  return {
    evaluate,
    reset
  }
}

export { LazyEvaluator }
