import Component from '../../../assets/scripts/modules/component'

class DropdownComponent extends Component {
  init () {
    const select = this.element.querySelector('select')
    const clustersContainer = this.element.querySelector('.dropdown-field__clusters')

    select.addEventListener('change', () => {
      clustersContainer.setAttribute('hidden', '')
      clustersContainer.querySelectorAll(`[data-cluster]`).forEach(item => item.setAttribute('hidden', ''))
      if (select.value) {
        clustersContainer.removeAttribute('hidden', '')
        clustersContainer.querySelector(`[data-cluster="${select.value}"]`).removeAttribute('hidden', '')
      }
    })

    this.element.querySelectorAll('.dropdown-field__clusters-item').forEach(item => {
      const btn = item.querySelector('.dropdown-field__clusters-btn-more')
      if (btn) {
        const isCollapsed = item.classList.contains('is-collapsed')
        btn.addEventListener('click', () => {
          btn.setAttribute('aria-expanded', isCollapsed)
          item.classList.toggle('is-collapsed')
        })
      }
    })
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.dropdown-field--cluster').forEach(element => {
  element.instanceCluster = element.instanceCluster || new DropdownComponent(element)
}))
