import Component from '../../../assets/scripts/modules/component'

class CopyLinkComponent extends Component {
  init () {
    this.textfield = this.element.querySelector('.copy-link__text-field')
    this.feedback = this.element.querySelector('.text-field__helptext')
    this.input = this.textfield.querySelector('.text-field__input')

    this.url = this.element.getAttribute('data-url') || window.location.href
    this.input.value = this.url
    this.input.setAttribute('value', this.url)

    this.element.addEventListener('click', () => {
      this.input.removeAttribute('disabled')
      this.input.select()
      this.input.setSelectionRange(0, 99999)
      document.execCommand('copy')
      this.input.setAttribute('disabled', true)

      this.feedback.style.display = 'block'
      setTimeout(() => {
        this.feedback.style.display = 'none'
      }, 5000)
    })
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.copy-link').forEach(element => {
  element.instance = element.instance || new CopyLinkComponent(element)
}))
