import Component from '../../../assets/scripts/modules/component'

export default class CaoOverviewComponent extends Component {
  init () {
    const blocks = this.element.querySelectorAll('.cao-overview__block')
    blocks.forEach(block => {
      const toggleMoreBtn = block.querySelector('.cao-overview__toggle-more-btn')
      if (toggleMoreBtn) {
        toggleMoreBtn.addEventListener('click', () => {
          const list = block.querySelector('.cao-overview__list')
          list.classList.toggle('is-collapsed')
        })
      }
    })
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.cao-overview').forEach(element => {
  element.instance = element.instance || new CaoOverviewComponent(element)
}))
