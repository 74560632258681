/**
 * Create a load more button
 */
import eventBus from '../interfaces/event-bus'

function LoadMoreButton (template) {
  function disable () {
    element.setAttribute('disabled', 'disabled')
  }

  function enable () {
    element.removeAttribute('disabled')
  }

  function fire () {
    disable()
    bus.send('click', {})
  }

  function subscribe (callback) {
    return bus.addListener('click', callback)
  }

  const fragment = template.content.cloneNode(true)
  const element = fragment.querySelector('[data-role=load-more-button]')
  const bus = eventBus()

  element.addEventListener('click', (event) => {
    event.preventDefault()
    fire()
  })

  return {
    disable,
    enable,
    subscribe,
    element
  }
}

export { LoadMoreButton }
