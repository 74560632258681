import TomSelect from 'tom-select'

import { apiCall, GET } from '../../../assets/scripts/interfaces/api-call'
import Component from '../../../assets/scripts/modules/component'
import findParentBySelector from '../../../assets/scripts/utilities/find-parent'
import fireCustomEvent from '../../../assets/scripts/utilities/fire-custom-event'
import { hydratorRegisterComponent } from '../../../assets/scripts/utilities/hydrator'

function Autocomplete (templateLibrary, placeholder) {
  const fragment = templateLibrary.select.content.cloneNode(true)
  const element = fragment.firstElementChild
  const selectContainer = fragment.querySelector('[data-role=select]')

  if (selectContainer) {
    selectContainer.setAttribute('placeholder', placeholder)
  }

  return {
    element
  }
}

class AutocompleteComponent extends Component {
  init () {
    const templateSelect = this.element.querySelector('.autocomplete__select')
    const templateItem = this.element.querySelector('.autocomplete__item')
    const templateOption = this.element.querySelector('.autocomplete__option')

    const baseUrl = this.element.getAttribute('data-base-url')
    const placeholder = this.element.getAttribute('data-placeholder')
    const autosubmit = this.element.getAttribute('data-autosubmit') !== 'false'
    const needToBeRedirected = this.element.getAttribute('data-redirect-to') === 'true'

    this.container = this.element.querySelector('[data-role=container]')

    this.library = {
      select: templateSelect,
      item: templateItem,
      option: templateOption
    }

    const autocomplete = Autocomplete(this.library, placeholder)

    this.container.innerHTML = ''
    this.container.appendChild(autocomplete.element)

    const createOptionHTML = this.createOptionHTML
    const createItemHTML = this.createItemHTML

    this.tomSelect = new TomSelect(autocomplete.element, {
      valueField: 'id',
      labelField: 'name',
      searchField: 'name',
      // options: [{ id: 'aap', name: 'beer' }],
      // load: function (query, callback) {
      //   var url = 'https://api.github.com/search/repositories?q=' + encodeURIComponent(query)
      //   fetch(url)
      //     .then(response => response.json())
      //     .then(json => {
      //       callback(json.items)
      //     }).catch(() => {
      //       callback()
      //     })
      // },
      onChange: function (value) {
        if (autosubmit) {
          const form = findParentBySelector(autocomplete.element, 'form')
          if (form) {
            // Using normal submit event causes weird behaviour
            fireCustomEvent('custom-submit', {}, false, false, form)
          }
        }
      },
      async load (query, callback) {
        let data

        if (!baseUrl) {
          // For testing purposes
          data = Array.from({ length: query.length }, () => ({
            id: `${query}${Math.random().toString(36).substring(2, 15)}`,
            name: `${query}${Math.random().toString(36).substring(2, 15)}`
          }))
        } else {
          const { items } = await apiCall(GET, `${baseUrl}?q=${query}`)
          data = items
        }
        callback(data)
      },
      onItemAdd: function (value, item) {
        if (needToBeRedirected) {
          window.location.href = value
        }
      },
      render: {
        item (item, escape) {
          return createItemHTML(templateItem, escape(item.name))
        },
        option (item, escape) {
          if (needToBeRedirected) return createOptionHTML(templateOption, item.name)
          return createOptionHTML(templateOption, escape(item.name))
        }
      }
    })
  }

  createItemHTML (template, text) {
    const fragment = template.content.cloneNode(true)
    const element = fragment.firstElementChild
    // const textContainer = fragment.querySelector('[data-role=text]')
    // textContainer.innerText = text
    return element.outerHTML
  }

  createOptionHTML (template, text) {
    const fragment = template.content.cloneNode(true)
    const element = fragment.firstElementChild
    const textContainer = fragment.querySelector('[data-role=text]')
    textContainer.innerText = text

    return element.outerHTML
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.autocomplete').forEach((element) => {
    element.instance = element.instance || new AutocompleteComponent(element)
  })
)
hydratorRegisterComponent('.autocomplete', AutocompleteComponent)
