import Component from '../../../assets/scripts/modules/component'
import { hydratorRegisterComponent } from '../../../assets/scripts/utilities/hydrator'

class OptionFieldComponent extends Component {
  init () {
    this.errorDisplay = document.querySelector('.option-field__error')
    const optionFields = Array.from(this.element.querySelectorAll('.option-field__input'))
    optionFields.forEach((field, idx) => {
      const errorId = `o${idx}`
      const errorMessage = field.getAttribute('data-invalid_message')
      if (!errorMessage) {
        return
      }
      field.addEventListener('valid', () => {
        this.removeError(errorId)
      })
      field.addEventListener('invalid', () => {
        this.addError(errorId, errorMessage)
      })
    })

    this.errors = []
  }

  addError (id, message) {
    this.errors.push([id, message])
    this.updateErrors()
  }

  removeError (id) {
    while (true) {
      const i = this.errors.findIndex(([i, _]) => i === id)
      if (i) {
        this.errors.splice(i, 1)
      } else {
        break
      }
    }
    this.updateErrors()
  }

  updateErrors () {
    if (!this.errorDisplay) {
      console.log('no error display')
      return
    }
    if (!this.errors.length) {
      this.element.classList.remove('option-field--error')
      this.errorDisplay.style.display = 'none'
      return
    }

    this.element.classList.add('option-field--error')
    this.errorDisplay.style.display = 'block'
    console.log(this.errors)
    this.errorDisplay.querySelector('span').innerText = this.errors.map(([_, m]) => m).join('; ')
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.option-field').forEach(element => {
  element.instance = element.instance || new OptionFieldComponent(element)
}))
hydratorRegisterComponent('.text-field', OptionFieldComponent)
