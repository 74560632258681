// Escape html
export default function escapeHTML (unsafeText) {
  const div = document.createElement('div')
  div.innerText = unsafeText
  return div.innerHTML
}

export function unescapeHTML (safeText) {
  const div = document.createElement('div')
  div.innerHTML = safeText
  return div.innerText
}
