
import Component from '../../../assets/scripts/modules/component'
import { clickedOutsideMultiple } from '../../../assets/scripts/utilities/outside-click'
export default class MenuLinkComponent extends Component {
  init () {
    this.active = false
    this.pinned = false
    this.link = Array.from(this.element.children).find(el => el.classList.contains('menu-link__link'))
    this.panel = Array.from(this.element.children).find(el => el.classList.contains('menu-panel'))

    if (this.panel && this.link) {
      this.backBtn = this.panel.querySelector('.menu-link--return')
      this.link.addEventListener('click', () => {
        this.togglePanel(!this.active, false, true)
      })

      this.backBtn.addEventListener('click', () => {
        this.togglePanel(false)
      })

      document.addEventListener('click', (e) => {
        if (!this.active) return
        clickedOutsideMultiple(e, [this.link, this.panel]) && this.togglePanel(false)
      })
    }
  }

  togglePanel (newState = false, openFirstChild = false) {
    const panelGroup = this.panel.getAttribute('data-panel-group')

    if (!newState) {
      this.element.classList.remove('menu-link--active')
      this.panel.classList.add('menu-panel--inactive')
    } else {
      this.element.classList.add('menu-link--active')
      Array.from(document.querySelectorAll(`.menu-panel[data-panel-group="${panelGroup}"]`)).forEach(el => {
        el.classList.add('menu-panel--inactive')
      })

      this.panel.classList.remove('menu-panel--inactive')

      if (openFirstChild) {
        const firstChild = this.panel.querySelector('.menu-panel')
        if (firstChild) {
          firstChild.classList.remove('menu-panel--inactive')
        }
      }
    }

    this.active = newState
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.menu-link').forEach(element => {
  element.instance = element.instance || new MenuLinkComponent(element)
}))
