/**
 * Create a new accept link
 */
import eventBus from '../../../../assets/scripts/interfaces/event-bus'

function ActionLink (template, data) {
  function disable () {
    enabled = false
  }

  function enable () {
    enabled = true
  }

  function fire () {
    if (enabled) {
      bus.send('click', {})
    }
  }

  function subscribe (callback) {
    return bus.addListener('click', callback)
  }

  const fragment = template.content.cloneNode(true)
  const element = fragment.firstElementChild
  const labelContainer = element.querySelector('[data-role=label]')

  const bus = eventBus()
  const { label } = data

  let enabled = true

  if (labelContainer && label) {
    labelContainer.innerText = label
  }

  element.addEventListener('click', (event) => {
    event.preventDefault()
    fire()
  })

  return {
    enable,
    disable,
    subscribe,
    element
  }
}

export { ActionLink }
