import { DropdownMenu } from '../../../assets/scripts/components/dropdown-menu'
import Component from '../../../assets/scripts/modules/component'
import { clickedOutsideSingle } from '../../../assets/scripts/utilities/outside-click'

export default class LanguageSwitchComponent extends Component {
  init () {
    this.html = document.querySelector('html')
    this.containerContent = document.querySelector('.container--content')
    this.languageSwitchMenu = this.element.querySelector('.language-switch__menu')
    this.menuBar = document.querySelector('.menu-bar .menu-bar__grid')
    this.selectedLanguage = this.element.querySelector('.language-switch__selected')
    this.googleTranslateElement = this.element.querySelector('#google_translate_element')
    this.googleTranslateCloseButton = document.querySelector('a[title="Sluiten"]')
    this.languagesPanel = DropdownMenu(this.languageSwitchMenu)

    if (!this.languageSwitchMenu || !this.languagesPanel) {
      return
    }

    this.initListeners()
  }

  initListeners () {
    this.element.addEventListener('click', (event) => {
      event.preventDefault()
      const isOpen = this.languagesPanel.isOpen()
      this.element.classList.toggle('active', !isOpen)
      isOpen ? this.languagesPanel.close() : this.languagesPanel.open()
    })

    document.addEventListener('click', (event) => {
      clickedOutsideSingle(event, this.element) && this.languagesPanel.close()
    })

    // Checks if the browser remembered a translation from previous session and adjusts the menu
    // Unfortunately there is no way of exactly telling (afaik) when google finished it's translation so setting this after 500ms which seems to work for all browsers
    document.addEventListener('gt-init', () => {
      setTimeout(() => {
        const lang = this.html.lang
        if (lang !== 'nl') {
          this.selectedLanguage.innerHTML = lang

          for (const menuItem of this.languageSwitchMenu.children) {
            menuItem.classList.toggle('hidden', menuItem.dataset.lang === lang)
          }
        }
      }, 500)
    })

    const clickHandler = event => {
      event.preventDefault()
      const el = event.target.closest('.language-switch__menu-item')
      this.changeLang(el.dataset.lang)
    }

    for (const lang of Array.from(this.languageSwitchMenu.querySelectorAll('.language-switch__menu-item'))) {
      lang.addEventListener('click', clickHandler)
    }
  }

  changeLang (lang) {
    const select = this.googleTranslateElement.querySelector('.goog-te-combo')
    this.html.setAttribute('lang', lang)

    select.value = lang
    select.dispatchEvent(new Event('change'))

    // this.containerContent.style.marginTop = '-40px'
    this.selectedLanguage.innerHTML = lang

    for (const menuItem of this.languageSwitchMenu.children) {
      menuItem.classList.toggle('hidden', menuItem.dataset.lang === lang)
    }

    // Fix for weird bug where selecting dutch once would not trigger a fully working translation
    if (lang === 'nl') {
      setTimeout(() => {
        select.value = lang
        select.dispatchEvent(new Event('change'))
      }, 25)
    }
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.language-switch').forEach((element) => {
    element.instance = element.instance || new LanguageSwitchComponent(element)
  })
)
