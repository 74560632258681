import { CustomToggleButton } from '../../../assets/scripts/components/custom-toggle-button'
import { ItemList } from '../../../assets/scripts/components/item-list'
import { apiCall, GET, POST, RequestStatusError } from '../../../assets/scripts/interfaces/api-call'
import Component from '../../../assets/scripts/modules/component'

/**
 * Controller for a group setting.
 *
 * @param template
 * @param data
 * @returns {{element: Element}}
 * @constructor
 */
function GroupSetting (template, data) {
  const fragment = template.content.cloneNode(true)
  const element = fragment.firstElementChild
  const labelElement = fragment.querySelector('[data-role=label]')
  const toggleElement = fragment.querySelector('.toggle')

  const { id, name, enabled } = data

  if (labelElement) {
    labelElement.innerText = name
  }

  if (toggleElement) {
    toggleElement.querySelector('input').checked = enabled

    async function change (newState) {
      const endpoint = `/rest-api/v1/community/settings/groups/`

      try {
        await apiCall(POST, endpoint, { id, enabled: newState }, window.CNV_APP?.csrfToken, false)
      } catch (err) {
        if (err instanceof RequestStatusError) {
          alert('Updaten mislukt. Mogelijk helpt het refreshen van de pagina')
          return
        }
      }

      return newState
    }

    async function setOn () {
      return change(true)
    }

    async function setOff () {
      return change(false)
    }

    CustomToggleButton(toggleElement, null, setOn, setOff)
  }

  return {
    element
  }
}

/**
 * Controller for groups settings object.
 *
 * @param element
 */
function GroupsSettingsController (element) {
  /**
   * Fetch settings for groups
   *
   * @param offset
   * @param limit
   * @returns {Promise<{total: *, items}>}
   */
  async function fetchGroupSettings (offset, limit) {
    const endpoint = `/rest-api/v1/community/settings/groups/?limit=${limit}&offset=${offset}`
    const results = await apiCall(GET, endpoint)
    const mapper = data => GroupSetting(templateSetting, data)
    return {
      total: results.count,
      items: results.results.map(mapper)
    }
  }

  const itemContainer = element.querySelector('[data-role=settings]')
  const templateSetting = element.querySelector('template[data-role=setting]')
  const templateLoadMoreButton = element.querySelector('template[data-role=load-more-button]')

  ItemList(itemContainer, templateLoadMoreButton, fetchGroupSettings, null, 10)

  return {
    element
  }
}

export default class SettingsGroupComponent extends Component {
  init () {
    const type = this.element.getAttribute('data-type')

    if (type === 'group-settings') {
      // Group settings controller
      GroupsSettingsController(this.element)
    }
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.settings-group').forEach(element => {
  element.instance = element.instance || new SettingsGroupComponent(element)
}))
