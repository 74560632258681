import Component from '../../../assets/scripts/modules/component'

class FormMembershipComponent extends Component {
  init () {
    if (this.element.dataset.currentStep === '2') {
      this.age = parseInt(this.element.querySelector('input[name="mywork-age"]').value, 10)

      this.fieldWorkStatusSelect = document.getElementById('id_mywork-work_status')
      this.fieldWorkStatus = this.fieldWorkStatusSelect.closest('.dropdown-field')

      this.fieldClusterSelect = document.getElementById('id_mywork-cluster')
      this.fieldCluster = this.fieldClusterSelect.closest('.dropdown-field')

      this.displayClusterField()
      this.fieldWorkStatusSelect.addEventListener('change', this.displayClusterField.bind(this))

      this.setClusterFieldLabel()
      this.fieldWorkStatusSelect.addEventListener('change', this.setClusterFieldLabel.bind(this))

      this.displaySalaryField()
      this.fieldWorkStatusSelect.addEventListener('change', this.displaySalaryField.bind(this))
      this.fieldClusterSelect.addEventListener('change', this.displaySalaryField.bind(this))

      this.displayPositionField()
      this.fieldWorkStatusSelect.addEventListener('change', this.displayPositionField.bind(this))
      this.fieldClusterSelect.addEventListener('change', this.displayPositionField.bind(this))

      this.displayGraduationField()
      this.fieldWorkStatusSelect.addEventListener('change', this.displayGraduationField.bind(this))
      this.fieldClusterSelect.addEventListener('change', this.displayGraduationField.bind(this))

      this.displayTryTheUnion()
      this.fieldWorkStatusSelect.addEventListener('change', this.displayTryTheUnion.bind(this))
      this.fieldClusterSelect.addEventListener('change', this.displayTryTheUnion.bind(this))
    }

    // if (this.element.dataset.currentStep === '3') {
    //   this.displayIBAN()
    //   this.fieldIban = document.getElementById('id_finalization-iban_number').closest('.text-field')
    //   this.fieldPaymentMethods = document.querySelectorAll('input[name="finalization-payment_choice"]')
    //   this.fieldPaymentMethods.forEach((radio) => { radio.addEventListener('change', this.displayIBAN) })
    // }
  }

  ageIsInRange (range) {
    const [, minAge, maxAge] = range.match(/(\d+)-(\d+)/)
    return this.age >= parseInt(minAge, 10) && this.age <= parseInt(maxAge, 10)
  }

  displayClusterField () {
    this.fieldCluster.setAttribute('hidden', '')
    if (this.fieldWorkStatusSelect.value) {
      this.fieldCluster.removeAttribute('hidden', '')
    }
  }

  setClusterFieldLabel () {
    if (this.fieldWorkStatusSelect.value) {
      const labelConditions = Object.keys(this.fieldCluster.dataset).filter(attr => attr.startsWith('labelCondition'))

      labelConditions.forEach((item, index) => {
        const fieldConditions = this.fieldCluster.dataset[item].split(',')
        if (fieldConditions.includes(this.fieldWorkStatusSelect.value)) {
          this.fieldCluster.querySelector('label').innerText = this.fieldCluster.dataset[`label-${index}`]
        }
      })
    }
  }

  displaySalaryField () {
    const salaryFields = [
      document.getElementById('id_mywork-connectief_salary'),
      document.getElementById('id_mywork-connectief_salary_sub20'),
      document.getElementById('id_mywork-vakmensen_salary')
    ].map(el => el.closest('.dropdown-field'))

    salaryFields.forEach(field => field.setAttribute('hidden', ''))

    if (this.age && this.fieldWorkStatusSelect.value && this.fieldClusterSelect.value) {
      salaryFields.forEach(field => {
        const cluster = field.dataset.conditionCluster.split(',')
        if (cluster.includes(this.fieldClusterSelect.value)) {
          const conditionAge = field.dataset.conditionAge
          const conditionWorkStatus = field.dataset.conditionWork_status
          if (conditionAge) {
            if (this.ageIsInRange(conditionAge)) {
              field.removeAttribute('hidden', '')
            }
          } else if (conditionWorkStatus) {
            const workStatus = conditionWorkStatus.split(',')
            if (workStatus.includes(this.fieldWorkStatusSelect.value)) {
              field.removeAttribute('hidden', '')
            }
          }
        }
      })
    }
  }

  displayPositionField () {
    const positionFields = [
      document.getElementById('id_mywork-connectief_education_scale'),
      document.getElementById('id_mywork-connectief_education_scale_sub26')
    ].map(el => el.closest('.dropdown-field'))

    positionFields.forEach(field => field.setAttribute('hidden', ''))

    if (this.fieldWorkStatusSelect.value && this.fieldClusterSelect.value) {
      positionFields.forEach(field => {
        const conditionWorkStatus = field.dataset.conditionWork_status.split(',')
        const conditionCluster = field.dataset.conditionCluster.split(',')
        const conditionAge = field.dataset.conditionAge
        if (conditionWorkStatus.includes(this.fieldWorkStatusSelect.value) && conditionCluster.includes(this.fieldClusterSelect.value)) {
          if (this.ageIsInRange(conditionAge)) {
            field.removeAttribute('hidden', '')
          }
        }
      })
    }
  }

  displayGraduationField () {
    const field = document.getElementById('id_mywork-connectief_graduation_date').closest('.text-field')
    field.setAttribute('hidden', '')

    if (this.fieldWorkStatusSelect.value && this.fieldClusterSelect.value) {
      const conditionWorkStatus = field.dataset.conditionWork_status.split(',')
      const conditionCluster = field.dataset.conditionCluster.split(',')
      if (conditionWorkStatus.includes(this.fieldWorkStatusSelect.value) && conditionCluster.includes(this.fieldClusterSelect.value)) {
        field.removeAttribute('hidden', '')
      }
    }
  }

  displayTryTheUnion () {
    const tryTheUnionSection = this.element.querySelector('.form-membership__try-the-union')
    const formSubmitBtn = this.element.querySelector('.form-membership__form-btn-submit > button')
    tryTheUnionSection.setAttribute('hidden', '')
    formSubmitBtn.removeAttribute('hidden', '')

    if (this.fieldWorkStatusSelect.value && this.fieldClusterSelect.value) {
      const isStudent = this.fieldWorkStatusSelect.value === 'ik-studeer'
      const isClusterVakmensen = this.element.dataset.clustersVakmensen.split(',').includes(this.fieldClusterSelect.value)
      if (this.age <= 22 && isStudent && isClusterVakmensen) {
        tryTheUnionSection.removeAttribute('hidden', '')
        formSubmitBtn.setAttribute('hidden', '')
      }
    }
  }

  // displayIBAN () {
  //   this.fieldPaymentMethodIncasso = document.getElementById('id_finalization-payment_choice_incasso')
  //   this.fieldPaymentMethodAcceptgiro = document.getElementById('id_finalization-payment_choice_acceptgiro')
  //   this.fieldIban = document.getElementById('id_finalization-iban_number').closest('.text-field')
  //
  //   if (this.fieldPaymentMethodIncasso.checked) {
  //     this.fieldIban.removeAttribute('hidden', '')
  //   } else {
  //     this.fieldIban.setAttribute('hidden', '')
  //   }
  // }
}

window.addEventListener('init-load', () => document.querySelectorAll('.form-membership').forEach(element => {
  element.instance = element.instance || new FormMembershipComponent(element)
}))
