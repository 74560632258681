import { apiCall, POST } from '../../../assets/scripts/interfaces/api-call'
import Component from '../../../assets/scripts/modules/component'

export default class MembershipCalculationComponent extends Component {
  init () {
    this.apiUrl = this.element.getAttribute('data-api-url')
    console.log(this.apiUrl)
    this.initPriceCalculationListener()
    this.initGiftSelectionListener()
    this.setPriceCalculation()
  }

  initPriceCalculationListener () {
    if (!this.apiUrl) {
      return
    }

    const form = this.element.closest('form')
    form.addEventListener('change', () => this.setPriceCalculation())
  }

  async setPriceCalculation () {
    if (!this.apiUrl) {
      return
    }

    this.element.setAttribute('hidden', '')
    const json = await this.fetchMembershipPriceCalculation()
    if (!json.error) {
      this.element.querySelector('.membership-calculation__price-value').innerText = json.fee
      this.element.querySelector('.membership-calculation__info-regular').innerHTML = json.tip
      this.element.removeAttribute('hidden', '')
    }
  }

  async fetchMembershipPriceCalculation () {
    if (!this.apiUrl) {
      return {
        ok: false
      }
    }

    const formEntries = [...new FormData(this.element.closest('form')).entries()].map(([k, v]) => {
      // remove the prefix
      if (k.indexOf('-') >= 0) {
        return [k.split('-')[1], v]
      }
      return [k, v]
    })
    const formData = new FormData()

    for (const [k, v] of formEntries) {
      formData.set(k, v)
    }

    return apiCall(POST, this.apiUrl, formData, window.CNV_APP.csrfToken, true, false)
  }

  initGiftSelectionListener () {
    document.querySelectorAll('.image-radio-field__input').forEach(gift => {
      gift.addEventListener('change', () => {
        // let discountLabel = ''
        this.element.querySelectorAll('.membership-calculation__gift-item').forEach(item => item.setAttribute('hidden', ''))
        if (gift.checked) {
          const item = this.element.querySelector(`.membership-calculation__gift-item[data-gift-value="${gift.value}"]`)
          if (item) {
            item.removeAttribute('hidden', '')
            this.element.querySelector('.membership-calculation__gifts').removeAttribute('hidden', '')
            // discountLabel = item.getAttribute('data-gift-discount')
          }
        }
        // const discountElement = this.element.querySelector('.membership-calculation__discount-tag')
        // if (discountElement) {
        //   discountElement.innerText = discountLabel
        //   discountElement[discountLabel ? 'removeAttribute' : 'setAttribute']('hidden', '')
        // }
      })
    })
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.membership-calculation').forEach(element => {
  element.instance = element.instance || new MembershipCalculationComponent(element)
}))
