// Check outside click for multiple elements
export function clickedOutsideMultiple (event, notelem = []) {
  let clickedOut = true
  for (const el of notelem) {
    if (event.target === el || el.contains(event.target)) {
      clickedOut = false
    }
  }
  return clickedOut
}

// Check outside click for single element
export function clickedOutsideSingle (event, el) {
  return !(event.target === el || el.contains(event.target))
}

export default {
  clickedOutsideSingle,
  clickedOutsideMultiple
}
