
import { CustomToggleButton } from '../../../../assets/scripts/components/custom-toggle-button'
import { DropdownMenu } from '../../../../assets/scripts/components/dropdown-menu'
import { DropdownMenuItem } from '../../../../assets/scripts/components/dropdown-menu-item'
import asyncFormSubmitter from '../../../../assets/scripts/interfaces/async-form-submitter'
import eventBus from '../../../../assets/scripts/interfaces/event-bus'
import { unescapeHTML } from '../../../../assets/scripts/utilities/escape-html'
import { hydrate } from '../../../../assets/scripts/utilities/hydrator'

function buildWorkCard (templateLibrary, actions, cardData) {
  function subscribe (name, callback) {
    bus.addListener(name, callback)
  }

  async function updatePrivacy (newState) {
    if (!cardData.uuid) {
      // testing
      return true
    }

    // regular
    const { submit } = asyncFormSubmitter(cardData.privacy_toggle.endpoint, window.CNV_APP.csrfToken)
    const formData = new FormData()
    formData.append('is_private', newState)
    const response = await submit(formData)
    return response.extra.is_private === newState
  }

  const bus = eventBus()

  const template = cardData.detail_link?.href ? templateLibrary.cardWithLink : templateLibrary.card
  const fragment = template.content.cloneNode(true)
  const element = fragment.firstElementChild
  const link = fragment.querySelector('[data-role=link]')
  const title = fragment.querySelector('[data-role=title]')
  const subtitle = fragment.querySelector('[data-role=subtitle]')
  const footer = fragment.querySelector('[data-role=footer]')

  if (link && cardData.detail_link.href) {
    link.href = cardData.detail_link.href
  }

  if (cardData.variant) {
    element.classList.add(`profile-work-card--${cardData.variant}`)
  }

  if (title) {
    title.innerText = unescapeHTML(cardData.title)
  }

  if (subtitle) {
    subtitle.innerText = cardData.subtitle
  }

  if (!cardData.public) {
    const dropdownMenu = DropdownMenu(templateLibrary.dropdownMenu)
    const menuItems = []

    if (actions.add) {
      const addNew = DropdownMenuItem(templateLibrary.dropdownMenuItem, 'Nieuwe toevoegen', templateLibrary.iconAdd)
      addNew.subscribe(actions.add)
      menuItems.push(addNew)
    }

    if (actions.remove) {
      const remove = DropdownMenuItem(templateLibrary.dropdownMenuItem, 'Verwijderen', templateLibrary.iconRemove)
      remove.subscribe(async () => {
        const success = await actions.remove(cardData.uuid)

        if (success) {
          bus.send('removed', { element })
        }
      })
      menuItems.push(remove)
    }

    if (footer) {
      if (cardData.privacy_toggle) {
        const privacyToggle = CustomToggleButton(
          templateLibrary.privacyToggle,
          cardData.privacy_toggle.is_toggled,
          async () => updatePrivacy(true),
          async () => updatePrivacy(false)
        )
        footer.appendChild(privacyToggle.element)
      }

      if (menuItems.length) {
        for (const item of menuItems) {
          dropdownMenu.addItem(item)
        }

        footer.appendChild(dropdownMenu.element)
      }
    }
  }

  hydrate(element)

  return {
    subscribe,
    element
  }
}

export { buildWorkCard }
