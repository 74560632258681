import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import Component from '../../../assets/scripts/modules/component'

export default class DossierTextComponent extends Component {
  init () {
    gsap.registerPlugin(ScrollTrigger)
    this.secondTextBlock = this.element.querySelectorAll('.dossier-text__text-block')[1]
    this.ScrollTriggerId = `id_${Math.random().toString(36).substring(7)}`

    const breakpoint = getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-notebook')
    const mq = window.matchMedia(`(min-width:${breakpoint})`)
    const mediaListener = (e) => {
      if (e.matches) {
        this.initScrollTrigger()
      } else {
        ScrollTrigger.getById(this.ScrollTriggerId)?.kill()
        gsap.set(this.secondTextBlock, { clearProps: true })
      }
    }
    mq.addEventListener('change', mediaListener)
    mediaListener(mq)
  }

  initScrollTrigger () {
    if (this.secondTextBlock) {
      gsap.fromTo(this.secondTextBlock,
        { y: '50%', opacity: 0 },
        {
          scrollTrigger: {
            id: this.ScrollTriggerId,
            trigger: this.element,
            start: '50% bottom',
            end: '100% bottom',
            scrub: true
          },
          y: '0%',
          opacity: 1
        }
      )
    }
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.dossier-text').forEach(element => {
  element.instance = element.instance || new DossierTextComponent(element)
}))
