/**
 * Create a new idea
 */
import { DropdownMenu } from '../../../../assets/scripts/components/dropdown-menu'
import { DropdownMenuItem } from '../../../../assets/scripts/components/dropdown-menu-item'
import { ItemList } from '../../../../assets/scripts/components/item-list'
import { UserLabel } from '../../../../assets/scripts/components/user-label'
import { formatDateToNow } from '../../../../assets/scripts/interfaces/date'
import eventBus from '../../../../assets/scripts/interfaces/event-bus'
import escapeHTML from '../../../../assets/scripts/utilities/escape-html'
import { hydrate } from '../../../../assets/scripts/utilities/hydrator'
import { buildLikeButton } from './comment-section-like-button'
import { buildReplyButton } from './comment-section-reply-button'

/**
 * Make text nicer
 *
 * @param text
 */
function applyTextMarkup (text) {
  // This needs not be done here per se, but for now we will do it here
  let safeText = escapeHTML(text)

  // decorate links
  const linkRegex = /((?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?)/ig
  safeText = safeText.replace(linkRegex, '<a class="link" href="$1" target="_blank">$1</a>')

  return safeText
}

function Poll (template, data) {
  const fragment = template.content.cloneNode(true)
  const element = fragment.firstElementChild

  const { uuid } = data

  element.setAttribute('data-uuid', uuid)

  return {
    element
  }
}

function buildComment (templateLibrary, api, commentData, canHaveReplies = false) {
  /**
   * Add a reply to this comment.
   *
   * @param reply
   */
  function addReply (reply) {
    if (repliesList) {
      repliesList.addItem(reply, true)
    }
  }

  /**
   * Subscribe to events sent by this comment
   *
   * @param name
   * @param callback
   * @returns {*}
   */
  function subscribe (name, callback) {
    return bus.addListener(name, callback)
  }

  function toggleReplyMode (newState = null) {
    const method = newState === true ? 'add' : (newState === false ? 'remove' : 'toggle')
    element.classList[method]('comment-section-comment--reply-mode')
  }

  const fragment = templateLibrary.comment.content.cloneNode(true)
  const element = fragment.querySelector('.comment-section-comment')
  const text = element.querySelector('[data-role=text]')
  const pollContainer = element.querySelector('[data-role=poll]')
  const username = element.querySelector('[data-role=username]')
  const userFunction = element.querySelector('[data-role=function]')
  const timestamp = element.querySelector('[data-role=timestamp]')
  const labels = element.querySelector('[data-role=labels]')
  const avatar = element.querySelector('[data-role=avatar]')
  const actions = element.querySelector('[data-role=actions]')
  const menu = element.querySelector('[data-role=menu]')
  const repliesContainer = element.querySelector('[data-role=replies]')
  const replyForm = element.querySelector('[data-role=reply-form]')

  const bus = eventBus()

  // We need a distinction whether this is a main post or a reply to a post
  const isPostNotReply = commentData.replyAllowed

  let repliesList

  if (username) {
    username.innerText = commentData.username

    if (window.CNV_APP.profileUrl) {
      username.href = window.CNV_APP.profileUrl.replace(/\bslug\b/, commentData.userSlug)
    }
  }

  if (avatar && commentData.avatar) {
    avatar.src = commentData.avatar
  }

  if (text) {
    text.innerHTML = applyTextMarkup(commentData.text)
  }

  if (pollContainer) {
    if (commentData.poll_uuid) {
      const poll = Poll(templateLibrary.poll, { uuid: commentData.poll_uuid })
      pollContainer.appendChild(poll.element)
      hydrate(pollContainer)
    } else {
      pollContainer.parentNode.removeChild(pollContainer)
    }
  }

  if (userFunction && commentData.function) {
    userFunction.innerText = commentData.function
  } else {
    userFunction.classList.add('empty')
  }

  if (timestamp && commentData.timestamp) {
    timestamp.innerText = formatDateToNow(new Date(commentData.timestamp))
  }

  if (repliesContainer && canHaveReplies) {
    const fetchSource = (offset, limit) => api.fetchReplies(commentData.uuid, offset, limit)
    const initialData = {
      total: commentData.totalReplies ? commentData.totalReplies : 0,
      items: commentData.replies ? commentData.replies.map(item => buildComment(templateLibrary, api, item, false)) : []
    }
    repliesList = ItemList(templateLibrary.list, templateLibrary.loadMoreButton, fetchSource, initialData)
    repliesContainer.appendChild(repliesList.element)
  }

  if (actions) {
    const likeButton = buildLikeButton(
      templateLibrary,
      {
        like: () => api.likeComment(isPostNotReply, commentData.uuid),
        unlike: () => api.unlikeComment(isPostNotReply, commentData.uuid)
      },
      { likedByUser: commentData.likedByUser, likeCount: commentData.likeCount, isOwned: commentData.isOwned }
    )
    actions.appendChild(likeButton.element)
  }

  if (actions && canHaveReplies) {
    const replyButton = buildReplyButton(templateLibrary, api)
    replyButton.subscribe(() => toggleReplyMode())
    actions.appendChild(replyButton.element)
  }

  const menuItems = []

  if (menu && commentData.isOwned) {
    const removeItem = DropdownMenuItem(templateLibrary.dropdownMenuItem, 'Reactie verwijderen', templateLibrary.iconRemove)
    removeItem.subscribe(async (event) => {
      const success = await api[isPostNotReply ? 'removePost' : 'removeReply'](commentData.uuid)

      if (success) {
        bus.send('removed', { element })
      }
    })
    menuItems.push(removeItem)
  }

  if (menu && !commentData.isOwned) {
    const reportItem = DropdownMenuItem(templateLibrary.dropdownMenuItem, 'Rapporteren', templateLibrary.iconReport)
    reportItem.subscribe(async (event) => {
      let success

      if (isPostNotReply) {
        success = await api.reportPost(commentData.uuid)
      } else {
        success = await api.reportReply(commentData.uuid, '')
      }

      if (success) {
        alert('Bedankt voor het rapporteren!')
      }
    })
    menuItems.push(reportItem)
  }

  if (menu && menuItems.length) {
    const dropdownMenu = DropdownMenu(templateLibrary.dropdownMenu)
    for (const item of menuItems) {
      dropdownMenu.addItem(item)
    }
    menu.appendChild(dropdownMenu.element)
  }

  if (replyForm) {
    // Fix duplicate ids
    const queue = []
    Array.from(replyForm.querySelectorAll('#make_me_unique')).forEach(el => {
      queue.push(`id_${el.name}_${commentData.uuid || Math.random()}`)
      el.id = queue[queue.length - 1]
    })
    Array.from(replyForm.querySelectorAll('[for="make_me_unique"]')).forEach(el => {
      el.setAttribute('for', queue.shift())
    })

    // Handle form
    replyForm.addEventListener('submit', async (event) => {
      event.preventDefault()
      toggleReplyMode(false)
      const reply = await api.postReply(commentData.uuid, new FormData(replyForm))
      addReply(reply)
      replyForm.querySelector('[name=text]').value = ''
    })
  }

  if (labels && commentData.labels) {
    const userLabels = commentData.labels.map(ul => UserLabel(templateLibrary.userLabel, ul))

    for (const userLabel of userLabels) {
      labels.appendChild(userLabel.element)
    }
  } else {
    labels.remove()
  }

  hydrate(fragment)

  return {
    addReply,
    subscribe,
    element
  }
}

export { buildComment }
