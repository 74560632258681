import Component from '../../../assets/scripts/modules/component'
import SwiperWrapper from '../../../assets/scripts/modules/swiper-wrapper'

export default class UspAsideComponent extends Component {
  init () {
    if (this.element.classList.contains('usp-aside--animating')) {
      this.container = this.element.querySelector('.swiper-container')
      this.swiperWrapper = new SwiperWrapper(this.container, {
        loop: true,
        speed: 2000,
        autoplay: {
          delay: 3000
        }
      })
    }
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.usp-aside').forEach(element => {
  element.instance = element.instance || new UspAsideComponent(element)
}))
