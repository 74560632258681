import {
  format,
  formatDistanceToNow
} from 'date-fns'
import { nl } from 'date-fns/locale'

function formatDate (date, showTime = true) {
  const formatting = showTime ? 'dd MMMM yyyy, hh.ii' : 'dd MMMM yyyy'
  return format(date, formatting, { locale: nl })
}

function formatDateToNow (date) {
  const normalizedDate = Math.min(new Date().getTime(), date.getTime())
  return formatDistanceToNow(normalizedDate, { locale: nl, addSuffix: true })
}

function formatTimeRemainingToNow (date) {
  const normalizedDate = Math.max(new Date().getTime(), date.getTime())
  const defaultDateString = formatDistanceToNow(normalizedDate, { locale: nl, addSuffix: true })
  return defaultDateString.replace(/\bover\b/, 'Nog')
}

export {
  formatDate,
  formatDateToNow,
  formatTimeRemainingToNow
}
