function humanizeNumber (number) {
  if (isNaN(number)) {
    return '?'
  }

  if (number < 1000) {
    return number.toString()
  }

  return `${humanizeNumber(Math.floor(number / 1000))}.${number % 1000}`
}

export { humanizeNumber }
