import { apiCall, GET, POST, redirectToLoginAndReturn, RequestStatusError } from '../../../assets/scripts/interfaces/api-call'
import { formatDate, formatTimeRemainingToNow } from '../../../assets/scripts/interfaces/date'
import eventBus from '../../../assets/scripts/interfaces/event-bus'
import Component from '../../../assets/scripts/modules/component'
import { hydratorRegisterComponent } from '../../../assets/scripts/utilities/hydrator'

/**
 * Format a date for the poll time remaining display.
 *
 * @param date
 * @returns {string}
 */
function formatPollTimeRemaining (date) {
  if (new Date() < date) {
    return `${formatTimeRemainingToNow(date)} open`
  }
  return `Einddatum: ${formatDate(date, false)}`
}

//  The initializePollModal code is to open the popup
function initializePollModal (pollElement) {
  MicroModal.init({
    openTrigger: 'data-modal-popup-open-' + pollElement.dataset.uuid,
    closeTrigger: 'data-modal-close',
    openClass: 'is-open',
    disableScroll: true,
    disableFocus: false,
    awaitOpenAnimation: false,
    awaitCloseAnimation: false,
    debugMode: true
  })
  const modal = pollElement.querySelector('.modal')
  const form = modal.querySelector('form')

  form.addEventListener('submit', function () {
    modal.querySelector('[data-modal-close]').click()
  }, false)
}

/**
 * Create a FormOption element for the Form element
 *
 * @param template
 * @param value
 * @param label
 * @returns {{element: Element}}
 * @constructor
 */
function FormOption (template, value, label) {
  const fragment = template.content.cloneNode(true)
  const element = fragment.firstElementChild
  const button = fragment.querySelector('[data-role=button]')
  const buttonLabel = fragment.querySelector('[data-role=button-label]')

  if (button) {
    button.value = value
  }

  if (buttonLabel) {
    buttonLabel.innerText = label
  }

  return {
    element
  }
}

/**
 * Create a Form element for the Poll element
 *
 * @param templateLibrary
 * @param formData
 * @returns {{subscribe: subscribe, element: Element}}
 * @constructor
 */
function Form (templateLibrary, formData) {
  function subscribe (callback) {
    bus.addListener('submit', callback)
  }

  const fragment = templateLibrary.form.content.cloneNode(true)
  const element = fragment.firstElementChild
  const timeRemainingContainer = fragment.querySelector('[data-role=time-remaining]')
  const optionsContainer = fragment.querySelector('[data-role=options]')
  const totalVotesContainer = fragment.querySelector('[data-role=total-votes]')

  const { endDate, options, totalVotes } = formData

  const bus = eventBus()

  let submitted = false

  if (timeRemainingContainer && endDate) {
    timeRemainingContainer.innerText = formatPollTimeRemaining(endDate)
  }

  for (const { value, label } of options) {
    const formOption = FormOption(templateLibrary.formOption, value, label)
    optionsContainer.appendChild(formOption.element)
  }

  if (totalVotes) {
    totalVotesContainer.innerText = `${totalVotes} ${totalVotes === 1 ? 'stem' : 'stemmen'}`
  }

  element.addEventListener('submit', (event) => {
    event.preventDefault()

    if (submitted) {
      return
    }

    submitted = true

    bus.send('submit', { value: (new FormData(element).get('value')) || event.submitter.value })
  })

  return {
    subscribe,
    element
  }
}

/**
 * Create a Result for the Results element
 *
 * @param template
 * @param resultData
 * @returns {{element: Element}}
 * @constructor
 */
function Result (template, resultData) {
  function formatPercent (percent) {
    return `${Math.floor(percent + 0.5)}%`
  }

  const fragment = template.content.cloneNode(true)
  const element = fragment.firstElementChild
  const label = fragment.querySelector('[data-role=label]')
  const result = fragment.querySelector('[data-role=result]')
  const bar = fragment.querySelector('[data-role=bar]')

  if (label) {
    label.innerText = resultData.label
  }

  if (result) {
    result.innerText = formatPercent(resultData.percent)
  }

  if (bar) {
    setTimeout(() => {
      bar.style.width = `${resultData.percent}%`
    })
  }

  if (resultData.selected) {
    element.classList.add('selected')
  }

  return {
    element
  }
}

function sessionRegisterVote (pollId) {
  const items = (localStorage.getItem('CNV_POLL_VOTED') || '').split(',')
  items.push(pollId)
  localStorage.setItem('CNV_POLL_VOTED', items.join(','))
}

function sessionDidVote (pollId) {
  return (localStorage.getItem('CNV_POLL_VOTED') || '').split(',').includes(pollId)
}

/**
 * Create a Results element
 *
 * @param templateLibrary
 * @param resultsData
 * @returns {{element: Element}}
 * @constructor
 */
function Results (templateLibrary, resultsData) {
  const fragment = templateLibrary.results.content.cloneNode(true)
  const element = fragment.firstElementChild
  const resultsContainer = fragment.querySelector('[data-role=results]')
  const totalVotesContainer = fragment.querySelector('[data-role=total-votes]')
  const timeRemainingContainer = fragment.querySelector('[data-role=time-remaining]')

  const { endDate, results, totalVotes } = resultsData

  if (timeRemainingContainer && endDate) {
    timeRemainingContainer.innerText = formatPollTimeRemaining(endDate)
  }

  for (const { label, percent, selected } of results) {
    const result = Result(templateLibrary.result, { label, percent, selected })
    resultsContainer.appendChild(result.element)
  }

  if (totalVotes) {
    totalVotesContainer.innerText = `${totalVotes} ${totalVotes === 1 ? 'stem' : 'stemmen'}`
  }

  return {
    element
  }
}

/**
 * Create a main poll element
 *
 * @param templateLibrary
 * @param api
 * @param pollData
 * @returns {{element: Element}}
 * @constructor
 */
function Poll (templateLibrary, api, pollData) {
  const fragment = templateLibrary.poll.content.cloneNode(true)
  const element = fragment.firstElementChild
  const questionContainer = fragment.querySelector('[data-role=question]')
  const body = fragment.querySelector('[data-role=body]')

  const { canVote, question, answers, totalVotes, votingEnd } = pollData

  if (questionContainer) {
    questionContainer.innerText = question
  }

  if (canVote) {
    const formData = {
      endDate: votingEnd,
      options: answers.map(({ text, uuid }) => ({ value: uuid, label: text })),
      totalVotes
    }
    const form = Form(templateLibrary, formData)
    form.subscribe(async (detail) => {
      await api.castVote(detail.value)
    })
    body.appendChild(form.element)
  } else {
    const resultsData = {
      endDate: votingEnd,
      results: answers.map(({ text, percent, selected }) => ({ label: text, percent, selected })),
      totalVotes
    }
    const results = Results(templateLibrary, resultsData)
    body.appendChild(results.element)
  }

  return {
    element
  }
}

/**
 * Fetch ideas from backend
 *
 * @param pollUuid
 * @returns {Promise<{total, ideas}|{total: *, ideas: *[]}>}
 */
async function fetchPoll (pollUuid) {
  // For testing purposes
  if (!pollUuid) {
    return {
      question: 'Wat vind je het aller belangrijkste de komende onderhandelingen?',
      answers: [
        { text: 'Een hoger salaris', percent: 34 },
        { text: 'Meer vakantiedagen', percent: 17 },
        { text: 'Inspraak in werktijden en aantal uur per week', percent: 49 }
      ],
      totalVotes: 74,
      canVote: true,
      votingEnd: new Date(new Date() * 1 + 86400000 * 3)
    }
  }

  // regular behaviour
  const endpoint = `/rest-api/v1/community/polls/${pollUuid}/`
  const data = await apiCall(GET, endpoint)
  return serializeTwistPollData(data)
}

/**
 * Cast a vote
 *
 * @param pollUuid
 * @param answerUuid
 * @returns {Promise<{question: string, answers: [{text: string, percent: number}, {text: string, percent: number}, {text: string, percent: number}], canVote: boolean, votingEnd: Date}|{question, answers, canVote: boolean, votingEnd: Date}>}
 */
async function doVote (pollUuid, answerUuid) {
  // For testing purposes
  if (!pollUuid || !answerUuid) {
    return {
      question: 'Wat vind je het aller belangrijkste de komende onderhandelingen?',
      answers: [
        { text: 'Een hoger salaris', percent: 34 },
        { text: 'Meer vakantiedagen', percent: 17 },
        { text: 'Inspraak in werktijden en aantal uur per week', percent: 49 }
      ],
      canVote: false,
      totalVotes: 75,
      votingEnd: new Date(new Date() * 1 + 86400000 * 3)
    }
  }

  // regular behaviour
  const endpoint = `/rest-api/v1/community/polls/${pollUuid}/vote/`
  let data

  try {
    data = await apiCall(POST, endpoint, { votes: [answerUuid] }, window.CNV_APP.csrfToken)
  } catch (err) {
    if (err instanceof RequestStatusError) {
      if (err.status === 403) {
        redirectToLoginAndReturn()
        return
      }
      throw err
    }
  }

  return serializeTwistPollData(data)
}

/**
 * Convert Twist it poll data to poll data
 *
 * @param pollData
 */
function serializeTwistPollData (pollData) {
  const { uuid, question, answers, voted, selected, voting_active: votingActive, voting_start: votingStart, voting_end: votingEnd } = pollData
  const now = new Date()
  const pollActive = votingActive && now >= new Date(votingStart) && now < new Date(votingEnd)
  const totalVotes = answers.reduce((total, { votes }) => total + votes, 0)
  const selectedUuid = selected.pop()

  return {
    question,
    answers: answers.map(({ uuid, text, votes }) => ({
      uuid,
      text,
      percent: (votes * 100) / Math.max(1, totalVotes),
      selected: selectedUuid === uuid
    })),
    totalVotes,
    canVote: !voted && pollActive && !sessionDidVote(uuid),
    votingEnd: new Date(votingEnd)
  }
}
class PollComponent extends Component {
  init () {
    const templateForm = this.element.querySelector('.poll__template-form')
    const templateFormOption = this.element.querySelector('.poll__template-form-option')
    const templatePoll = this.element.querySelector('.poll__template-poll')
    const templateResults = this.element.querySelector('.poll__template-results')
    const templateResult = this.element.querySelector('.poll__template-result')

    this.pollUuid = this.element.getAttribute('data-uuid')
    this.askEmail = this.element.dataset.askEmail.toLowerCase() === 'true'

    this.container = this.element.querySelector('[data-role=container]')

    this.library = {
      form: templateForm,
      formOption: templateFormOption,
      poll: templatePoll,
      result: templateResult,
      results: templateResults
    }

    this.api = {
      castVote: this.castVote.bind(this)
    }

    this.setPoll()
  }

  /**
   * Cast a vote on a certain answer in the form
   *
   * @param answerUuid
   */
  async castVote (answerUuid) {
    const newPollData = await doVote(this.pollUuid, answerUuid)
    if (newPollData) {
      sessionRegisterVote(this.pollUuid)
      this.setPoll(newPollData)
    }
  }

  /**
   * Update the poll element with new poll data
   *
   * @param pollData
   */
  async setPoll (pollData = null) {
    if (!pollData) {
      pollData = await fetchPoll(this.pollUuid)
    }

    const poll = Poll(this.library, this.api, pollData)
    this.container.innerHTML = ''
    this.container.appendChild(poll.element)

    // use code underneath for the check on popup
    if (!window.CNV_APP.isLoggedIn && this.askEmail) {
      initializePollModal(this.element)
    }
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.poll').forEach(element => {
  element.instance = element.instance || new PollComponent(element)
}))
hydratorRegisterComponent('.poll', PollComponent)
