import eventBus from '../interfaces/event-bus'
import Paginator from '../interfaces/paginator'
import { LoadMoreButton } from './load-more-button'

const MODE_INITIAL = 1
const MODE_RELOAD = 2
const MODE_APPEND = 3

/**
 * Build and manage a list of items.
 *
 * @param templateOrElement
 * @param templateLoadMoreButton
 * @param fetchSource
 * @param initialData
 * @param itemsPerPage
 * @returns {{addComment: addComment, element: *}}
 */
function ItemList (templateOrElement, templateLoadMoreButton, fetchSource, initialData, itemsPerPage = 3) {
  /**
   * Add an item on top of the list
   *
   * New: option to add it to bottom instead as well
   *
   * @param bottom
   * @param item
   */
  function addItem (item, bottom = false) {
    if (bottom) {
      list.appendChild(item.element)
    } else {
      list.insertBefore(item.element, list.firstChild)
    }

    if (item.isActive && item.isActive()) {
      activeItems += 1
    }

    if (item.subscribe) {
      item.subscribe('order-change', () => update())
      item.subscribe('removed', (detail) => removeItem(detail))
    }

    paginator.updateExtra(1)

    fireUpdate()
  }

  /**
   * Fetch a new page of items
   */
  function fetchPage () {
    update(MODE_APPEND).catch(console.log)
  }

  /**
   * Subscribe to updates of this list.
   *
   * @param callback
   */
  function subscribe (callback) {
    bus.addListener('update', callback)
  }

  /**
   * Append an item to the bottom of the list
   *
   * N.B. this doesn't call fireUpdate because it is assumed to be called from
   * the update function.
   *
   * @param item
   */
  function appendItem (item) {
    list.appendChild(item.element)

    if (item.isActive && item.isActive()) {
      activeItems += 1
    }

    if (item.subscribe) {
      item.subscribe('order-change', () => update())
      item.subscribe('removed', (detail) => removeItem(detail))
    }
  }

  /**
   * Remove an item from the list
   *
   * @param item
   */
  function removeItem (item) {
    list.removeChild(item.element)
    paginator.updateExtra(-1)
    update().catch(console.log)
  }

  /**
   * Update the contents of the list.
   *
   * @param mode
   * @returns {Promise<*|undefined>}
   */
  async function update (mode = MODE_RELOAD) {
    let data

    if (mode === MODE_INITIAL) {
      data = initialData
    } else {
      const { offset, limit } = paginator[mode === MODE_RELOAD ? 'reload' : 'incrementAndFetch']()
      data = await fetchSource(offset, limit)
    }

    if (data.total > paginator.total + paginator.extra) {
      const reload = paginator.total !== 0
      paginator.updateTotal(data.total)

      if (reload) {
        return update()
      }
    }

    if (mode === MODE_INITIAL && data.items.length < itemsPerPage) {
      return update()
    }

    if (mode === MODE_RELOAD && !data.isTest) {
      list.innerHTML = ''
      activeItems = 0
    }

    for (const item of data.items) {
      appendItem(item)
    }

    if (footer && loadMoreButton) {
      if (paginator.isCompleted()) {
        if (!paginatorRemoved) {
          footer.removeChild(loadMoreButton.element)
          paginatorRemoved = true
        }
      } else {
        loadMoreButton.enable()
      }
    }

    fireUpdate()
  }

  function fireUpdate () {
    bus.send('update', {
      activeItemCount: activeItems,
      itemCount: list.children.length,
      totalItemCount: paginator.total
    })
  }

  const template = templateOrElement.tagName === 'TEMPLATE' ? templateOrElement : null
  const fragment = template ? template.content.cloneNode(true) : templateOrElement
  const element = template ? fragment.firstElementChild : templateOrElement
  const list = fragment.querySelector('[data-role=items]')
  const footer = fragment.querySelector('[data-role=footer]')
  const paginator = new Paginator(itemsPerPage, 0)
  let paginatorRemoved = false
  let loadMoreButton

  // Extra feature: items can be active or not and this keeps track of the number of active items
  let activeItems = 0

  const bus = eventBus()

  if (templateLoadMoreButton) {
    loadMoreButton = LoadMoreButton(templateLoadMoreButton)
    loadMoreButton.subscribe(fetchPage)

    if (footer) {
      footer.appendChild(loadMoreButton.element)
    }
  }

  update(initialData ? MODE_INITIAL : MODE_RELOAD)

  return {
    addItem,
    fetchPage,
    reload: async () => update(),
    subscribe,
    element
  }
}

export { ItemList }
