import Component from '../../../assets/scripts/modules/component'

class LoginDetailsComponent extends Component {
  init () {
    document.getElementById('modal-delete').querySelectorAll('form input[name="csrfmiddlewaretoken"]').forEach(
      element => {
        element.value = window.CNV_APP.csrfToken
      }
    )
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.login-details').forEach(element => {
  element.instance = element.instance || new LoginDetailsComponent(element)
}))
