
export default class Paginator {
  constructor (perPage, total) {
    this.perPage = perPage
    this.currentPage = 0
    this.total = total
    this.extra = 0
  }

  isCompleted () {
    return (this.currentPage + 1) * this.perPage + this.extra >= this.total
  }

  incrementPage () {
    this.currentPage += 1
  }

  fetch (pageId = null) {
    const page = pageId === null ? this.currentPage : pageId
    return { limit: this.perPage, offset: this.perPage * page + this.extra }
  }

  incrementAndFetch () {
    this.incrementPage()
    return this.fetch()
  }

  reload () {
    return { limit: (this.currentPage + 1) * this.perPage + this.extra, offset: 0 }
  }

  /**
   * Use this to update the total because (a) new item(s) was/were added.
   *
   * @param amount
   */
  updateExtra (amount) {
    this.extra += amount
    this.total += amount
  }

  updateTotal (newTotal) {
    if (this.total - this.extra > 0) {
      this.extra += newTotal - this.total
    }

    this.total = newTotal
  }
}
