
import { BREAKPOINTS } from '../../../assets/scripts/configuration'
import Component from '../../../assets/scripts/modules/component'
import SwiperWrapper from '../../../assets/scripts/modules/swiper-wrapper'

const SWIPER_OPTIONS = {
  spaceBetween: 10,
  breakpointsInverse: true,
  breakpoints: {
    [BREAKPOINTS.NOTEBOOK]: { slidesPerView: 2 }
  }
}

let timeout

export default class ContentCarouselComponent extends Component {
  init () {
    this.observer = null
    this.container = this.element.querySelector('.swiper-container')
    this.numSlides = this.element.querySelectorAll('.swiper-slide').length

    this.swiperIsUsed = false
    this.checkForSwiper()
    this.showNavigation()
    window.addEventListener('resize', () => this.checkForSwiper())
  }

  checkForSwiper () {
    const shouldUseSwiper = window.innerWidth < BREAKPOINTS.NOTEBOOK || this.numSlides > 2

    if (!this.swiperIsUsed && shouldUseSwiper) {
      this.initializeSwiper()
    } else if (this.swiperIsUsed && !shouldUseSwiper) {
      this.destroySwiper()
    }
  }

  destroySwiper () {
    this.swiperIsUsed = false
    this.swiperWrapper.destroy()
    this.showNavigation()
  }

  initializeSwiper () {
    this.swiperIsUsed = true
    const swiperOptions = {
      ...SWIPER_OPTIONS,
      navigation: {
        nextEl: this.element.querySelector('.swiper-button-next'),
        prevEl: this.element.querySelector('.swiper-button-prev')
      }
    }

    this.swiperWrapper = new SwiperWrapper(this.container, swiperOptions, [], {
      slideChange: () => this.onSwiperSlideChange(),
      resize: () => this.showNavigation()
    })

    window.clearTimeout(timeout)
    timeout = window.setTimeout(() => {
      this.onSwiperSlideChange()
      this.showNavigation()

      // Warkaround for swiper bug; see https://github.com/nolimits4web/Swiper/issues/2099
      if (SWIPER_OPTIONS.loop) {
        [...this.element.querySelectorAll('.swiper-button-disabled')].forEach(button => {
          button.classList.remove('swiper-button-disabled')
        })
      }
    }, 500)
    this.showNavigation()
  }

  canNavigate () {
    if (!this.swiperIsUsed) {
      return false
    }

    const prevIsDisabled = this.element.querySelector('.swiper-button-prev').classList.contains('swiper-button-disabled')
    const nextIsDisabled = this.element.querySelector('.swiper-button-next').classList.contains('swiper-button-disabled')
    return !(prevIsDisabled && nextIsDisabled)
  }

  showNavigation () {
    if (this.canNavigate()) {
      this.element.querySelector('.swiper-navigation').removeAttribute('hidden', '')
    } else {
      this.element.querySelector('.swiper-navigation').setAttribute('hidden', '')
    }
  }

  onSwiperSlideChange () {
    if (!this.swiperWrapper || !this.swiperWrapper.swiper || !this.swiperWrapper.swiper.slides) {
      return
    }

    const current = this.swiperWrapper.swiper.slides[this.swiperWrapper.swiper.activeIndex]
    const all = Object.values(this.swiperWrapper.swiper.slides).filter(slide => !!slide.tagName)
    const currentWithDuplicates = all.filter(slide => slide.getAttribute('data-swiper-slide-index') === current.getAttribute('data-swiper-slide-index'))

    currentWithDuplicates.forEach(slide => {
      slide.classList.add('swiper-slide--activated')
    })
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.content-carousel').forEach(element => {
  element.instance = element.instance || new ContentCarouselComponent(element)
}))
