import Component from '../../../assets/scripts/modules/component'

export default class FilterZipcodeComponent extends Component {
  init () {
    this.button = this.element.querySelector('.filter-zipcode__button')
    this.result = this.element.querySelector('.filter-zipcode__result')
    this.inputZipCode = document.getElementById('filter-zipcode__input')
    this.errorElement = this.element.querySelector('.filter-zipcode__error')
    this.consulentType = this.element.dataset.consulent

    if (this.button && this.inputZipCode && this.consulentType) {
      this.button.addEventListener('click', () => {
        this.getConsulent().then((result) => {
          return result.zipcode ? this.displayResult(result) : this.displayError(result)
        })
      })

      this.inputZipCode.addEventListener('keypress', (e) => {
        if (e.key === 'Enter') {
          e.preventDefault()
          this.button.click()
        }
      })
    }
  }

  async getConsulent () {
    const url = `/api/consulent/${this.consulentType}?zipcode=${this.inputZipCode.value}`
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'same-origin',
      'Access-Control-Allow-Headers': '*',
      'Content-Type': 'application/json'
    })

    return response.json()
  }

  displayResult (result) {
    const title = this.element.querySelector('.filter-zipcode__result-title')
    const email = this.element.querySelector('.filter-zipcode__result-email')
    const emailSpan = this.element.querySelector('.filter-zipcode__result-email-link')
    const phone = this.element.querySelector('.filter-zipcode__result-phone')
    const phoneSpan = this.element.querySelector('.filter-zipcode__result-phone-number')

    this.result.classList.add('filter-zipcode__result-consultant')
    this.errorElement.classList.remove('show--error')

    title.innerText = result.name
    email.href = `mailto:${result.email}`
    emailSpan.innerText = result.email
    phone.href = `tel:${result.phone_number}`
    phoneSpan.innerText = result.phone_number
  }

  displayError (result) {
    const errorText = this.element.querySelector('.filter-zipcode__error-text')

    this.errorElement.classList.add('show--error')
    this.result.classList.remove('filter-zipcode__result-consultant')
    errorText.innerText = result.message
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.filter-zipcode').forEach((element) => {
    element.instance = element.instance || new FilterZipcodeComponent(element)
  })
)
