
import { DropdownMenu } from '../../../assets/scripts/components/dropdown-menu'
import { ItemList } from '../../../assets/scripts/components/item-list'
import { apiCall, GET, POST } from '../../../assets/scripts/interfaces/api-call'
import { formatDateToNow } from '../../../assets/scripts/interfaces/date'
import Component from '../../../assets/scripts/modules/component'
import escapeHTML from '../../../assets/scripts/utilities/escape-html'
import { clickedOutsideMultiple } from '../../../assets/scripts/utilities/outside-click'

const RELOAD_AFTER = 15000 * 1000 // disable this for by making it really big

/**
 * Create an actor object for a notification
 *
 * @param template
 * @param data
 * @returns {{element: Element}}
 * @constructor
 */
function Actor (template, data) {
  const fragment = template.content.cloneNode(true)
  const element = fragment.firstElementChild
  const nameElement = fragment.querySelector('[data-role=name]')

  const { name } = data

  if (nameElement) {
    nameElement.innerText = name
  }

  return {
    element
  }
}

/**
 * Create a notification object
 *
 * @param library
 * @param api
 * @param data
 * @returns {{element: Element}}
 * @constructor
 */
function Notification (library, api, data) {
  function isActive () {
    return unread
  }

  const fragment = library.notification.content.cloneNode(true)
  const element = fragment.firstElementChild
  const textElement = fragment.querySelector('[data-role=text]')
  const linkElement = fragment.querySelector('[data-role=link]')
  const avatarElement = fragment.querySelector('[data-role=avatar]')
  const dateElement = fragment.querySelector('[data-role=date]')

  const {
    actionObject,
    actor,
    href,
    id,
    target,
    thumbnail,
    timestamp,
    unread,
    verb
  } = data

  if (unread) {
    element.classList.add('is-unread')
  }

  if (linkElement && href) {
    linkElement.href = href

    // First mark notification as read
    linkElement.addEventListener('click', async (event) => {
      event.preventDefault()
      await markRead(id)
      window.location.href = href
    })
  }

  if (avatarElement) {
    if (thumbnail) {
      avatarElement.src = thumbnail
    }
  }

  if (dateElement && timestamp) {
    dateElement.innerHTML = formatDateToNow(timestamp)
  }

  if (textElement) {
    let copy = escapeHTML(verb)

    if (copy.indexOf('{actor}') >= 0) {
      const { element: actorElement } = Actor(library.actor, { name: actor })
      copy = copy.replace(/\{actor\}/g, actorElement.outerHTML)
    }

    if (copy.indexOf('{action_object}') >= 0) {
      copy = copy.replace(/\{action_object\}/g, escapeHTML(actionObject))
    }

    if (copy.indexOf('{target}') >= 0) {
      copy = copy.replace(/\{target\}/g, `“${escapeHTML(target)}”`)
    }

    textElement.innerHTML = copy
  }

  return {
    isActive,
    element
  }
}

/**
 * Create a notification count object
 *
 * @param library
 * @param data
 * @returns {{shouldShow: (function(): boolean), element: Element}}
 * @constructor
 */
function NotificationCount (library, data) {
  const fragment = library.notificationCount.content.cloneNode(true)
  const element = fragment.firstElementChild
  const countElement = fragment.querySelector('[data-role=count]')
  const iconElement = fragment.querySelector('[data-role=icon]')

  const { count } = data

  if (countElement) {
    countElement.innerText = count < 10 ? count.toString() : '9+'

    if (count < 1) {
      countElement.parentNode.removeChild(countElement)
    }
  }

  if (iconElement) {
    if (count > 0) {
      iconElement.innerHTML = library.iconBellActive.content.cloneNode(true).firstElementChild.outerHTML
    } else {
      iconElement.innerHTML = library.iconBell.content.cloneNode(true).firstElementChild.outerHTML
    }
  }

  return {
    element
  }
}

/**
 * Fetch notifications from the back-end
 *
 * @param library
 * @param api
 * @param offset
 * @param limit
 * @returns {Promise<{total, items}>}
 */
async function fetchNotifications (library, api, offset, limit) {
  const params = new URLSearchParams({ offset, limit }).toString()
  const endpoint = `/rest-api/v1/users/me/notifications/?${params}`
  const results = await apiCall(GET, endpoint)
  const serialize = data => Notification(library, api, serializeNotificationData(data))

  return {
    total: results.results.length,
    items: results.results.map(serialize)
  }
}

async function markRead (uuid) {
  const endpoint = `/rest-api/v1/users/me/notifications/read_many/`
  await apiCall(POST, endpoint, { ids: [uuid] }, window.CNV_APP?.csrfToken)
}

/**
 * Manager for the notifications.
 *
 * @param library
 * @param element
 * @returns {Promise<void>}
 * @constructor
 */
async function NotificationManager (library, element) {
  /**
   * Update the notifications. Don't call this function directly as it's a periodic function
   *
   * @returns {Promise<void>}
   */
  async function _autoUpdate () {
    await reload()
    setTimeout(updateFunction, RELOAD_AFTER)
  }

  /**
   * Update the notification item in the menu bar.
   *
   * @param activeCount Number of active items
   * @param totalCount Number of total items
   */
  function updateStatus (activeCount, totalCount) {
    if (noMessagesContainer) {
      noMessagesContainer.classList.toggle('menu-panel-notification-dropdown__no-messages--active', totalCount < 1)
    }

    if (countContainer) {
      const notificationCount = NotificationCount(library, { count: activeCount })

      countContainer.innerHTML = ''
      countContainer.appendChild(notificationCount.element)
    }
  }

  const updateFunction = _autoUpdate

  const noMessagesContainer = element.querySelector('.menu-panel-notification-dropdown__no-messages')
  const itemListElement = element.querySelector('[data-role=item-list]')
  const countContainer = element.querySelector('[data-role=notification-count-container]')
  const api = {
    fetchNotifications: (limit, offset) => fetchNotifications(library, api, limit, offset),
    markRead
  }
  const { reload, subscribe } = ItemList(itemListElement, library.loadMoreButton, api.fetchNotifications, null, 25)
  setTimeout(updateFunction, RELOAD_AFTER)
  subscribe(({ activeItemCount, itemCount }) => updateStatus(activeItemCount, itemCount))
}

/**
 * Convert twist it notification data for use in the front-end
 *
 * @param data
 * @returns {{actor, thumbnail, unread, deeplink, verb, actionObject, id, target, timestamp: (Date|null)}}
 */
function serializeNotificationData (data) {
  const {
    action_object: actionObject,
    actor,
    deeplink,
    id,
    target,
    thumbnail,
    timestamp,
    unread,
    verb
  } = data

  let href = deeplink || '#'
  let slug

  if (href.startsWith('group:')) {
    slug = deeplink.substring(6)
    href = window.CNV_APP.groupUrl?.replace(/\/slug\//, `/${slug}/`) || '#'
  }

  return {
    actionObject,
    actor,
    href,
    id,
    target,
    thumbnail,
    timestamp: timestamp ? new Date(timestamp) : null,
    unread,
    verb
  }
}

class NotificationDropdown extends Component {
  init () {
    const templateNotification = this.element.querySelector('template[data-role=notification]')
    const templateActor = this.element.querySelector('template[data-role=actor]')
    const templateLoadMoreButton = this.element.querySelector('template[data-role=load-more-button]')
    const templateIconBell = this.element.querySelector('template[data-role=icon-bell]')
    const templateIconBellActive = this.element.querySelector('template[data-role=icon-bell-active]')
    const templateNotificationCount = this.element.querySelector('template[data-role=notification-count]')

    const library = {
      notification: templateNotification,
      actor: templateActor,
      loadMoreButton: templateLoadMoreButton,
      notificationCount: templateNotificationCount,
      iconBell: templateIconBell,
      iconBellActive: templateIconBellActive
    }

    if (window.CNV_APP?.isLoggedIn) {
      NotificationManager(library, this.element)
    }

    this.initializeDropdown()
  }

  initializeDropdown () {
    const btnElement = this.element.querySelector('[data-role=notification-count-container]')
    const dropdownElement = this.element.querySelector('.menu-panel-notification-dropdown')

    if (btnElement && dropdownElement) {
      const dropdown = DropdownMenu(dropdownElement)

      btnElement.addEventListener('click', (event) => {
        event.preventDefault()
        btnElement.classList.toggle('menu-panel-notification--filled')
        dropdown.isOpen() ? dropdown.close() : dropdown.open()
      })

      document.addEventListener('click', (e) => {
        clickedOutsideMultiple(e, [btnElement, dropdownElement]) && dropdown.close()
      })
    }
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.menu-panel-notification').forEach(element => {
  element.instance = element.instance || new NotificationDropdown(element)
}))
