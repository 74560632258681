import { CustomToggleButton } from '../../../assets/scripts/components/custom-toggle-button'
import { apiCall, POST, RequestStatusError } from '../../../assets/scripts/interfaces/api-call'

/**
 * Controller for a user setting item
 *
 * @param element
 * @param settingName
 * @returns {{element}}
 * @constructor
 */
function UserSettingController (element) {
  const settingName = element.dataset.settingName
  const buttonElement = element.querySelector('.toggle')

  async function change (newState) {
    const endpoint = `/rest-api/v1/users/me/notifications/settings/`

    try {
      await apiCall(POST, endpoint, { [settingName]: newState }, window.CNV_APP?.csrfToken, false)
    } catch (err) {
      if (err instanceof RequestStatusError) {
        alert('Updaten mislukt. Mogelijk helpt het refreshen van de pagina')
        return
      }
    }

    return newState
  }

  async function setOn () {
    return change(true)
  }

  async function setOff () {
    return change(false)
  }

  if (!buttonElement.instance) {
    buttonElement.instance = CustomToggleButton(buttonElement, null, setOn, setOff)
  }

  return {
    element
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.profile-setting').forEach(element => {
  element.instance = element.instance || UserSettingController(element)
}))
