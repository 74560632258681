import debounce from 'lodash/debounce'

import Component from '../../../assets/scripts/modules/component'

export default class CaoProgressComponent extends Component {
  init () {
    this.timeline = this.element.querySelector('.cao-timeline__timeline')
    this.phaseItems = this.element.querySelectorAll('.cao-timeline__phase-item')
    this.buttons = this.element.querySelectorAll('.cao-timeline__phase-btn')
    this.contentSections = this.element.querySelectorAll('.cao-timeline__content-section')
    this.expander = this.element.querySelector('.cao-timeline__expander')
    this.expander.addEventListener('click', (e) => this.checkExpand(e))
    this.buttons.forEach(button => {
      button.addEventListener('click', (e) => this.showItem(e))
    })
    window.addEventListener('resize', debounce(this.resizeEvent.bind(this), 200))

    this.setCurrentPhase()

    setTimeout(() => {
      this.checkOverflow()
      this.scrollCurrentPhaseToCenter()
    }, 10)
  }

  checkExpand () {
    if (this.expander.classList.contains('is-closed')) {
      this.setCurrentPhase()
    } else {
      this.contentSections.forEach(section => section.setAttribute('hidden', 'true'))
      this.expander.classList.toggle('is-closed')
    }
  }

  resizeEvent () {
    // this.scrollCurrentPhaseToCenter() // TODO: figure out why its not working :/
    this.checkOverflow()
  }

  checkOverflow () {
    const list = this.element.querySelector('.cao-timeline__phases')
    const overflowClass = 'has-overflow'
    if (list.scrollWidth > list.clientWidth) {
      this.timeline.classList.add(overflowClass)
    } else {
      this.timeline.classList.remove(overflowClass)
    }
  }

  getPhaseHash () {
    const hash = window.location.hash.slice(1)
    const isPhaseHash = !!document.querySelector(`[data-phase="${hash}"]`)

    if (isPhaseHash) {
      return [hash, false]
    }

    const targetElement = document.getElementById(hash)

    if (this.element.contains(targetElement)) {
      for (const el of this.element.querySelectorAll('.cao-timeline__content-section')) {
        if (el.contains(targetElement)) {
          return [el.dataset.phase, true]
        }
      }
    }

    return ['', false]
  }

  // Called in init to determine which phase the user left off
  setCurrentPhase () {
    const currentPhaseEl = document.querySelector('[data-current-phase="true"]')
    const [phaseHash, hashIsSubElement] = this.getPhaseHash()
    const storedPhase = window.sessionStorage.getItem('cao-phase')
    const phase = phaseHash || storedPhase || currentPhaseEl?.attributes['data-phase'].value || 'start0' // Previous session > Current Phase Attribute
    const sectionButton = document.querySelector(`[data-phase="${phase}"]`)
    if (this.expander.classList.contains('is-closed')) {
      this.expander.classList.toggle('is-closed')
    }
    sectionButton && sectionButton.click()

    if (phaseHash) {
      if (hashIsSubElement) {
        // Some weird effects with scrolling.. this seems to be ok
        setTimeout(() => {
          document.getElementById(window.location.hash.slice(1)).scrollIntoView()
        }, 1000)
      } else {
        this.expander.scrollIntoView()
      }
    }
  }

  scrollCurrentPhaseToCenter () {
    const phasesList = this.element.querySelector('.cao-timeline__phases')
    const currentPhaseRect = this.element.querySelector('.is-current-phase').getBoundingClientRect()
    const currentPhaseCenterPosition = currentPhaseRect.left + currentPhaseRect.width / 2
    const scrollXPosition = currentPhaseCenterPosition - phasesList.getBoundingClientRect().x - phasesList.clientWidth / 2
    phasesList.scrollTo(scrollXPosition, 0)
  }

  showItem (event) {
    if (this.expander.classList.contains('is-closed')) {
      this.expander.classList.toggle('is-closed')
    }
    const btn = event.currentTarget
    const { phase } = btn.dataset
    const section = this.element.querySelector(`#cao-timeline-content-${phase}`)

    this.resetItems()

    // display clicked phase item
    btn.setAttribute('aria-expanded', 'true')
    btn.parentNode.classList.add('is-active')
    section.removeAttribute('hidden', 'true')

    // Update CAO phase
    window.sessionStorage.setItem('cao-phase', phase)

    // update swiper
    this.element.querySelectorAll('.swiper-container').forEach(container => container.swiper && container.swiper.update())
  }

  resetItems () {
    this.buttons.forEach(btn => btn.setAttribute('aria-expanded', 'false'))
    this.phaseItems.forEach(item => item.classList.remove('is-active'))
    this.contentSections.forEach(section => section.setAttribute('hidden', 'true'))
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.cao-timeline').forEach(element => {
  element.instance = element.instance || new CaoProgressComponent(element)
}))
