/**
 * Create a new dropdown menu item
 */
import eventBus from '../interfaces/event-bus'

function DropdownMenuItem (template, label, iconTemplate) {
  function fire (event) {
    event.preventDefault()
    bus.send('click', {})
  }

  function subscribe (callback) {
    return bus.addListener('click', callback)
  }

  const fragment = template.content.cloneNode(true)
  const element = fragment.firstElementChild
  const labelElement = fragment.querySelector('[data-role=label]')
  const iconElement = fragment.querySelector('[data-role=icon]')
  const bus = eventBus()

  if (labelElement) {
    labelElement.innerText = label
  }

  if (iconElement && iconTemplate) {
    iconElement.innerHTML = ''
    iconElement.appendChild(iconTemplate.content.cloneNode(true).firstElementChild)
  }

  element.addEventListener('click', fire)

  return {
    subscribe,
    element
  }
}

export { DropdownMenuItem }
