const GET = 'GET'
const POST = 'POST'
const DELETE = 'DELETE'

/**
 * Error when request doesn't return 200 status
 */
class RequestStatusError extends Error {
  constructor (message, status) {
    super(message)
    this.status = status
    this.name = 'RequestStatusError'
  }
}

/**
 * Do api call
 *
 * @param method
 * @param endpoint
 * @param data
 * @param csrftoken
 * @param returnJson
 * @returns {Promise<Promise<any>|Response>}
 */
async function apiCall (method, endpoint, data, csrftoken, returnJson = true, sendJson = true) {
  const headers = { 'X-CSRFToken': csrftoken, 'X-Requested-With': 'XmlHttpRequest' }
  if (sendJson) {
    headers['Content-Type'] = 'application/json'
  }
  const request = new Request(endpoint, { headers })
  const requestBody = {
    method: method,
    mode: 'same-origin'
  }
  if (data) {
    requestBody.body = sendJson ? JSON.stringify(data) : data
  }
  const response = await fetch(request, requestBody)

  if (response.status !== 200) {
    const content = await response.text()
    throw new RequestStatusError(content, response.status)
  }

  return returnJson ? response.json() : response
}

/**
 * Submit form data to api
 *
 * @param method
 * @param endpoint
 * @param formData
 * @param csrftoken
 * @param returnJson
 * @returns {Promise<Promise<any>|Response>}
 */
async function apiFormCall (method, endpoint, formData, csrftoken, returnJson) {
  return apiCall(method, endpoint, Object.fromEntries(formData), csrftoken, returnJson)
}

/**
 * Redirect user to login page
 */
function redirectToLogin () {
  window.location.href = window.CNV_APP.loginUrl
}

/**
 * Redirect user to login page and return here afterwards
 */
function redirectToLoginAndReturn () {
  window.location.href = window.CNV_APP.loginAndReturnUrl
}

export { DELETE, GET, POST, RequestStatusError, apiCall, apiFormCall, redirectToLogin, redirectToLoginAndReturn }
