import Component from '../../../assets/scripts/modules/component'

class SpotlerFormComponent extends Component {
  init () {
    // Take the id of this element, remove 'mp' and get the form with this id
    this.form = null
    this.formId = this.element.id.replace('mp', '')
    this.parent = this.element.parentElement

    setTimeout(() => {
      this.form = this.parent.querySelector(`form#${this.formId}`)
      this.setFormPlaceholders()
    }, 1000)
  }

  setFormPlaceholders () {
    if (!this.form) { return }

    const setPlaceholders = (fields, callback) => {
      for (const field of fields) {
        const sublabel = field.querySelector('.sublabel')
        if (!sublabel) continue

        callback(field, sublabel)
      }
    }

    // Set placeholder for regular input fields
    const formFields = this.form.querySelectorAll('.mpFormField:not(:has(input.mpDateField))')
    setPlaceholders(formFields, (field, sublabel) => {
      const input = field.querySelector('input')
      if (input) input.placeholder = sublabel.textContent
    })

    // Set placeholder for date input fields
    const formDateFields = this.form.querySelectorAll('.mpFormField:has(input.mpDateField)')
    setPlaceholders(formDateFields, (field, sublabel) => {
      const dateInputs = field.querySelectorAll('.mpDateField')
      const dateFieldPattern = field.querySelector('.dateFieldPattern')?.value.split('-') || []
      const sublabelParts = sublabel.textContent.split('-') || []
      const placeholders = dateFieldPattern.map((item, i) => `${sublabelParts[i].trim()} (${item.toLowerCase()})`)

      dateInputs.forEach((input, i) => {
        input.placeholder = placeholders[i] || ''
      })
    })
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('[id^="mpform"]').forEach((element) => {
    element.instance = element.instance || new SpotlerFormComponent(element)
  })
)
