import { DropdownMenu } from '../../../assets/scripts/components/dropdown-menu'
import Component from '../../../assets/scripts/modules/component'
import { clickedOutsideSingle } from '../../../assets/scripts/utilities/outside-click'

export default class TranslationMenuComponent extends Component {
  init () {
    this.html = document.querySelector('html')
    this.main = document.querySelector('.container--content')
    this.menu = this.element.querySelector('.translation-menu__menu')
    this.selectedLang = this.element.querySelector('.translation-menu__selected')
    this.translateEl = this.element.querySelector('#google_translate_element')
    this.languagesPanel = DropdownMenu(this.menu)

    if (!this.menu || !this.languagesPanel) return

    this.element.addEventListener('click', (event) => {
      event.preventDefault()
      const isOpen = this.languagesPanel.isOpen()
      this.element.classList.toggle('active', !isOpen)
      isOpen ? this.languagesPanel.close() : this.languagesPanel.open()
    })

    document.addEventListener('click', (event) => {
      clickedOutsideSingle(event, this.element) && this.languagesPanel.close()
    })

    // Checks if the browser remembered a translation from previous session and adjusts the menu
    // Unfortunately there is no way of exactly telling (afaik) when google finished it's translation so setting this after 500ms which seems to work for all browsers
    document.addEventListener('gt-init', () => {
      setTimeout(() => {
        const lang = this.html.lang
        if (lang !== 'nl') {
          this.selectedLang.innerHTML = lang

          for (const menuItem of this.menu.children) {
            menuItem.classList.toggle('hidden', menuItem.dataset.lang === lang)
          }
        }
      }, 500)
    })

    this.initLangListeners()
  }

  initLangListeners () {
    const clickHandler = event => {
      event.preventDefault()
      const el = event.target.closest('.translation-menu__menu-item')
      this.changeLang(el.dataset.lang)
    }

    for (const lang of Array.from(this.menu.querySelectorAll('.translation-menu__menu-item'))) {
      lang.addEventListener('click', clickHandler)
    }
  }

  changeLang (lang) {
    const select = this.translateEl.querySelector('.goog-te-combo')
    this.html.setAttribute('lang', lang)

    select.value = lang
    select.dispatchEvent(new Event('change'))

    this.main.style.marginTop = '-40px'
    this.selectedLang.innerHTML = lang

    for (const menuItem of this.menu.children) {
      menuItem.classList.toggle('hidden', menuItem.dataset.lang === lang)
    }

    // Fix for weird bug where selecting dutch once would not trigger a fully working translation
    if (lang === 'nl') {
      setTimeout(() => {
        select.value = lang
        select.dispatchEvent(new Event('change'))
      }, 25)
    }
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.translation-menu').forEach((element) => {
    element.instance = element.instance || new TranslationMenuComponent(element)
  })
)
