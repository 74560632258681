
const componentPatterns = {}
const callables = []

const hydrate = (container, includeSelf = false) => {
  Object.entries(componentPatterns).forEach(([qs, Component]) => {
    const matches = []

    if (includeSelf && container.matches(qs)) {
      matches.push(container)
    }

    matches.push(...container.querySelectorAll(qs))
    matches.forEach(element => {
      element.instance = element.instance || new Component(element)
    })
  })
  callables.forEach(callable => callable(container))
}

const hydratorRegisterCallable = (callable) => {
  callables.push(callable)
}

const hydratorRegisterComponent = (querySelector, componentClass) => {
  componentPatterns[querySelector] = componentClass
}

export { hydrate, hydratorRegisterCallable, hydratorRegisterComponent }
