const eventBus = () => {
  const listeners = []
  let id = 1
  return {
    addListener: (name, callback) => {
      listeners.push([id, name, callback])
      return id++
    },
    removeListener: (id) => {
      const index = listeners.findIndex(l => l[0] === id)
      if (index >= 0) {
        this.listeners.splice(index, 1)
      }
      return index >= 0
    },
    send: (name, detail) => {
      listeners.forEach(([_, name_, callback]) => {
        if (name_ === name) {
          callback(detail)
        }
      })
    }
  }
}

export default eventBus
