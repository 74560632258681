
import { ItemList } from '../../../assets/scripts/components/item-list'
import asyncFormSubmitter from '../../../assets/scripts/interfaces/async-form-submitter'
import Component from '../../../assets/scripts/modules/component'
import fireCustomEvent from '../../../assets/scripts/utilities/fire-custom-event'
import { buildWorkCard } from './components/work-card'

function caseInsensitiveStringCompare (a, b) {
  const [_a, _b] = [a.toUpperCase(), b.toUpperCase()]

  if (_a < _b) {
    return -1
  }

  return _a > _b ? 1 : 0
}

/**
 * Build a placeholder card when no actual card exists yet in a region (sector, cao, work).
 *
 * @param template
 * @param label
 * @param onAddButtonClick
 * @returns {{subscribe: subscribe, element: Element}}
 */
function buildPlaceholderCard (template, label, onAddButtonClick) {
  const fragment = template.content.cloneNode(true)
  const element = fragment.firstElementChild
  const labelElement = fragment.querySelector('[data-role=label]')
  const button = fragment.querySelector('[data-role=add-button]')

  if (labelElement) {
    labelElement.innerText = label
  }

  if (button) {
    button.addEventListener('click', (event) => {
      event.preventDefault()
      onAddButtonClick()
    })
  }

  return {
    subscribe: () => {},
    element
  }
}

function getCards (library, api, cards) {
  return {
    total: cards.length,
    items: cards.map(data => buildWorkCard(library, api, data))
  }
}

function getCardsOrEmptyCard (library, api, cards, emptyCard, maxAllowed = 2) {
  if (cards.length) {
    const finalApi = { ...api }

    if (finalApi.add && cards.length >= maxAllowed) {
      delete finalApi.add
    }

    return getCards(library, finalApi, cards)
  }

  return {
    total: 1,
    items: [emptyCard]
  }
}

async function removeCard (endpoint, localCardList, uuid) {
  if (!uuid) {
    // Testing
    localCardList.pop()
    return true
  }

  const { submit } = asyncFormSubmitter(endpoint, window.CNV_APP.csrfToken)
  const formData = new FormData()
  formData.append('uuid', uuid)
  const response = await submit(formData)
  localCardList.splice(localCardList.findIndex(item => item.uuid === uuid), 1)
  return response.success
}

export default class ProfileWorkComponent extends Component {
  init () {
    const templateList = this.element.querySelector('.profile-work__template-list')
    const templateCard = this.element.querySelector('.profile-work__template-card')
    const templateCardWithLink = this.element.querySelector('.profile-work__template-card-with-link')
    const templateDropdownMenu = this.element.querySelector('.profile-work__template-dropdown-menu')
    const templateDropdownMenuItem = this.element.querySelector('.profile-work__template-dropdown-menu-item')
    const templatePlaceholder = this.element.querySelector('.profile-work__template-placeholder')
    const templatePrivacyToggle = this.element.querySelector('.profile-work__template-privacy-toggle')
    const templateIconAdd = this.element.querySelector('.profile-work__template-icon-add')
    const templateIconRemove = this.element.querySelector('.profile-work__template-icon-remove')

    const modalTriggerSector = this.element.querySelector('[data-role=open-modal-sector]')
    const modalTriggerCao = this.element.querySelector('[data-role=open-modal-cao]')
    // const modalTriggerWork = this.element.querySelector('[data-role=open-modal-work]')
    // const modalTriggerGremium = this.element.querySelector('[data-role=open-modal-gremium]')

    const canModify = this.element.getAttribute('data-can-modify') === 'true'
    const removeEndpoint = this.element.getAttribute('data-remove-endpoint')

    const cards = this.element.querySelector('[data-role=cards]')

    this.modalTriggers = {
      cao: () => {
        fireCustomEvent('click', {}, false, false, modalTriggerCao)
      },
      sector: () => {
        fireCustomEvent('click', {}, false, false, modalTriggerSector)
      },
      work: () => {
        // fireCustomEvent('click', {}, false, false, modalTriggerWork)
      },
      gremium: () => {
        // fireCustomEvent('click', {}, false, false, modalTriggerGremium)
      }
    }

    this.library = {
      card: templateCard,
      cardWithLink: templateCardWithLink,
      dropdownMenu: templateDropdownMenu,
      dropdownMenuItem: templateDropdownMenuItem,
      iconAdd: templateIconAdd,
      iconRemove: templateIconRemove,
      list: templateList,
      placeholder: templatePlaceholder,
      privacyToggle: templatePrivacyToggle
    }

    this.api = {
      getSectorCards: canModify ? () => getCardsOrEmptyCard(
        this.library,
        canModify ? {
          add: this.modalTriggers.sector,
          remove: (uuid) => removeCard(removeEndpoint, this.sectorCards, uuid)
        } : {},
        this.sectorCards,
        this.emptySectorsCard,
        2) : () => getCards(this.library, {}, this.sectorCards),
      getCaoCards: canModify ? () => getCardsOrEmptyCard(
        this.library,
        canModify ? {
          add: this.modalTriggers.cao,
          remove: (uuid) => removeCard(removeEndpoint, this.caoCards, uuid)
        } : {},
        this.caoCards,
        this.emptyCaoCard,
        2) : () => getCards(this.library, {}, this.caoCards),
      getWorkCards: () => getCards(
        this.library,
        canModify ? {
          remove: (uuid) => removeCard(removeEndpoint, this.workCards, uuid)
        } : {},
        this.workCards,
        0),
      getGremiumCards: () => getCards(
        this.library,
        canModify ? {
          remove: (uuid) => removeCard(removeEndpoint, this.gremiumCards, uuid)
        } : {},
        this.gremiumCards,
        0)
    }

    // Containers for current cards
    this.sectorCards = []
    this.caoCards = []
    this.workCards = []
    this.gremiumCards = []

    // Cards that are shown when no item is selected
    this.emptySectorsCard = buildPlaceholderCard(templatePlaceholder, 'Sector', this.modalTriggers.sector)
    this.emptyCaoCard = buildPlaceholderCard(templatePlaceholder, 'Cao', this.modalTriggers.cao)
    // this.emptyWorkCard = buildPlaceholderCard(templatePlaceholder, 'Work', this.modalTriggers.work)
    // this.emptyGremiumCard = buildPlaceholderCard(templatePlaceholder, 'Vrijwilligersrol', this.modalTriggers.gremium)

    this.sectorCardList = ItemList(templateList, null, this.api.getSectorCards)
    cards.appendChild(this.sectorCardList.element)

    this.caoCardList = ItemList(templateList, null, this.api.getCaoCards)
    cards.appendChild(this.caoCardList.element)

    this.workCardList = ItemList(templateList, null, this.api.getWorkCards)
    cards.appendChild(this.workCardList.element)

    this.gremiumCardList = ItemList(templateList, null, this.api.getGremiumCards)
    cards.appendChild(this.gremiumCardList.element)

    window.addEventListener('modal-form-completed', (event) => {
      const { modal, data } = event.detail

      if (modal.id === 'modal-sector') {
        this.addSectorCard(data.sector)
      } else if (modal.id === 'modal-cao') {
        this.addCaoCard(data.cao)
      } else if (modal.id === 'modal-work') {
        this.addWorkCard(data.work)
      } else if (modal.id === 'modal-gremium') {
        this.addWorkCard(data.gremium)
      }
    })
  }

  addSectorCard (data) {
    this.sectorCards.push(data)
    this.sectorCards.sort((a, b) => caseInsensitiveStringCompare(a.title, b.title))
    this.sectorCardList.reload()
  }

  addCaoCard (data) {
    this.caoCards.push(data)
    this.caoCards.sort((a, b) => caseInsensitiveStringCompare(a.title, b.title))
    this.caoCardList.reload()
  }

  addWorkCard (data) {
    this.workCards.push(data)
    this.workCardList.reload()
  }

  addGremiumCard (data) {
    this.gremiumCards.push(data)
    this.gremiumCardList.reload()
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.profile-work').forEach(element => {
  element.instance = element.instance || new ProfileWorkComponent(element)
}))
