import Component from '../../../assets/scripts/modules/component'

class SidebarComponent extends Component {
  init () {
    const backLink = this.element.querySelector('[href="#back"]')

    if (backLink) {
      backLink.addEventListener('click', (event) => {
        event.preventDefault()

        window.history.back()
      })
    }
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.sidebar').forEach(element => {
  element.instance = element.instance || new SidebarComponent(element)
}))
