/**
 * Create a new reply button
 */
import eventBus from '../../../../assets/scripts/interfaces/event-bus'

function buildReplyButton (templateLibrary) {
  function disable () {
    element.setAttribute('disabled', 'disabled')
  }

  function enable () {
    element.removeAttribute('disabled')
  }

  function fire () {
    bus.send('click', {})
  }

  function subscribe (callback) {
    return bus.addListener('click', callback)
  }

  const fragment = templateLibrary.replyButton.content.cloneNode(true)
  const element = fragment.querySelector('.comment-section-reply-button')
  const bus = eventBus()

  element.addEventListener('click', fire)

  return {
    disable,
    enable,
    subscribe,
    element
  }
}

export { buildReplyButton }
