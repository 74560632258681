import Component from '../../../assets/scripts/modules/component'

export default class ArticleNavBackLink extends Component {
  init () {
    if (this.element.classList.contains('article-nav-back-link--fixed')) {
      this.setupFixedLink()
    }
  }

  setupFixedLink () {
    const element = this.element
    let previousScrollPosition = 0

    const isScrollingDown = () => {
      let goingDown = false

      const scrollPosition = window.scrollY

      if (scrollPosition > previousScrollPosition) {
        goingDown = true
      }

      previousScrollPosition = scrollPosition

      return goingDown
    }

    const handleScroll = () => {
      if (isScrollingDown()) {
        element.classList.add('article-nav-back-link--compact')
      } else {
        element.classList.remove('article-nav-back-link--compact')
      }
    }

    window.addEventListener('scroll', handleScroll)
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.article-nav-back-link').forEach(element => {
  element.instance = element.instance || new ArticleNavBackLink(element)
}))
