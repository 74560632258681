import Component from '../../../assets/scripts/modules/component'

function findBackgroundColour (element, goDown = false) {
  const getColor = element => getComputedStyle(element, null).backgroundColor
  const colorIsUndefined = colorValue => (colorValue.toString().match(/\b0\b/g) || []).length === 4
  const main = document.querySelector('main')
  const fallback = 'white'

  if (!main) {
    return fallback
  }

  const lastChild = main.lastElementChild

  if (!lastChild) {
    return fallback
  }

  const sectionWrapper = lastChild.classList.contains('section-wrapper') ? lastChild : null

  const targetComponent = sectionWrapper ? sectionWrapper.lastElementChild : lastChild

  if (!targetComponent) {
    return fallback
  }

  const componentBackground = getColor(targetComponent)
  return colorIsUndefined(componentBackground) && sectionWrapper ? getColor(sectionWrapper) : componentBackground
}

export default class FooterBarComponent extends Component {
  init () {
    // this.initializeChat()

    setTimeout(() => {
      this.element.style.backgroundColor = findBackgroundColour(this.element)
    })
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.footer-bar').forEach(element => {
  element.instance = element.instance || new FooterBarComponent(element)
}))
