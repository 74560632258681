/**
 * Create a new like button
 */

function buildLikeButton (templateLibrary, api, data) {
  function updateStatus (isLikedByUsed, likeCount) {
    if (button) {
      button.classList[isLikedByUsed ? 'add' : 'remove']('button-toggle--is-collapsed')
    }

    if (info) {
      let text

      if (likeCount < 1) {
        text = ''
      } else if (likeCount === 1) {
        text = '1 vindt dit leuk'
      } else {
        text = `${likeCount} vinden dit leuk`
      }

      info.innerText = text

      if (text) {
        info.classList.remove('empty')
      } else {
        info.classList.add('empty')
      }
    }
  }

  const fragment = templateLibrary.likeButton.content.cloneNode(true)
  const element = fragment.querySelector('.comment-section-like-button')
  const button = fragment.querySelector('[data-role=button]')
  const info = fragment.querySelector('[data-role=info]')
  const { likedByUser, likeCount, isOwned } = data

  let isOn = likedByUser

  updateStatus(isOn, likeCount || 0)

  if (!isOwned) {
    button.addEventListener('click', async () => {
      const { likeCount } = await api[isOn ? 'unlike' : 'like']()
      isOn = !isOn
      updateStatus(isOn, likeCount)
    })
  }

  return {
    element
  }
}

export { buildLikeButton }
