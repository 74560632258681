import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import Component from '../../../assets/scripts/modules/component'

export default class DossierHeaderComponent extends Component {
  init () {
    this.classNoScroll = 'no-scroll'
    this.classNavOpen = 'is-open-nav'
    this.ScrollTriggerId = `id_${Math.random().toString(36).substring(7)}`

    this.navItemNext = this.element.querySelector('.dossier-header__nav-item--next')
    this.navItemPrevious = this.element.querySelector('.dossier-header__nav-item--previous')
    this.navMouseenterNext = this.navMouseenterNext.bind(this)
    this.navMouseLeaveNext = this.navMouseLeaveNext.bind(this)
    this.navMouseenterPrevious = this.navMouseenterPrevious.bind(this)
    this.navMouseLeavePrevious = this.navMouseLeavePrevious.bind(this)

    const breakpoint = getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-notebook')
    const mq = window.matchMedia(`(min-width:${breakpoint})`)
    mq.addEventListener('change', this.mediaListener.bind(this))
    this.mediaListener(mq)
  }

  mediaListener (e) {
    if (e.matches) {
      this.initScrollTrigger()
      this.navItemNext.addEventListener('mouseenter', this.navMouseenterNext)
      this.navItemNext.addEventListener('mouseleave', this.navMouseLeaveNext)
      this.navItemPrevious.addEventListener('mouseenter', this.navMouseenterPrevious)
      this.navItemPrevious.addEventListener('mouseleave', this.navMouseLeavePrevious)
    } else {
      ScrollTrigger.getById(this.ScrollTriggerId)?.kill()
      this.navItemNext.removeEventListener('mouseenter', this.navMouseenterNext)
      this.navItemNext.removeEventListener('mouseleave', this.navMouseLeaveNext)
      this.navItemPrevious.removeEventListener('mouseenter', this.navMouseenterPrevious)
      this.navItemPrevious.removeEventListener('mouseleave', this.navMouseLeavePrevious)
    }
  }

  navMouseenterNext () {
    this.element.classList.add(this.classNavOpen)
    this.element.classList.add('dossier-header--nav-open-next')
    document.body.classList.add(this.classNoScroll)
  }

  navMouseLeaveNext () {
    this.element.classList.remove(this.classNavOpen)
    this.element.classList.remove('dossier-header--nav-open-next')
    document.body.classList.remove(this.classNoScroll)
  }

  navMouseenterPrevious () {
    this.element.classList.add(this.classNavOpen)
    this.element.classList.add('dossier-header--nav-open-previous')
    document.body.classList.add(this.classNoScroll)
  }

  navMouseLeavePrevious () {
    this.element.classList.remove(this.classNavOpen)
    this.element.classList.remove('dossier-header--nav-open-previous')
    document.body.classList.remove(this.classNoScroll)
  }

  initScrollTrigger () {
    gsap.registerPlugin(ScrollTrigger)

    const backgroundEL = this.element.querySelector('.dossier-header__background')
    const timeline = gsap.timeline()
    timeline.to(backgroundEL, {
      scrollTrigger: {
        // markers: true,
        id: this.ScrollTriggerId,
        trigger: backgroundEL,
        start: 1,
        end: '100px',
        scrub: true,
        pin: '.dossier-header__pinned',
        onEnter: () => {
          this.element.classList.add('has-scrolled')
        },
        onEnterBack: () => {
          backgroundEL.style.position = 'fixed'
        },
        onLeave: () => {
          backgroundEL.style.position = 'absolute'
        },
        onLeaveBack: () => {
          this.element.classList.remove('has-scrolled')
        }
      },
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: 0
    })

    window.addEventListener('resize', () => {
      // timeline.set(backgroundEL, { clearProps: 'all' })
      // For some reason the above won't work so we do it via setAttribute
      backgroundEL.setAttribute('style', '')
      timeline.invalidate()
    })
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.dossier-header').forEach(element => {
  element.instance = element.instance || new DossierHeaderComponent(element)
}))
