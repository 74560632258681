/**
 * Adaption for button-toggle custom logic
 */

function CustomToggleButton (templateOrElement, initialState, onCallback, offCallback) {
  function updateStatus (isOn, info) {
    if (button) {
      button.classList[isOn ? 'add' : 'remove']('button-toggle--is-collapsed')
    }

    if (infoDisplay) {
      infoDisplay.innerText = info
    }
  }

  const template = templateOrElement.tagName === 'TEMPLATE' ? templateOrElement : null
  const fragment = template ? template.content.cloneNode(true) : templateOrElement
  const element = template ? fragment.firstElementChild : templateOrElement
  const button = fragment.querySelector('[data-role="button"],[data-role="toggle-clickable-area"]')
  const infoDisplay = fragment.querySelector('[data-role="info"]')

  let isOn = template ? initialState : templateOrElement.querySelector('input').checked
  let processing = false

  updateStatus(isOn)

  function setProcessing () {
    processing = true
    element.classList.add('button-toggle--processing')
  }

  function unsetProcessing () {
    processing = false
    element.classList.remove('button-toggle--processing')
  }

  button.addEventListener('click', async () => {
    if (processing) {
      return
    }

    setProcessing()
    const response = await (isOn ? offCallback() : onCallback())
    unsetProcessing()
    isOn = !isOn
    updateStatus(isOn, response.info)
  })
  return {
    element
  }
}

export { CustomToggleButton }
